
import { MenuItem, MenuItemProps, Tooltip } from "@mui/material";
import { Module } from "../api";

const ModuleMenuItem = (props: { module: Module, props: MenuItemProps }) => {
    props.props.disabled = 0 === props.module.modelVersion;
    return (
        props.module.modelVersion === 0 ? <Tooltip key={props.module.id} title={0 === props.module.modelVersion ? "Module is not active" : ""}><span><MenuItem {...props.props}>{props.module.name}</MenuItem></span></Tooltip> : <MenuItem {...props.props}>{props.module.name}</MenuItem>
    );
}
export default ModuleMenuItem;