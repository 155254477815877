import { AddOutlined, SupervisorAccountOutlined, YardOutlined, ModelTrainingOutlined, ViewModuleOutlined, AccountBalanceOutlined } from '@mui/icons-material'
import { Box, Grid, IconButton, Paper, Stack, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { Module } from '../api'

function AdminFront() {
	return (
		<>
		{/*
			<Box p={2} pb={2}>
				<iframe src="https://loki.aimap.oniq.solutions/d-solo/VoeJRG94z/new-dashboard?orgId=1&from=1687942813014&to=1687964413014&panelId=2"
					height="620px"
					width="100%"
				>
				</iframe>
			</Box>
		*/}
			<Grid
				container
				spacing={2}
				p={2}
			>
				<Grid item xs={10} sm={4}
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					<Paper elevation={3} sx={{
						borderRadius: 2.5,
						p: 2,
						margin: 'auto',
						maxWidth: 500,
						//height: 250,
						flexGrow: 1,
						backgroundColor: "rgb(143, 143, 159)"
					}}
					>
						<Stack className="adminbutton" direction="row" alignItems="center" gap={1} sx={{ display: 'flex' }} component={Link} to={`/accounts`}>
							<SupervisorAccountOutlined sx={{ width: 50, height: 100 }} />
							<Typography variant="h4"><b>Invite user</b></Typography>
						</Stack>
					</Paper>
				</Grid>
				<Grid item xs={10} sm={4}
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					<Paper elevation={3} sx={{
						borderRadius: 2.5,
						p: 2,
						margin: 'auto',
						maxWidth: 500,
						//height: 250,
						flexGrow: 1,
						backgroundColor: "rgb(143, 143, 159)"
					}}>
						<Stack className="adminbutton" direction="row" alignItems="center" gap={1} sx={{ display: 'flex' }} component={Link} to={`/organizations`}>
							<YardOutlined sx={{ width: 50, height: 100 }} />
							<Typography variant="h4"><b>Create Organization</b></Typography>
						</Stack>
					</Paper>
				</Grid>
				<Grid item xs={10} sm={4}
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					<Paper elevation={3} sx={{
						borderRadius: 2.5,
						p: 2,
						margin: 'auto',
						maxWidth: 500,
						//height: 250,
						flexGrow: 1,
						backgroundColor: "rgb(143, 143, 159)"
					}}>
						<Stack className="adminbutton" direction="row" alignItems="center" gap={1} sx={{ display: 'flex' }} component={Link} to={`/projects`}>
							<AddOutlined sx={{ width: 50, height: 100 }} />
							<Typography variant="h4"><b>Create Collection</b></Typography>
						</Stack>
					</Paper>
				</Grid>
				<Grid item xs={10} sm={4}
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					<Paper elevation={3} sx={{
						borderRadius: 2.5,
						p: 2,
						margin: 'auto',
						maxWidth: 500,
						//height: 250,
						flexGrow: 1,
						backgroundColor: "rgb(143, 143, 159)"
					}}>
						<Stack className="adminbutton" direction="row" alignItems="center" gap={1} sx={{ display: 'flex' }} component={Link} to={`/training`}>
							<ModelTrainingOutlined sx={{ width: 50, height: 100 }} />
							<Typography variant="h4"><b>Create Training</b></Typography>
						</Stack>
					</Paper>
				</Grid>
				<Grid item xs={10} sm={4}
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					<Paper elevation={3} sx={{
						borderRadius: 2.5,
						p: 2,
						margin: 'auto',
						maxWidth: 500,
						//height: 250,
						flexGrow: 1,
						backgroundColor: "rgb(143, 143, 159)"
					}}>
						<Stack className="adminbutton" direction="row" alignItems="center" gap={1} sx={{ display: 'flex' }} component={Link} to={`/modules`}>
							<ViewModuleOutlined sx={{ width: 50, height: 100 }} />
							<Typography variant="h4"><b>Create a module</b></Typography>
						</Stack>
					</Paper>
				</Grid>
				<Grid item xs={10} sm={4}
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					<Paper elevation={3} sx={{
						borderRadius: 2.5,
						p: 2,
						margin: 'auto',
						maxWidth: 500,
						//height: 250,
						flexGrow: 1,
						backgroundColor: "rgb(143, 143, 159)"
					}}>
						<Stack className="adminbutton" direction="row" alignItems="center" gap={1} sx={{ display: 'flex' }} component={Link} to={`/licenses`}>
							<AccountBalanceOutlined sx={{ width: 50, height: 100 }} />
							<Typography variant="h4"><b>Create A License</b></Typography>
						</Stack>
					</Paper>
				</Grid>
			</Grid>
		</>
	)
}

export default AdminFront
