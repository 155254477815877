import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { Module, ModuleApi } from "../api";
import ApiConfig from "../Components/ApiConfig";
import Loading from "../Components/Loading";
import { useNavigate } from "react-router-dom";
import DeleteModule from "../Components/DeleteModule";
import ViewModule from "../Components/ViewModule";
import AddModule from "../Components/AddModule";
import EditModule from "../Components/EditModule";

function Modules() {
	const { isLoading, getAccessTokenSilently, user } = useAuth0();
	const [modules, setModules] = useState<Module[]>([]);
	const [role, setRole] = useState('');
	const navigate = useNavigate();
	const [isLoadingData, setIsLoadingData] = useState(true)

	const getAllModules = async () => {
		const token = await getAccessTokenSilently();
		const header = { headers: { Authorization: `Bearer ${token}` } };

		try {
			await new ModuleApi(ApiConfig())
				.getAllModules(header)
				.then((res) => {
					const data = res;
					setModules(data);
					setIsLoadingData(false)
				});
		} catch (error) {
			navigate("/");
			console.log(error);
		}
	}

	useEffect(() => {
		getAllModules();
	}, []);
	useEffect(() => {

		if (user && user["https://aimaps.k8s.oniq.solutions/roles"].length) {
			setRole(user["https://aimaps.k8s.oniq.solutions/roles"][0]);
			console.log(role)
		}
	}, [isLoading, user])

	if (isLoading || isLoadingData) {
		return (Loading())
	}

	return (
		<div className="modules">
			<h1>Modules</h1>
			{"administrator"===role?<Box sx={{ display: 'flex', justifyContent: 'flex-end', m: 2 }}>
				<AddModule user={user} getAllModules={getAllModules} />
			</Box>:''}
			<TableContainer component={Paper}>
				<Table aria-label="account-table">
					<TableHead>
						<TableRow>
							<TableCell></TableCell>
							<TableCell>Module Name</TableCell>
							<TableCell>Description</TableCell>
							<TableCell></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{modules.map((module) =>
							<TableRow
								key={module.id}
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
								<TableCell>
									<a href={`https://regis.ams3.digitaloceanspaces.com${module.image}`} target="_blank" rel="noopener noreferrer">
										<img className="photo" src={`https://regis.ams3.digitaloceanspaces.com${module.image}`} alt="No thumbnail found" />
									</a>
								</TableCell>
								<TableCell component="th" scope="row">{module.name}
								</TableCell>
								<TableCell>{module.description}
								</TableCell>
								<TableCell align="right" padding='none'>
										<ViewModule module={module} />
										<EditModule module={module} user={user} getAllModules={getAllModules}></EditModule>
										<DeleteModule module={module} getAllModules={getAllModules} user={user} />
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);

}
export default Modules