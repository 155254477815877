import { useAuth0 } from '@auth0/auth0-react';
import React, {useEffect, useState } from 'react'
import {  ImageApi, Location } from '../api';
import ApiConfig from '../Components/ApiConfig';
import { useParams } from 'react-router-dom'
import { styled } from '@mui/material/styles';
import Dropzone from 'react-dropzone'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { Alert, Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, LinearProgress, linearProgressClasses, Card, CardActions, IconButton, IconButtonProps, Collapse } from '@mui/material';
import exifr from 'exifr'
import Loading from '../Components/Loading';
import { LoadingButton } from '@mui/lab';
import ErrorComp from '../Components/ErrorComp';
import { ImageInterface } from '../Components/ImageInterface';
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
	height: 10,
	borderRadius: 5,
	[`&.${linearProgressClasses.colorPrimary}`]: {
		backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
	},
	[`& .${linearProgressClasses.bar}`]: {
		borderRadius: 5,
		backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
	},

}));
interface ExpandMoreProps extends IconButtonProps {
	expand: boolean;
}


function UploadImage() {
	const { getAccessTokenSilently, isLoading } = useAuth0();
	const { id } = useParams()
	const [loading, setLoading] = useState(false);
	const [selectedImages, setSelectedImages] = useState<ImageInterface[]>([]);
	const [selectedImagesLength, setSelectedImagesLength] = useState<number>(0)
	const [isFilePicked, setIsFilePicked] = useState(false);
	const [projectId, setProjectId] = useState<string>("");
	const [coordinates, setCoordinates] = useState<Location>({ latitude: 0, longitude: 0 });
	const [estimatedTime, setEstimatedTime] = useState(new Date());
	const [timeLeft, setTimeLeft] = useState(0);
	const [showFiles, setShowFiles] = useState(false);

	const [progress, setProgress] = useState(0);
	const [displayError, setDisplayError] = useState({ display: 'none' })
	const [displaySuccess, setDisplaySuccess] = useState({ display: 'none' })
	const [dragActive, setDragActive] = useState(false)
	const [uploaded, setUploaded] = useState(0);
	const [expanded, setExpanded] = React.useState(false);

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};
	useEffect(() => {
		if (id) {
			setProjectId(id);
		}
	}, [id]);

	if (isLoading) {
		return (Loading())
	}

	const ExpandMore = styled((props: ExpandMoreProps) => {
		const { expand, ...other } = props;
		return <> <IconButton aria-label='Show details' {...other} /> </> ;
	})(({ theme, expand }) => ({
		transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest,
		}),
	}));


	const handleDrag = function (e: any) {
		e.preventDefault();
		e.stopPropagation();
		if (e.type === "dragenter" || e.type === "dragover") {
			setDragActive(true);
		} else if (e.type === "dragleave") {
			setDragActive(false);
		}
	};
	const mapToImage = async (file: any) => {
		const coordinate = await exifr.gps(file);
		let dateTime = await exifr.parse(file, ['DateTimeOriginal'])
		const blob = file as Blob
		console.log("Coordinates: ", coordinate);
		if (dateTime == null) {
			dateTime = new Date();
		} else {
			dateTime = dateTime.DateTimeOriginal;
		}
		const data = {
			coordinates: coordinate,
			created: dateTime,
			projectId: projectId,
			name: file.name,
			type: file.type,
			size: file.size,
			lastModified: file.lastModified,
			blob: blob
		} as ImageInterface
		return data;
	}
	async function getFile(fileEntry: any) {
		try {
			return new Promise((resolve, reject) => fileEntry.file(resolve, reject));
		} catch (err) {
			console.log(err);
		}
	}


	const handleDrop = async (allFiles: any) => {
		setSelectedImages([]);
		setUploaded(0)
		setDragActive(false);

		if (allFiles && allFiles.length && allFiles[0]) {
			setDisplayError({ display: 'none' })
			setDisplaySuccess({ display: 'none' })

			const images = Object.values(allFiles).map(async (file: any) => {
				if (await !file.name.match(/\.(jpg|jpeg|png)$/i)) {
					setDisplayError({ display: 'block' })
					setIsFilePicked(false)
				} else {
					return await mapToImage(file);
				}
			})
			Promise.all(images).then((values) => {
				setSelectedImages(values as ImageInterface[]);
				setSelectedImagesLength(values.length)
			});
			setIsFilePicked(true)
		}
	}

	const handleFileInput = async (e: any) => {
		setDisplayError({ display: 'none' })
		setDisplaySuccess({ display: 'none' })
		setSelectedImages([]);
		setUploaded(0)

		if (e.target.files.length === 0) {
			setIsFilePicked(false)
		} else {
			const images = Object.values(e.target.files).map(async (file: any) => {
				if (await !file.name.match(/\.(jpg|jpeg|png)$/i)) {
					setDisplayError({ display: 'block' })
					setIsFilePicked(false)
				} else {
					const data = await mapToImage(file);
					return data;
				}
			})
			Promise.all(images).then((values) => {
				setSelectedImages(values as ImageInterface[])
				setSelectedImagesLength(values.length)
			});
			setIsFilePicked(true)
		}
	}
	const timeLeftDisplay = () => {
		const seconds = timeLeft / 1000;
		const minutes = Math.floor(seconds / 60);
		const hours = Math.floor(minutes / 60);
		const days = Math.floor(hours / 24);
		return (days > 0 ? `${days} days,` : '') + (hours > 0 ? ` ${hours % 24} hours,` : '') + (minutes > 0 ? `${minutes % 60} minutes,` : '') + ` ${Math.floor(seconds % 60)} seconds`;
	}

	const createDataPoint = async () => {
		const requestOptions = {
			headers: {
				Authorization: `Bearer ${await getAccessTokenSilently()}`,
			},
		}
		setLoading(true)
		try {
			const api = new ImageApi(ApiConfig());
			const startTime = new Date();
			let uploadedTimes = [startTime];
			setProgress(0);
			let inProgress = 0;
			let uploadedLocal = 0;
			while (selectedImages.length) {
				console.log("inprogress", inProgress);
				if (inProgress > 2) {
					await new Promise(resolve => setTimeout(resolve, 250));
					continue;
				}
				const image = selectedImages.pop();
				inProgress++;
				console.log("progress", progress);
				if (image) {
					api.uploadImage({ projectId: projectId, image: image.blob, location: image.coordinates }, requestOptions).then(() => {
						inProgress--;
						setUploaded(uploaded => uploaded + 1)
						uploadedTimes.push(new Date());

						if (uploadedTimes.length > 5) {
							if (uploadedTimes.length > 15)
								uploadedTimes.shift();
							let totalDiff = 0;
							for (let i = 0; i < uploadedTimes.length - 1; i++) {

								const diff = uploadedTimes[i + 1].getTime() - uploadedTimes[i].getTime();
								totalDiff += diff;
								console.log("diff", diff);
							}
							const average = totalDiff / (uploadedTimes.length - 1);
							console.log("average", average);
							setEstimatedTime(new Date(new Date().getTime() + average * selectedImages.length));
							setTimeLeft((timeLeft) => {
								let newVal = average * selectedImages.length;
								if (newVal < timeLeft || newVal > 1.5 * timeLeft)
									return newVal;
								else return timeLeft;
							});
						}
						uploadedLocal++;
						setProgress(100 * uploadedLocal / selectedImagesLength)
						setSelectedImages(selectedImages => selectedImages.filter(i => i.name !== image.name));
					}
					).catch((error) => {
						console.log("Error uploading image ", error);
						selectedImages.push(image);
						inProgress--;
					})

				}
			}
			while (inProgress > 0) {
				await new Promise(resolve => setTimeout(resolve, 250));
			}
			setLoading(false);
			setIsFilePicked(false)

		} catch (error) {
			setDisplayError({ display: 'block' })
			setDisplaySuccess({ display: 'none' })
			setLoading(false)
			console.error(error)
		}
		setDisplayError({ display: 'none' })
		setDisplaySuccess({ display: 'block' })
		setLoading(false)
	}

	return (
		<Box style={{ width: '90%' }} sx={{ m: 2, display: 'inline-flex', flexDirection: 'column', rowGap: 1, alignItems: 'center' }}>
			{(loading ? <Alert severity="info">Please keep browser tag open to complete upload, you may minimize the window or continue browsing in another tab</Alert>
				:

				<Dropzone onDrop={acceptedFiles => handleDrop(acceptedFiles)}>

					{({ getRootProps, getInputProps }) => (
						<Card style={{ width: '100%' }} variant="outlined" >
							<section style={{ width: '100%', height: '100%' }}>
								<div {...getRootProps()}>
									<input {...getInputProps()} />
									<p>Drag 'n' drop some files here, or click to select files</p>
									<p> </p>
									<p> </p>
									<CardActions style={{ justifyContent: 'center' }}>
										<Box textAlign='center'>
											<CloudUploadOutlinedIcon fontSize='large'></CloudUploadOutlinedIcon>
										</Box>

									</CardActions>
								</div>



							</section>
						</Card>
					)}
				</Dropzone>
			)}


			<Box sx={displayError} style={{ width: '100%' }}>
				<ErrorComp error={'Something went wrong'} />
			</Box>
			{uploaded === selectedImagesLength ?
				<Box sx={displaySuccess} style={{ width: '100%' }}>
					<Alert severity="success">Images successfully uploaded!</Alert>
				</Box> : <></>
			}
			{isFilePicked ? (

				<Box style={{width:'100%'}} sx={{ m: 2, display: 'inline-flex', flexDirection: 'column', rowGap: 1 }}>

					{loading ? <Card >
						<Typography sx={{ fontSize: 14 }} color="text.info" gutterBottom>
							Uploading images
						</Typography>
						<Typography>Images uploaded : {uploaded} / {selectedImagesLength}</Typography>

						<BorderLinearProgress value={progress} variant='determinate' /> 

						<Typography>{`Time left :${timeLeftDisplay()}`}</Typography>

					</Card> : <LoadingButton loading={loading} variant='contained' onClick={createDataPoint}>Submit</LoadingButton>
					}
					{<Card>
						<CardActions>
							<Typography>Selected {selectedImagesLength} images to upload </Typography>
							
							<ExpandMore
								expand={expanded}
								onClick={handleExpandClick}
								aria-expanded={expanded}
								aria-label="show details"
							>
								<ExpandMoreIcon />
							</ExpandMore>
							</CardActions>
					<Collapse in={expanded} timeout={500} >
						<TableContainer component={Paper}>
							<Table aria-label="account-table">
								<TableHead>
									<TableRow>
										<TableCell>File Name</TableCell>
										<TableCell>File Type</TableCell>
										<TableCell>Size in bytes</TableCell>
										<TableCell>Date Time Original</TableCell>
										<TableCell>Last Modified</TableCell>
										<TableCell>Coordinates</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{selectedImages.map(image => <TableRow
										key={Math.random()}
										sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
									>
										<TableCell component="th" scope="row">{image.name}
										</TableCell>
										<TableCell>{image.type}
										</TableCell>
										<TableCell align="left">{image.size}
										</TableCell>
										<TableCell>{image.created.toString()}
										</TableCell>
										<TableCell>{new Date(image.lastModified).toLocaleDateString()}
										</TableCell>
										<TableCell>{image.coordinates ? (
											<>
												<p>Latitude: {image.coordinates.latitude}</p>
												<p>Longitude: {image.coordinates.longitude}</p>
											</>
										) : (
											<p>No gps coordinates found.{/*  Image can still be uploaded for training purposes, but will not be listed on the map */}</p>
										)}
										</TableCell>
									</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer>
						</Collapse>
					</Card>}
				</Box>
			) : (<></>
			)}
		</Box >

	)
}

export default UploadImage