/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CloudStorageRead,
  CloudStorageWriteRequest,
  DataMetaRead,
  DataRequest,
  LoginRequest,
  PaginatedCloudStorageReadList,
  PaginatedMetaUserList,
  PaginatedPolymorphicProjectList,
  PaginatedTaskReadList,
  PatchedCloudStorageWriteRequest,
  PatchedLabeledDataRequest,
  ProjectRead,
  ProjectWriteRequest,
  RestrictedRegister,
  RestrictedRegisterRequest,
  TaskRead,
  TaskWriteRequest,
  Token,
  TrainingStatus,
} from '../models';
import {
    CloudStorageReadFromJSON,
    CloudStorageReadToJSON,
    CloudStorageWriteRequestFromJSON,
    CloudStorageWriteRequestToJSON,
    DataMetaReadFromJSON,
    DataMetaReadToJSON,
    DataRequestFromJSON,
    DataRequestToJSON,
    LoginRequestFromJSON,
    LoginRequestToJSON,
    PaginatedCloudStorageReadListFromJSON,
    PaginatedCloudStorageReadListToJSON,
    PaginatedMetaUserListFromJSON,
    PaginatedMetaUserListToJSON,
    PaginatedPolymorphicProjectListFromJSON,
    PaginatedPolymorphicProjectListToJSON,
    PaginatedTaskReadListFromJSON,
    PaginatedTaskReadListToJSON,
    PatchedCloudStorageWriteRequestFromJSON,
    PatchedCloudStorageWriteRequestToJSON,
    PatchedLabeledDataRequestFromJSON,
    PatchedLabeledDataRequestToJSON,
    ProjectReadFromJSON,
    ProjectReadToJSON,
    ProjectWriteRequestFromJSON,
    ProjectWriteRequestToJSON,
    RestrictedRegisterFromJSON,
    RestrictedRegisterToJSON,
    RestrictedRegisterRequestFromJSON,
    RestrictedRegisterRequestToJSON,
    TaskReadFromJSON,
    TaskReadToJSON,
    TaskWriteRequestFromJSON,
    TaskWriteRequestToJSON,
    TokenFromJSON,
    TokenToJSON,
    TrainingStatusFromJSON,
    TrainingStatusToJSON,
} from '../models';

export interface AuthCreateLoginRequest {
    loginRequest: LoginRequest;
    xOrganization?: string;
    org?: string;
    orgId?: number;
}

export interface AuthCreateRegisterRequest {
    restrictedRegisterRequest: RestrictedRegisterRequest;
    xOrganization?: string;
    org?: string;
    orgId?: number;
}

export interface CloudstoragesCreateRequest {
    cloudStorageWriteRequest: CloudStorageWriteRequest;
    xOrganization?: string;
    org?: string;
    orgId?: number;
}

export interface CloudstoragesDestroyRequest {
    id: number;
    xOrganization?: string;
    org?: string;
    orgId?: number;
}

export interface CloudstoragesListRequest {
    xOrganization?: string;
    filter?: string;
    org?: string;
    orgId?: number;
    page?: number;
    pageSize?: number;
    search?: string;
    sort?: string;
}

export interface CloudstoragesPartialUpdateRequest {
    id: number;
    xOrganization?: string;
    org?: string;
    orgId?: number;
    patchedCloudStorageWriteRequest?: PatchedCloudStorageWriteRequest;
}

export interface CloudstoragesRetrieveContentRequest {
    id: number;
    xOrganization?: string;
    manifestPath?: string;
    org?: string;
    orgId?: number;
}

export interface JobsPartialUpdateAnnotationsRequest {
    action: JobsPartialUpdateAnnotationsActionEnum;
    id: number;
    xOrganization?: string;
    org?: string;
    orgId?: number;
    patchedLabeledDataRequest?: PatchedLabeledDataRequest;
}

export interface JobsRetrieveDataMetaRequest {
    id: number;
    xOrganization?: string;
    org?: string;
    orgId?: number;
}

export interface ProjectsCreateRequest {
    projectWriteRequest: ProjectWriteRequest;
    xOrganization?: string;
    org?: string;
    orgId?: number;
}

export interface ProjectsDestroyRequest {
    trainingProjectId: number;
    xOrganization?: string;
    org?: string;
    orgId?: number;
}

export interface ProjectsListRequest {
    xOrganization?: string;
    filter?: string;
    org?: string;
    orgId?: number;
    page?: number;
    pageSize?: number;
    search?: string;
    sort?: string;
}

export interface ProjectsPartialUpdateRequest {
    trainingProjectId: number;
    xOrganization?: string;
    org?: string;
    orgId?: number;
}

export interface ProjectsRetrieveRequest {
    trainingProjectId: number;
    xOrganization?: string;
    org?: string;
    orgId?: number;
}

export interface ProjectsRetrieveDatasetRequest {
    trainingProjectId: number;
    xOrganization?: string;
    action?: ProjectsRetrieveDatasetActionEnum;
    cloudStorageId?: number;
    filename?: string;
    format?: string;
    location?: ProjectsRetrieveDatasetLocationEnum;
    org?: string;
    orgId?: number;
    useDefaultLocation?: boolean;
}

export interface StartTrainingRequest {
    trainingProjectId: number;
    epochs?: number;
    useUnknown?: boolean;
    inputSize?: number;
    modelType?: string;
}

export interface TasksCreateRequest {
    taskWriteRequest: TaskWriteRequest;
    xOrganization?: string;
    org?: string;
    orgId?: number;
}

export interface TasksCreateDataRequest {
    id: number;
    projectId: string;
    dataRequest: DataRequest;
    uploadFinish?: boolean;
    uploadMultiple?: boolean;
    uploadStart?: boolean;
    xOrganization?: string;
    org?: string;
    orgId?: number;
}

export interface TasksDestroyRequest {
    id: number;
    xOrganization?: string;
    org?: string;
    orgId?: number;
}

export interface TasksListRequest {
    xOrganization?: string;
    filter?: string;
    org?: string;
    orgId?: number;
    page?: number;
    pageSize?: number;
    search?: string;
    sort?: string;
}

export interface TasksRetrieveRequest {
    id: number;
    xOrganization?: string;
    org?: string;
    orgId?: number;
}

export interface TasksetCreateRequest {
    taskWriteRequest: Array<TaskWriteRequest>;
    xOrganization?: string;
    org?: string;
    orgId?: number;
}

export interface TrainingStatusRequest {
    trainingProjectId: number;
}

export interface UpdateTrainingStatusRequest {
    trainingProjectId: number;
    status?: string;
}

export interface UploadModelRequest {
    modelName: string;
    modelVersion: number;
}

export interface UsersDestroyRequest {
    id: number;
    xOrganization?: string;
    org?: string;
    orgId?: number;
}

export interface UsersListRequest {
    xOrganization?: string;
    filter?: string;
    org?: string;
    orgId?: number;
    page?: number;
    pageSize?: number;
    search?: string;
    sort?: string;
}

/**
 * 
 */
export class TrainingApi extends runtime.BaseAPI {

    /**
     * Check the credentials and return the REST Token if the credentials are valid and authenticated. Calls Django Auth login method to register User ID in Django session framework  Accept the following POST parameters: username, password Return the REST Framework Token Object\'s key.
     */
    async authCreateLoginRaw(requestParameters: AuthCreateLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Token>> {
        if (requestParameters.loginRequest === null || requestParameters.loginRequest === undefined) {
            throw new runtime.RequiredError('loginRequest','Required parameter requestParameters.loginRequest was null or undefined when calling authCreateLogin.');
        }

        const queryParameters: any = {};

        if (requestParameters.org !== undefined) {
            queryParameters['org'] = requestParameters.org;
        }

        if (requestParameters.orgId !== undefined) {
            queryParameters['org_id'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xOrganization !== undefined && requestParameters.xOrganization !== null) {
            headerParameters['X-Organization'] = String(requestParameters.xOrganization);
        }

        const response = await this.request({
            path: `/training/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginRequestToJSON(requestParameters.loginRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenFromJSON(jsonValue));
    }

    /**
     * Check the credentials and return the REST Token if the credentials are valid and authenticated. Calls Django Auth login method to register User ID in Django session framework  Accept the following POST parameters: username, password Return the REST Framework Token Object\'s key.
     */
    async authCreateLogin(requestParameters: AuthCreateLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Token> {
        const response = await this.authCreateLoginRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async authCreateRegisterRaw(requestParameters: AuthCreateRegisterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestrictedRegister>> {
        if (requestParameters.restrictedRegisterRequest === null || requestParameters.restrictedRegisterRequest === undefined) {
            throw new runtime.RequiredError('restrictedRegisterRequest','Required parameter requestParameters.restrictedRegisterRequest was null or undefined when calling authCreateRegister.');
        }

        const queryParameters: any = {};

        if (requestParameters.org !== undefined) {
            queryParameters['org'] = requestParameters.org;
        }

        if (requestParameters.orgId !== undefined) {
            queryParameters['org_id'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xOrganization !== undefined && requestParameters.xOrganization !== null) {
            headerParameters['X-Organization'] = String(requestParameters.xOrganization);
        }

        const response = await this.request({
            path: `/training/register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RestrictedRegisterRequestToJSON(requestParameters.restrictedRegisterRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestrictedRegisterFromJSON(jsonValue));
    }

    /**
     */
    async authCreateRegister(requestParameters: AuthCreateRegisterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestrictedRegister> {
        const response = await this.authCreateRegisterRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Method creates a cloud storage with a specified characteristics
     */
    async cloudstoragesCreateRaw(requestParameters: CloudstoragesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CloudStorageRead>> {
        if (requestParameters.cloudStorageWriteRequest === null || requestParameters.cloudStorageWriteRequest === undefined) {
            throw new runtime.RequiredError('cloudStorageWriteRequest','Required parameter requestParameters.cloudStorageWriteRequest was null or undefined when calling cloudstoragesCreate.');
        }

        const queryParameters: any = {};

        if (requestParameters.org !== undefined) {
            queryParameters['org'] = requestParameters.org;
        }

        if (requestParameters.orgId !== undefined) {
            queryParameters['org_id'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xOrganization !== undefined && requestParameters.xOrganization !== null) {
            headerParameters['X-Organization'] = String(requestParameters.xOrganization);
        }

        const response = await this.request({
            path: `/training/cloudstorages`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CloudStorageWriteRequestToJSON(requestParameters.cloudStorageWriteRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CloudStorageReadFromJSON(jsonValue));
    }

    /**
     * Method creates a cloud storage with a specified characteristics
     */
    async cloudstoragesCreate(requestParameters: CloudstoragesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CloudStorageRead> {
        const response = await this.cloudstoragesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Method deletes a specific cloud storage
     */
    async cloudstoragesDestroyRaw(requestParameters: CloudstoragesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cloudstoragesDestroy.');
        }

        const queryParameters: any = {};

        if (requestParameters.org !== undefined) {
            queryParameters['org'] = requestParameters.org;
        }

        if (requestParameters.orgId !== undefined) {
            queryParameters['org_id'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xOrganization !== undefined && requestParameters.xOrganization !== null) {
            headerParameters['X-Organization'] = String(requestParameters.xOrganization);
        }

        const response = await this.request({
            path: `/training/cloudstorages/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Method deletes a specific cloud storage
     */
    async cloudstoragesDestroy(requestParameters: CloudstoragesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.cloudstoragesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Returns a paginated list of storages according to query parameters
     */
    async cloudstoragesListRaw(requestParameters: CloudstoragesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedCloudStorageReadList>> {
        const queryParameters: any = {};

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        if (requestParameters.org !== undefined) {
            queryParameters['org'] = requestParameters.org;
        }

        if (requestParameters.orgId !== undefined) {
            queryParameters['org_id'] = requestParameters.orgId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xOrganization !== undefined && requestParameters.xOrganization !== null) {
            headerParameters['X-Organization'] = String(requestParameters.xOrganization);
        }

        const response = await this.request({
            path: `/training/cloudstorages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedCloudStorageReadListFromJSON(jsonValue));
    }

    /**
     * Returns a paginated list of storages according to query parameters
     */
    async cloudstoragesList(requestParameters: CloudstoragesListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedCloudStorageReadList> {
        const response = await this.cloudstoragesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Methods does a partial update of chosen fields in a cloud storage instance
     */
    async cloudstoragesPartialUpdateRaw(requestParameters: CloudstoragesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CloudStorageRead>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cloudstoragesPartialUpdate.');
        }

        const queryParameters: any = {};

        if (requestParameters.org !== undefined) {
            queryParameters['org'] = requestParameters.org;
        }

        if (requestParameters.orgId !== undefined) {
            queryParameters['org_id'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xOrganization !== undefined && requestParameters.xOrganization !== null) {
            headerParameters['X-Organization'] = String(requestParameters.xOrganization);
        }

        const response = await this.request({
            path: `/training/cloudstorages/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedCloudStorageWriteRequestToJSON(requestParameters.patchedCloudStorageWriteRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CloudStorageReadFromJSON(jsonValue));
    }

    /**
     * Methods does a partial update of chosen fields in a cloud storage instance
     */
    async cloudstoragesPartialUpdate(requestParameters: CloudstoragesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CloudStorageRead> {
        const response = await this.cloudstoragesPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Method returns a manifest content
     */
    async cloudstoragesRetrieveContentRaw(requestParameters: CloudstoragesRetrieveContentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cloudstoragesRetrieveContent.');
        }

        const queryParameters: any = {};

        if (requestParameters.manifestPath !== undefined) {
            queryParameters['manifest_path'] = requestParameters.manifestPath;
        }

        if (requestParameters.org !== undefined) {
            queryParameters['org'] = requestParameters.org;
        }

        if (requestParameters.orgId !== undefined) {
            queryParameters['org_id'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xOrganization !== undefined && requestParameters.xOrganization !== null) {
            headerParameters['X-Organization'] = String(requestParameters.xOrganization);
        }

        const response = await this.request({
            path: `/training/cloudstorages/{id}/content`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Method returns a manifest content
     */
    async cloudstoragesRetrieveContent(requestParameters: CloudstoragesRetrieveContentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.cloudstoragesRetrieveContentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get csrftoken and sessionid
     */
    async getCookiesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/training/cookies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get csrftoken and sessionid
     */
    async getCookies(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getCookiesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Method gets status of annotations in a specific job
     */
    async getLabelingStatusRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: string; }>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/training/jobs/annotations/status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Method gets status of annotations in a specific job
     */
    async getLabelingStatus(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: string; }> {
        const response = await this.getLabelingStatusRaw(initOverrides);
        return await response.value();
    }

    /**
     * Method performs a partial update of annotations in a specific job
     */
    async jobsPartialUpdateAnnotationsRaw(requestParameters: JobsPartialUpdateAnnotationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.action === null || requestParameters.action === undefined) {
            throw new runtime.RequiredError('action','Required parameter requestParameters.action was null or undefined when calling jobsPartialUpdateAnnotations.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling jobsPartialUpdateAnnotations.');
        }

        const queryParameters: any = {};

        if (requestParameters.action !== undefined) {
            queryParameters['action'] = requestParameters.action;
        }

        if (requestParameters.org !== undefined) {
            queryParameters['org'] = requestParameters.org;
        }

        if (requestParameters.orgId !== undefined) {
            queryParameters['org_id'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xOrganization !== undefined && requestParameters.xOrganization !== null) {
            headerParameters['X-Organization'] = String(requestParameters.xOrganization);
        }

        const response = await this.request({
            path: `/training/jobs/{id}/annotations`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedLabeledDataRequestToJSON(requestParameters.patchedLabeledDataRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Method performs a partial update of annotations in a specific job
     */
    async jobsPartialUpdateAnnotations(requestParameters: JobsPartialUpdateAnnotationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.jobsPartialUpdateAnnotationsRaw(requestParameters, initOverrides);
    }

    /**
     * Method provides a meta information about media files which are related with the job
     */
    async jobsRetrieveDataMetaRaw(requestParameters: JobsRetrieveDataMetaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DataMetaRead>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling jobsRetrieveDataMeta.');
        }

        const queryParameters: any = {};

        if (requestParameters.org !== undefined) {
            queryParameters['org'] = requestParameters.org;
        }

        if (requestParameters.orgId !== undefined) {
            queryParameters['org_id'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xOrganization !== undefined && requestParameters.xOrganization !== null) {
            headerParameters['X-Organization'] = String(requestParameters.xOrganization);
        }

        const response = await this.request({
            path: `/training/jobs/{id}/data/meta`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DataMetaReadFromJSON(jsonValue));
    }

    /**
     * Method provides a meta information about media files which are related with the job
     */
    async jobsRetrieveDataMeta(requestParameters: JobsRetrieveDataMetaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DataMetaRead> {
        const response = await this.jobsRetrieveDataMetaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Method creates a new project
     */
    async projectsCreateRaw(requestParameters: ProjectsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectRead>> {
        if (requestParameters.projectWriteRequest === null || requestParameters.projectWriteRequest === undefined) {
            throw new runtime.RequiredError('projectWriteRequest','Required parameter requestParameters.projectWriteRequest was null or undefined when calling projectsCreate.');
        }

        const queryParameters: any = {};

        if (requestParameters.org !== undefined) {
            queryParameters['org'] = requestParameters.org;
        }

        if (requestParameters.orgId !== undefined) {
            queryParameters['org_id'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xOrganization !== undefined && requestParameters.xOrganization !== null) {
            headerParameters['X-Organization'] = String(requestParameters.xOrganization);
        }

        const response = await this.request({
            path: `/training/projects`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectWriteRequestToJSON(requestParameters.projectWriteRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectReadFromJSON(jsonValue));
    }

    /**
     * Method creates a new project
     */
    async projectsCreate(requestParameters: ProjectsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectRead> {
        const response = await this.projectsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Method deletes a specific project
     */
    async projectsDestroyRaw(requestParameters: ProjectsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.trainingProjectId === null || requestParameters.trainingProjectId === undefined) {
            throw new runtime.RequiredError('trainingProjectId','Required parameter requestParameters.trainingProjectId was null or undefined when calling projectsDestroy.');
        }

        const queryParameters: any = {};

        if (requestParameters.org !== undefined) {
            queryParameters['org'] = requestParameters.org;
        }

        if (requestParameters.orgId !== undefined) {
            queryParameters['org_id'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xOrganization !== undefined && requestParameters.xOrganization !== null) {
            headerParameters['X-Organization'] = String(requestParameters.xOrganization);
        }

        const response = await this.request({
            path: `/training/projects/{trainingProjectId}`.replace(`{${"trainingProjectId"}}`, encodeURIComponent(String(requestParameters.trainingProjectId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Method deletes a specific project
     */
    async projectsDestroy(requestParameters: ProjectsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.projectsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Returns a paginated list of projects according to query parameters (12 projects per page)
     */
    async projectsListRaw(requestParameters: ProjectsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedPolymorphicProjectList>> {
        const queryParameters: any = {};

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        if (requestParameters.org !== undefined) {
            queryParameters['org'] = requestParameters.org;
        }

        if (requestParameters.orgId !== undefined) {
            queryParameters['org_id'] = requestParameters.orgId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xOrganization !== undefined && requestParameters.xOrganization !== null) {
            headerParameters['X-Organization'] = String(requestParameters.xOrganization);
        }

        const response = await this.request({
            path: `/training/projects`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedPolymorphicProjectListFromJSON(jsonValue));
    }

    /**
     * Returns a paginated list of projects according to query parameters (12 projects per page)
     */
    async projectsList(requestParameters: ProjectsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedPolymorphicProjectList> {
        const response = await this.projectsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Method updates a specific project
     */
    async projectsPartialUpdateRaw(requestParameters: ProjectsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectRead>> {
        if (requestParameters.trainingProjectId === null || requestParameters.trainingProjectId === undefined) {
            throw new runtime.RequiredError('trainingProjectId','Required parameter requestParameters.trainingProjectId was null or undefined when calling projectsPartialUpdate.');
        }

        const queryParameters: any = {};

        if (requestParameters.org !== undefined) {
            queryParameters['org'] = requestParameters.org;
        }

        if (requestParameters.orgId !== undefined) {
            queryParameters['org_id'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xOrganization !== undefined && requestParameters.xOrganization !== null) {
            headerParameters['X-Organization'] = String(requestParameters.xOrganization);
        }

        const response = await this.request({
            path: `/training/projects/{trainingProjectId}`.replace(`{${"trainingProjectId"}}`, encodeURIComponent(String(requestParameters.trainingProjectId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectReadFromJSON(jsonValue));
    }

    /**
     * Method updates a specific project
     */
    async projectsPartialUpdate(requestParameters: ProjectsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectRead> {
        const response = await this.projectsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Method returns details of a specific project
     */
    async projectsRetrieveRaw(requestParameters: ProjectsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectRead>> {
        if (requestParameters.trainingProjectId === null || requestParameters.trainingProjectId === undefined) {
            throw new runtime.RequiredError('trainingProjectId','Required parameter requestParameters.trainingProjectId was null or undefined when calling projectsRetrieve.');
        }

        const queryParameters: any = {};

        if (requestParameters.org !== undefined) {
            queryParameters['org'] = requestParameters.org;
        }

        if (requestParameters.orgId !== undefined) {
            queryParameters['org_id'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xOrganization !== undefined && requestParameters.xOrganization !== null) {
            headerParameters['X-Organization'] = String(requestParameters.xOrganization);
        }

        const response = await this.request({
            path: `/training/projects/{trainingProjectId}`.replace(`{${"trainingProjectId"}}`, encodeURIComponent(String(requestParameters.trainingProjectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectReadFromJSON(jsonValue));
    }

    /**
     * Method returns details of a specific project
     */
    async projectsRetrieve(requestParameters: ProjectsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectRead> {
        const response = await this.projectsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Export project as a dataset in a specific format
     */
    async projectsRetrieveDatasetRaw(requestParameters: ProjectsRetrieveDatasetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.trainingProjectId === null || requestParameters.trainingProjectId === undefined) {
            throw new runtime.RequiredError('trainingProjectId','Required parameter requestParameters.trainingProjectId was null or undefined when calling projectsRetrieveDataset.');
        }

        const queryParameters: any = {};

        if (requestParameters.action !== undefined) {
            queryParameters['action'] = requestParameters.action;
        }

        if (requestParameters.cloudStorageId !== undefined) {
            queryParameters['cloud_storage_id'] = requestParameters.cloudStorageId;
        }

        if (requestParameters.filename !== undefined) {
            queryParameters['filename'] = requestParameters.filename;
        }

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        if (requestParameters.location !== undefined) {
            queryParameters['location'] = requestParameters.location;
        }

        if (requestParameters.org !== undefined) {
            queryParameters['org'] = requestParameters.org;
        }

        if (requestParameters.orgId !== undefined) {
            queryParameters['org_id'] = requestParameters.orgId;
        }

        if (requestParameters.useDefaultLocation !== undefined) {
            queryParameters['use_default_location'] = requestParameters.useDefaultLocation;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xOrganization !== undefined && requestParameters.xOrganization !== null) {
            headerParameters['X-Organization'] = String(requestParameters.xOrganization);
        }

        const response = await this.request({
            path: `/training/projects/{trainingProjectId}/dataset`.replace(`{${"trainingProjectId"}}`, encodeURIComponent(String(requestParameters.trainingProjectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Export project as a dataset in a specific format
     */
    async projectsRetrieveDataset(requestParameters: ProjectsRetrieveDatasetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.projectsRetrieveDatasetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Starts a training on the trainingProjectId
     */
    async startTrainingRaw(requestParameters: StartTrainingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TrainingStatus>> {
        if (requestParameters.trainingProjectId === null || requestParameters.trainingProjectId === undefined) {
            throw new runtime.RequiredError('trainingProjectId','Required parameter requestParameters.trainingProjectId was null or undefined when calling startTraining.');
        }

        const queryParameters: any = {};

        if (requestParameters.epochs !== undefined) {
            queryParameters['epochs'] = requestParameters.epochs;
        }

        if (requestParameters.useUnknown !== undefined) {
            queryParameters['useUnknown'] = requestParameters.useUnknown;
        }

        if (requestParameters.inputSize !== undefined) {
            queryParameters['inputSize'] = requestParameters.inputSize;
        }

        if (requestParameters.modelType !== undefined) {
            queryParameters['modelType'] = requestParameters.modelType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/training/projects/{trainingProjectId}/train`.replace(`{${"trainingProjectId"}}`, encodeURIComponent(String(requestParameters.trainingProjectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrainingStatusFromJSON(jsonValue));
    }

    /**
     * Starts a training on the trainingProjectId
     */
    async startTraining(requestParameters: StartTrainingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TrainingStatus> {
        const response = await this.startTrainingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Method creates a new task in a database without any attached images and videos
     */
    async tasksCreateRaw(requestParameters: TasksCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TaskRead>> {
        if (requestParameters.taskWriteRequest === null || requestParameters.taskWriteRequest === undefined) {
            throw new runtime.RequiredError('taskWriteRequest','Required parameter requestParameters.taskWriteRequest was null or undefined when calling tasksCreate.');
        }

        const queryParameters: any = {};

        if (requestParameters.org !== undefined) {
            queryParameters['org'] = requestParameters.org;
        }

        if (requestParameters.orgId !== undefined) {
            queryParameters['org_id'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xOrganization !== undefined && requestParameters.xOrganization !== null) {
            headerParameters['X-Organization'] = String(requestParameters.xOrganization);
        }

        const response = await this.request({
            path: `/training/tasks`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TaskWriteRequestToJSON(requestParameters.taskWriteRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TaskReadFromJSON(jsonValue));
    }

    /**
     * Method creates a new task in a database without any attached images and videos
     */
    async tasksCreate(requestParameters: TasksCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TaskRead> {
        const response = await this.tasksCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Method permanently attaches images or video to a task. Supports tus uploads, see more https://tus.io/
     */
    async tasksCreateDataRaw(requestParameters: TasksCreateDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DataRequest>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling tasksCreateData.');
        }

        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling tasksCreateData.');
        }

        if (requestParameters.dataRequest === null || requestParameters.dataRequest === undefined) {
            throw new runtime.RequiredError('dataRequest','Required parameter requestParameters.dataRequest was null or undefined when calling tasksCreateData.');
        }

        const queryParameters: any = {};

        if (requestParameters.org !== undefined) {
            queryParameters['org'] = requestParameters.org;
        }

        if (requestParameters.orgId !== undefined) {
            queryParameters['org_id'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.uploadFinish !== undefined && requestParameters.uploadFinish !== null) {
            headerParameters['Upload-Finish'] = String(requestParameters.uploadFinish);
        }

        if (requestParameters.uploadMultiple !== undefined && requestParameters.uploadMultiple !== null) {
            headerParameters['Upload-Multiple'] = String(requestParameters.uploadMultiple);
        }

        if (requestParameters.uploadStart !== undefined && requestParameters.uploadStart !== null) {
            headerParameters['Upload-Start'] = String(requestParameters.uploadStart);
        }

        if (requestParameters.xOrganization !== undefined && requestParameters.xOrganization !== null) {
            headerParameters['X-Organization'] = String(requestParameters.xOrganization);
        }

        const response = await this.request({
            path: `/training/tasks/{id}/data/{projectId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DataRequestToJSON(requestParameters.dataRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DataRequestFromJSON(jsonValue));
    }

    /**
     * Method permanently attaches images or video to a task. Supports tus uploads, see more https://tus.io/
     */
    async tasksCreateData(requestParameters: TasksCreateDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DataRequest> {
        const response = await this.tasksCreateDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Method deletes a specific task, all attached jobs, annotations, and data
     */
    async tasksDestroyRaw(requestParameters: TasksDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling tasksDestroy.');
        }

        const queryParameters: any = {};

        if (requestParameters.org !== undefined) {
            queryParameters['org'] = requestParameters.org;
        }

        if (requestParameters.orgId !== undefined) {
            queryParameters['org_id'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xOrganization !== undefined && requestParameters.xOrganization !== null) {
            headerParameters['X-Organization'] = String(requestParameters.xOrganization);
        }

        const response = await this.request({
            path: `/training/tasks/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Method deletes a specific task, all attached jobs, annotations, and data
     */
    async tasksDestroy(requestParameters: TasksDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.tasksDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Returns a paginated list of tasks according to query parameters (10 tasks per page)
     */
    async tasksListRaw(requestParameters: TasksListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedTaskReadList>> {
        const queryParameters: any = {};

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        if (requestParameters.org !== undefined) {
            queryParameters['org'] = requestParameters.org;
        }

        if (requestParameters.orgId !== undefined) {
            queryParameters['org_id'] = requestParameters.orgId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xOrganization !== undefined && requestParameters.xOrganization !== null) {
            headerParameters['X-Organization'] = String(requestParameters.xOrganization);
        }

        const response = await this.request({
            path: `/training/tasks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedTaskReadListFromJSON(jsonValue));
    }

    /**
     * Returns a paginated list of tasks according to query parameters (10 tasks per page)
     */
    async tasksList(requestParameters: TasksListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedTaskReadList> {
        const response = await this.tasksListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Method returns details of a specific task
     */
    async tasksRetrieveRaw(requestParameters: TasksRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TaskRead>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling tasksRetrieve.');
        }

        const queryParameters: any = {};

        if (requestParameters.org !== undefined) {
            queryParameters['org'] = requestParameters.org;
        }

        if (requestParameters.orgId !== undefined) {
            queryParameters['org_id'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xOrganization !== undefined && requestParameters.xOrganization !== null) {
            headerParameters['X-Organization'] = String(requestParameters.xOrganization);
        }

        const response = await this.request({
            path: `/training/tasks/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TaskReadFromJSON(jsonValue));
    }

    /**
     * Method returns details of a specific task
     */
    async tasksRetrieve(requestParameters: TasksRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TaskRead> {
        const response = await this.tasksRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Method creates a new task in a database without any attached images and videos
     */
    async tasksetCreateRaw(requestParameters: TasksetCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TaskRead>>> {
        if (requestParameters.taskWriteRequest === null || requestParameters.taskWriteRequest === undefined) {
            throw new runtime.RequiredError('taskWriteRequest','Required parameter requestParameters.taskWriteRequest was null or undefined when calling tasksetCreate.');
        }

        const queryParameters: any = {};

        if (requestParameters.org !== undefined) {
            queryParameters['org'] = requestParameters.org;
        }

        if (requestParameters.orgId !== undefined) {
            queryParameters['org_id'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xOrganization !== undefined && requestParameters.xOrganization !== null) {
            headerParameters['X-Organization'] = String(requestParameters.xOrganization);
        }

        const response = await this.request({
            path: `/training/taskset`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.taskWriteRequest.map(TaskWriteRequestToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TaskReadFromJSON));
    }

    /**
     * Method creates a new task in a database without any attached images and videos
     */
    async tasksetCreate(requestParameters: TasksetCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TaskRead>> {
        const response = await this.tasksetCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Checks status of training
     */
    async trainingStatusRaw(requestParameters: TrainingStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TrainingStatus>> {
        if (requestParameters.trainingProjectId === null || requestParameters.trainingProjectId === undefined) {
            throw new runtime.RequiredError('trainingProjectId','Required parameter requestParameters.trainingProjectId was null or undefined when calling trainingStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/training/projects/{trainingProjectId}/status`.replace(`{${"trainingProjectId"}}`, encodeURIComponent(String(requestParameters.trainingProjectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrainingStatusFromJSON(jsonValue));
    }

    /**
     * Checks status of training
     */
    async trainingStatus(requestParameters: TrainingStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TrainingStatus> {
        const response = await this.trainingStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update status of training
     */
    async updateTrainingStatusRaw(requestParameters: UpdateTrainingStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TrainingStatus>> {
        if (requestParameters.trainingProjectId === null || requestParameters.trainingProjectId === undefined) {
            throw new runtime.RequiredError('trainingProjectId','Required parameter requestParameters.trainingProjectId was null or undefined when calling updateTrainingStatus.');
        }

        const queryParameters: any = {};

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/training/projects/{trainingProjectId}/status`.replace(`{${"trainingProjectId"}}`, encodeURIComponent(String(requestParameters.trainingProjectId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrainingStatusFromJSON(jsonValue));
    }

    /**
     * Update status of training
     */
    async updateTrainingStatus(requestParameters: UpdateTrainingStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TrainingStatus> {
        const response = await this.updateTrainingStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Uploads trained model to S3
     */
    async uploadModelRaw(requestParameters: UploadModelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TrainingStatus>> {
        if (requestParameters.modelName === null || requestParameters.modelName === undefined) {
            throw new runtime.RequiredError('modelName','Required parameter requestParameters.modelName was null or undefined when calling uploadModel.');
        }

        if (requestParameters.modelVersion === null || requestParameters.modelVersion === undefined) {
            throw new runtime.RequiredError('modelVersion','Required parameter requestParameters.modelVersion was null or undefined when calling uploadModel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/training/projects/{modelName}/{modelVersion}/uploadmodel`.replace(`{${"modelName"}}`, encodeURIComponent(String(requestParameters.modelName))).replace(`{${"modelVersion"}}`, encodeURIComponent(String(requestParameters.modelVersion))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TrainingStatusFromJSON(jsonValue));
    }

    /**
     * Uploads trained model to S3
     */
    async uploadModel(requestParameters: UploadModelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TrainingStatus> {
        const response = await this.uploadModelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Method deletes a specific user from the server
     */
    async usersDestroyRaw(requestParameters: UsersDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling usersDestroy.');
        }

        const queryParameters: any = {};

        if (requestParameters.org !== undefined) {
            queryParameters['org'] = requestParameters.org;
        }

        if (requestParameters.orgId !== undefined) {
            queryParameters['org_id'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xOrganization !== undefined && requestParameters.xOrganization !== null) {
            headerParameters['X-Organization'] = String(requestParameters.xOrganization);
        }

        const response = await this.request({
            path: `/training/user/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Method deletes a specific user from the server
     */
    async usersDestroy(requestParameters: UsersDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.usersDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Method provides a paginated list of users registered on the server
     */
    async usersListRaw(requestParameters: UsersListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedMetaUserList>> {
        const queryParameters: any = {};

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        if (requestParameters.org !== undefined) {
            queryParameters['org'] = requestParameters.org;
        }

        if (requestParameters.orgId !== undefined) {
            queryParameters['org_id'] = requestParameters.orgId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xOrganization !== undefined && requestParameters.xOrganization !== null) {
            headerParameters['X-Organization'] = String(requestParameters.xOrganization);
        }

        const response = await this.request({
            path: `/training/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedMetaUserListFromJSON(jsonValue));
    }

    /**
     * Method provides a paginated list of users registered on the server
     */
    async usersList(requestParameters: UsersListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedMetaUserList> {
        const response = await this.usersListRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const JobsPartialUpdateAnnotationsActionEnum = {
    Create: 'create',
    Delete: 'delete',
    Update: 'update'
} as const;
export type JobsPartialUpdateAnnotationsActionEnum = typeof JobsPartialUpdateAnnotationsActionEnum[keyof typeof JobsPartialUpdateAnnotationsActionEnum];
/**
 * @export
 */
export const ProjectsRetrieveDatasetActionEnum = {
    Download: 'download',
    ImportStatus: 'import_status'
} as const;
export type ProjectsRetrieveDatasetActionEnum = typeof ProjectsRetrieveDatasetActionEnum[keyof typeof ProjectsRetrieveDatasetActionEnum];
/**
 * @export
 */
export const ProjectsRetrieveDatasetLocationEnum = {
    CloudStorage: 'cloud_storage',
    Local: 'local'
} as const;
export type ProjectsRetrieveDatasetLocationEnum = typeof ProjectsRetrieveDatasetLocationEnum[keyof typeof ProjectsRetrieveDatasetLocationEnum];
