/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const JobStage = {
    Annotation: 'annotation',
    Validation: 'validation',
    Acceptance: 'acceptance'
} as const;
export type JobStage = typeof JobStage[keyof typeof JobStage];


export function JobStageFromJSON(json: any): JobStage {
    return JobStageFromJSONTyped(json, false);
}

export function JobStageFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobStage {
    return json as JobStage;
}

export function JobStageToJSON(value?: JobStage | null): any {
    return value as any;
}

