import { LoadingButton } from '@mui/lab'
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import DownloadIcon from '@mui/icons-material/Download';
import { useAuth0 } from '@auth0/auth0-react';
import { Export, ExportApi, GetProjectExportFormatEnum, Project, ProjectApi } from '../api';
import ApiConfig from './ApiConfig';

function ExportProject(props: { project: Project, disabledButtons: boolean }) {
	const { getAccessTokenSilently } = useAuth0()
	const [isDownloading, setIsDownloading] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
	const header = async () => {
		return { headers: { Authorization: `Bearer ${await getAccessTokenSilently()}` } };
	}

	const wait = (delay: number) => {
		return new Promise((resolve) => setTimeout(resolve, delay));
	}

	const fetchRetry = async (exportId: string,format: GetProjectExportFormatEnum, delay: number, tries: number) => {
		const onError = (err: any) => {
			console.log("Retrying")
			let triesLeft = tries - 1;
			if (!triesLeft) {
				console.log("Failed to export dataset")
				setIsDownloading(false)
				throw err;
			}
			wait(delay).then(async () => await fetchRetry(exportId,format, delay, triesLeft));
		}
		return await new ExportApi(ApiConfig()).getExportById({ exportId }, header).then(async (res) => {
			var csvURL = window.URL.createObjectURL(res);
			let tempLink = document.createElement('a');
            const extention = format === GetProjectExportFormatEnum.Landxml ? "xml" : "csv";
			tempLink.href = csvURL;
			tempLink.setAttribute('download', `${props.project.projectId}.${extention}`);
			tempLink.click();
			setIsDownloading(false)
		}).catch(onError);
	}

	const exportDataset = async (format: GetProjectExportFormatEnum) => {
		setIsDownloading(true)
		let req = {
			projectId: props.project.projectId as string,
			format: format
		}
		await new ProjectApi(ApiConfig()).getProjectExport(req, header).then(async (res: Export) => {
			await fetchRetry(res.exportId as string,format, 2500, 3)
		}).catch(err => {
			console.error(err);
			setIsDownloading(false);
		})
	}

	return (
		<>
			{isDownloading ?
				<LoadingButton loading={isDownloading} />
				:
				<Tooltip title="Export data">
					<IconButton /* onClick={exportDataset}  */ onClick={handleClick} disabled={props.disabledButtons}>
						<DownloadIcon />
					</IconButton>
                    
				</Tooltip>
                
			}
            <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={()=>{
            handleClose();
            exportDataset(GetProjectExportFormatEnum.Landxml)}}>LandXml</MenuItem>
        <MenuItem onClick={()=>{
            handleClose();
            exportDataset(GetProjectExportFormatEnum.Csv)}}>Csv</MenuItem>
      </Menu>
		</>
	)
}

export default ExportProject