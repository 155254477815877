/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AttributeValRequest } from './AttributeValRequest';
import {
    AttributeValRequestFromJSON,
    AttributeValRequestFromJSONTyped,
    AttributeValRequestToJSON,
} from './AttributeValRequest';

/**
 * 
 * @export
 * @interface LabeledImageRequest
 */
export interface LabeledImageRequest {
    /**
     * 
     * @type {number}
     * @memberof LabeledImageRequest
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof LabeledImageRequest
     */
    frame: number;
    /**
     * 
     * @type {number}
     * @memberof LabeledImageRequest
     */
    labelId: number;
    /**
     * 
     * @type {number}
     * @memberof LabeledImageRequest
     */
    group?: number;
    /**
     * 
     * @type {string}
     * @memberof LabeledImageRequest
     */
    source?: string;
    /**
     * 
     * @type {Array<AttributeValRequest>}
     * @memberof LabeledImageRequest
     */
    attributes?: Array<AttributeValRequest>;
}

/**
 * Check if a given object implements the LabeledImageRequest interface.
 */
export function instanceOfLabeledImageRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "frame" in value;
    isInstance = isInstance && "labelId" in value;

    return isInstance;
}

export function LabeledImageRequestFromJSON(json: any): LabeledImageRequest {
    return LabeledImageRequestFromJSONTyped(json, false);
}

export function LabeledImageRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): LabeledImageRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'frame': json['frame'],
        'labelId': json['label_id'],
        'group': !exists(json, 'group') ? undefined : json['group'],
        'source': !exists(json, 'source') ? undefined : json['source'],
        'attributes': !exists(json, 'attributes') ? undefined : ((json['attributes'] as Array<any>).map(AttributeValRequestFromJSON)),
    };
}

export function LabeledImageRequestToJSON(value?: LabeledImageRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'frame': value.frame,
        'label_id': value.labelId,
        'group': value.group,
        'source': value.source,
        'attributes': value.attributes === undefined ? undefined : ((value.attributes as Array<any>).map(AttributeValRequestToJSON)),
    };
}

