/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ChunkType } from './ChunkType';
import {
    ChunkTypeFromJSON,
    ChunkTypeFromJSONTyped,
    ChunkTypeToJSON,
} from './ChunkType';
import type { SortingMethod } from './SortingMethod';
import {
    SortingMethodFromJSON,
    SortingMethodFromJSONTyped,
    SortingMethodToJSON,
} from './SortingMethod';
import type { StorageMethod } from './StorageMethod';
import {
    StorageMethodFromJSON,
    StorageMethodFromJSONTyped,
    StorageMethodToJSON,
} from './StorageMethod';
import type { StorageType } from './StorageType';
import {
    StorageTypeFromJSON,
    StorageTypeFromJSONTyped,
    StorageTypeToJSON,
} from './StorageType';

/**
 * Adds support for write once fields to serializers.
 * 
 * To use it, specify a list of fields as `write_once_fields` on the
 * serializer's Meta:
 * ```
 * class Meta:
 *     model = SomeModel
 *     fields = '__all__'
 *     write_once_fields = ('collection', )
 * ```
 * 
 * Now the fields in `write_once_fields` can be set during POST (create),
 * but cannot be changed afterwards via PUT or PATCH (update).
 * Inspired by http://stackoverflow.com/a/37487134/627411.
 * @export
 * @interface DataRequest
 */
export interface DataRequest {
    /**
     * 
     * @type {number}
     * @memberof DataRequest
     */
    chunkSize?: number;
    /**
     * 
     * @type {number}
     * @memberof DataRequest
     */
    size?: number;
    /**
     * 
     * @type {number}
     * @memberof DataRequest
     */
    imageQuality: number;
    /**
     * 
     * @type {number}
     * @memberof DataRequest
     */
    startFrame?: number;
    /**
     * 
     * @type {number}
     * @memberof DataRequest
     */
    stopFrame?: number;
    /**
     * 
     * @type {string}
     * @memberof DataRequest
     */
    frameFilter?: string;
    /**
     * 
     * @type {ChunkType}
     * @memberof DataRequest
     */
    compressedChunkType?: ChunkType;
    /**
     * 
     * @type {ChunkType}
     * @memberof DataRequest
     */
    originalChunkType?: ChunkType;
    /**
     * 
     * @type {Array<Blob>}
     * @memberof DataRequest
     */
    clientFiles?: Array<Blob>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DataRequest
     */
    serverFiles?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DataRequest
     */
    remoteFiles?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof DataRequest
     */
    useZipChunks?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DataRequest
     */
    cloudStorageId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DataRequest
     */
    useCache?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DataRequest
     */
    copyData?: boolean;
    /**
     * 
     * @type {StorageMethod}
     * @memberof DataRequest
     */
    storageMethod?: StorageMethod;
    /**
     * 
     * @type {StorageType}
     * @memberof DataRequest
     */
    storage?: StorageType;
    /**
     * 
     * @type {SortingMethod}
     * @memberof DataRequest
     */
    sortingMethod?: SortingMethod;
}

/**
 * Check if a given object implements the DataRequest interface.
 */
export function instanceOfDataRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "imageQuality" in value;

    return isInstance;
}

export function DataRequestFromJSON(json: any): DataRequest {
    return DataRequestFromJSONTyped(json, false);
}

export function DataRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'chunkSize': !exists(json, 'chunk_size') ? undefined : json['chunk_size'],
        'size': !exists(json, 'size') ? undefined : json['size'],
        'imageQuality': json['image_quality'],
        'startFrame': !exists(json, 'start_frame') ? undefined : json['start_frame'],
        'stopFrame': !exists(json, 'stop_frame') ? undefined : json['stop_frame'],
        'frameFilter': !exists(json, 'frame_filter') ? undefined : json['frame_filter'],
        'compressedChunkType': !exists(json, 'compressed_chunk_type') ? undefined : ChunkTypeFromJSON(json['compressed_chunk_type']),
        'originalChunkType': !exists(json, 'original_chunk_type') ? undefined : ChunkTypeFromJSON(json['original_chunk_type']),
        'clientFiles': !exists(json, 'client_files') ? undefined : json['client_files'],
        'serverFiles': !exists(json, 'server_files') ? undefined : json['server_files'],
        'remoteFiles': !exists(json, 'remote_files') ? undefined : json['remote_files'],
        'useZipChunks': !exists(json, 'use_zip_chunks') ? undefined : json['use_zip_chunks'],
        'cloudStorageId': !exists(json, 'cloud_storage_id') ? undefined : json['cloud_storage_id'],
        'useCache': !exists(json, 'use_cache') ? undefined : json['use_cache'],
        'copyData': !exists(json, 'copy_data') ? undefined : json['copy_data'],
        'storageMethod': !exists(json, 'storage_method') ? undefined : StorageMethodFromJSON(json['storage_method']),
        'storage': !exists(json, 'storage') ? undefined : StorageTypeFromJSON(json['storage']),
        'sortingMethod': !exists(json, 'sorting_method') ? undefined : SortingMethodFromJSON(json['sorting_method']),
    };
}

export function DataRequestToJSON(value?: DataRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'chunk_size': value.chunkSize,
        'size': value.size,
        'image_quality': value.imageQuality,
        'start_frame': value.startFrame,
        'stop_frame': value.stopFrame,
        'frame_filter': value.frameFilter,
        'compressed_chunk_type': ChunkTypeToJSON(value.compressedChunkType),
        'original_chunk_type': ChunkTypeToJSON(value.originalChunkType),
        'client_files': value.clientFiles,
        'server_files': value.serverFiles,
        'remote_files': value.remoteFiles,
        'use_zip_chunks': value.useZipChunks,
        'cloud_storage_id': value.cloudStorageId,
        'use_cache': value.useCache,
        'copy_data': value.copyData,
        'storage_method': StorageMethodToJSON(value.storageMethod),
        'storage': StorageTypeToJSON(value.storage),
        'sorting_method': SortingMethodToJSON(value.sortingMethod),
    };
}

