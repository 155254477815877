import { Button, MenuItem, Modal, TextField, Tooltip, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import React, { useEffect, useState } from 'react'
import { Account, AccountApi, Organization, OrganizationApi } from '../api';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { useAuth0 } from '@auth0/auth0-react';
import ApiConfig from './ApiConfig';
import ModalStyle from './ModalStyle';
import LoadingButton from '@mui/lab/LoadingButton';

interface errorMessage {
  value: string;
  message: string;
  status: number;
}

function CreateAccount(props: any) {
  const { getAccessTokenSilently, user } = useAuth0();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => { setOpen(true); refresh(); };
  const handleClose = () => { setOpen(false); refresh() };
  const [userRole, setUserRole] = useState<string>('user');
  const [orgs, setOrgs] = useState<Organization[]>([]);
  const [role, setRole] = React.useState('');
  const [account, setAccount] = useState<Account>({});
  const [organization, setOrganization] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<errorMessage>({ message: '', status: 200, value: '' })
  const [loading, setLoading] = useState(false);



  const roles = [
    {
      value: 'administrator',
      label: 'Administrator'
    },
    {
      value: 'superuser',
      label: 'Superuser'
    },
    {
      value: 'traininguser',
      label: 'Traininguser'
    },
    {
      value: 'user',
      label: 'User'
    },
  ];

  const refresh = () => {
    setAccount({ ...{}, role: 'user', organization: orgs.length ? orgs[0] : undefined })
    setRole('user')
    setErrorMessage({ message: '', status: 200, value: '' })
    setLoading(false)
  }
  useEffect(() => {
    if (user && user["https://aimaps.k8s.oniq.solutions/roles"].length) {
      setUserRole(user["https://aimaps.k8s.oniq.solutions/roles"][0]);
    }
  }, [user])

  useEffect(() => {
    const getAllOrganizations = async () => {
      try {
        const token = await getAccessTokenSilently();
        const header = { headers: { Authorization: `Bearer ${token}`, accept: 'application/json' } };
        new OrganizationApi(ApiConfig())
          .getAllOrganizations(header)
          .then((res) => {
            const data = res;
            setOrgs(
              data.map((organization: any) => {
                return organization;
              })
            );
            if (data.length === 1) {
              setOrganization((data[0] && data[0].organizationId) ? data[0].organizationId : '');
              setAccount({
                ...account,
                organization: data[0]
              });
            }
          });
      } catch (error) {

      }
    }
    getAllOrganizations();
  }, [getAccessTokenSilently]);


  const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRole(event.target.value);
    setAccount({
      ...account,
      role: event.target.value
    });
  };

  const handleOrganizationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const organization = orgs.find(o => o.organizationId === event.target.value)
    if (organization) {
      setOrganization(event.target.value);
      setAccount({
        ...account,
        organization: organization
      });
    }
  };


  const handleAccountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    setAccount({
      ...account,
      [event.target.name]: newValue
    });
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.type === 'checkbox' ? event.target.checked : event.target.value.replace(/\s/g, '');
    //console.log(newValue)
    setAccount({
      ...account,
      [event.target.name]: newValue
    });
  }

  const createAccount = async () => {
    //console.log(account)
    const createNewAccount = async () => {
		if (account.password == undefined) {
			setErrorMessage({
				value: 'password',
				message: "No password",
				status: 400,
			  })
			  setLoading(false)
			  return false
		}
      try {
        const token = await getAccessTokenSilently();
        const header = {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        };
        const data = (await new AccountApi(ApiConfig()).createAccount({ account: account }, header));
        props.setAccounts([...props.accounts, data]);
        return true
      } catch (e: any) {
        let response = await e.response.json()
        if (response.message.includes("email")) {
          setErrorMessage({
            value: 'email',
            message: response.message,
            status: response.status,
          })
        } else if (response.message.includes("PasswordStrengthError")) {
			setErrorMessage({
				value: 'password',
				message: response.message,
				status: response.status,
			  })
		} else {
          setErrorMessage({
            value: 'name',
            message: response.message,
            status: response.status,
          })
        }
        setLoading(false)
        return false
      }
    }
    setLoading(true);
    const success = await createNewAccount();
    if (success) {
      handleClose();
    }


  }

  return (
    <div>
      <Button className='createAccount-button' onClick={handleOpen} variant="contained">
        Create<AddCircleOutlineOutlinedIcon />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="Signup"
      >
        <Box sx={ModalStyle}>
          <Stack spacing={2}>
            <Typography id="modal-createaccount" variant="h6" component="h2">
              New Account
            </Typography>
            <TextField id="name-field" name='name' label="Name" onChange={handleAccountChange} error={errorMessage.value === "name"} helperText={errorMessage.value !== "name" ? null : errorMessage.message} variant="outlined" />
            <TextField id="email-field" name='email' label="Email" onChange={handleEmailChange} error={errorMessage.value === "email"} helperText={errorMessage.value !== "email" ? null : errorMessage.message} variant="outlined" />

            <TextField id="role-field" disabled={!(userRole === 'administrator')} name='role' select value={role} onChange={handleRoleChange} label="Role" variant="outlined">
              {roles.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            {<TextField id="org-field" name='organization' disabled={!(userRole === 'administrator')} select value={organization} onChange={handleOrganizationChange} label="Organization" variant="outlined" >
              {orgs.map((org) => (
                <MenuItem key={org.organizationId} value={org.organizationId}>
                  {org.name}
                </MenuItem>
              ))}
            </TextField>}
            <Tooltip title="Select a temporary password for user, use atleast one upper case letter and one number, if left blank user will need to reset password.">
              <TextField id="password-field" name='password' label="Password" type="password" onChange={handleAccountChange} error={errorMessage.value === "password"} helperText={errorMessage.value !== "password" ? null : errorMessage.message} variant="outlined" />
            </Tooltip>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', }}>
              <Button variant="contained" onClick={handleClose}>Cancel</Button>
              <LoadingButton loading={loading} id="create-button" onClick={createAccount} variant="contained">Submit</LoadingButton>
            </Box>
          </Stack>
        </Box>
      </Modal>
    </div>
  )
}

export default CreateAccount