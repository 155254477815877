/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BasicUser } from './BasicUser';
import {
    BasicUserFromJSON,
    BasicUserFromJSONTyped,
    BasicUserToJSON,
} from './BasicUser';
import type { ChunkType } from './ChunkType';
import {
    ChunkTypeFromJSON,
    ChunkTypeFromJSONTyped,
    ChunkTypeToJSON,
} from './ChunkType';
import type { JobStatus } from './JobStatus';
import {
    JobStatusFromJSON,
    JobStatusFromJSONTyped,
    JobStatusToJSON,
} from './JobStatus';
import type { Label } from './Label';
import {
    LabelFromJSON,
    LabelFromJSONTyped,
    LabelToJSON,
} from './Label';
import type { Segment } from './Segment';
import {
    SegmentFromJSON,
    SegmentFromJSONTyped,
    SegmentToJSON,
} from './Segment';
import type { Storage } from './Storage';
import {
    StorageFromJSON,
    StorageFromJSONTyped,
    StorageToJSON,
} from './Storage';

/**
 * 
 * @export
 * @interface TaskRead
 */
export interface TaskRead {
    /**
     * 
     * @type {string}
     * @memberof TaskRead
     */
    readonly url?: string;
    /**
     * 
     * @type {number}
     * @memberof TaskRead
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof TaskRead
     */
    readonly name?: string;
    /**
     * 
     * @type {number}
     * @memberof TaskRead
     */
    projectId?: number;
    /**
     * 
     * @type {string}
     * @memberof TaskRead
     */
    readonly mode?: string;
    /**
     * 
     * @type {BasicUser}
     * @memberof TaskRead
     */
    owner?: BasicUser;
    /**
     * 
     * @type {BasicUser}
     * @memberof TaskRead
     */
    assignee?: BasicUser;
    /**
     * 
     * @type {string}
     * @memberof TaskRead
     */
    readonly bugTracker?: string;
    /**
     * 
     * @type {Date}
     * @memberof TaskRead
     */
    readonly createdDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TaskRead
     */
    readonly updatedDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof TaskRead
     */
    readonly overlap?: number;
    /**
     * 
     * @type {number}
     * @memberof TaskRead
     */
    readonly segmentSize?: number;
    /**
     * 
     * @type {JobStatus}
     * @memberof TaskRead
     */
    status?: JobStatus;
    /**
     * 
     * @type {Array<Label>}
     * @memberof TaskRead
     */
    labels?: Array<Label>;
    /**
     * 
     * @type {Array<Segment>}
     * @memberof TaskRead
     */
    readonly segments?: Array<Segment>;
    /**
     * 
     * @type {number}
     * @memberof TaskRead
     */
    readonly dataChunkSize?: number;
    /**
     * 
     * @type {ChunkType}
     * @memberof TaskRead
     */
    dataCompressedChunkType?: ChunkType;
    /**
     * 
     * @type {ChunkType}
     * @memberof TaskRead
     */
    dataOriginalChunkType?: ChunkType;
    /**
     * 
     * @type {number}
     * @memberof TaskRead
     */
    readonly size?: number;
    /**
     * 
     * @type {number}
     * @memberof TaskRead
     */
    readonly imageQuality?: number;
    /**
     * 
     * @type {number}
     * @memberof TaskRead
     */
    readonly data?: number;
    /**
     * 
     * @type {string}
     * @memberof TaskRead
     */
    dimension?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskRead
     */
    readonly subset?: string;
    /**
     * 
     * @type {number}
     * @memberof TaskRead
     */
    readonly organization?: number;
    /**
     * 
     * @type {Storage}
     * @memberof TaskRead
     */
    targetStorage?: Storage;
    /**
     * 
     * @type {Storage}
     * @memberof TaskRead
     */
    sourceStorage?: Storage;
}

/**
 * Check if a given object implements the TaskRead interface.
 */
export function instanceOfTaskRead(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TaskReadFromJSON(json: any): TaskRead {
    return TaskReadFromJSONTyped(json, false);
}

export function TaskReadFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskRead {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'url': !exists(json, 'url') ? undefined : json['url'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'projectId': !exists(json, 'project_id') ? undefined : json['project_id'],
        'mode': !exists(json, 'mode') ? undefined : json['mode'],
        'owner': !exists(json, 'owner') ? undefined : BasicUserFromJSON(json['owner']),
        'assignee': !exists(json, 'assignee') ? undefined : BasicUserFromJSON(json['assignee']),
        'bugTracker': !exists(json, 'bug_tracker') ? undefined : json['bug_tracker'],
        'createdDate': !exists(json, 'created_date') ? undefined : (new Date(json['created_date'])),
        'updatedDate': !exists(json, 'updated_date') ? undefined : (new Date(json['updated_date'])),
        'overlap': !exists(json, 'overlap') ? undefined : json['overlap'],
        'segmentSize': !exists(json, 'segment_size') ? undefined : json['segment_size'],
        'status': !exists(json, 'status') ? undefined : JobStatusFromJSON(json['status']),
        'labels': !exists(json, 'labels') ? undefined : ((json['labels'] as Array<any>).map(LabelFromJSON)),
        'segments': !exists(json, 'segments') ? undefined : ((json['segments'] as Array<any>).map(SegmentFromJSON)),
        'dataChunkSize': !exists(json, 'data_chunk_size') ? undefined : json['data_chunk_size'],
        'dataCompressedChunkType': !exists(json, 'data_compressed_chunk_type') ? undefined : ChunkTypeFromJSON(json['data_compressed_chunk_type']),
        'dataOriginalChunkType': !exists(json, 'data_original_chunk_type') ? undefined : ChunkTypeFromJSON(json['data_original_chunk_type']),
        'size': !exists(json, 'size') ? undefined : json['size'],
        'imageQuality': !exists(json, 'image_quality') ? undefined : json['image_quality'],
        'data': !exists(json, 'data') ? undefined : json['data'],
        'dimension': !exists(json, 'dimension') ? undefined : json['dimension'],
        'subset': !exists(json, 'subset') ? undefined : json['subset'],
        'organization': !exists(json, 'organization') ? undefined : json['organization'],
        'targetStorage': !exists(json, 'target_storage') ? undefined : StorageFromJSON(json['target_storage']),
        'sourceStorage': !exists(json, 'source_storage') ? undefined : StorageFromJSON(json['source_storage']),
    };
}

export function TaskReadToJSON(value?: TaskRead | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'project_id': value.projectId,
        'owner': BasicUserToJSON(value.owner),
        'assignee': BasicUserToJSON(value.assignee),
        'status': JobStatusToJSON(value.status),
        'labels': value.labels === undefined ? undefined : ((value.labels as Array<any>).map(LabelToJSON)),
        'data_compressed_chunk_type': ChunkTypeToJSON(value.dataCompressedChunkType),
        'data_original_chunk_type': ChunkTypeToJSON(value.dataOriginalChunkType),
        'dimension': value.dimension,
        'target_storage': StorageToJSON(value.targetStorage),
        'source_storage': StorageToJSON(value.sourceStorage),
    };
}

