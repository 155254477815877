import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Auth0Provider
    domain={process.env.NODE_ENV === 'development'?"dev-b02c5vyi.eu.auth0.com":"dev-ybqbaeum.eu.auth0.com"}
    clientId={process.env.NODE_ENV === 'development'?"Y9hAUY2oLssu4YD7xyYX1KLyHH41SDcG":"df9bOiv8jNiJdjlsKkCbDyR7fBHJgtx3"}
    audience={process.env.NODE_ENV === 'development'?'https://dev-b02c5vyi.eu.auth0.com/api/v2/':'https://dev-ybqbaeum.eu.auth0.com/api/v2/'}
    redirectUri={window.location.origin}
  	cacheLocation="localstorage"
	useRefreshTokens={true}
  >
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </Auth0Provider>
);


serviceWorkerRegistration.unregister();


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
