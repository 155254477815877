import { Button, Modal, TextField, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import React, { useState } from 'react'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { ErrorResponse, Organization, OrganizationApi } from '../api';
import { useAuth0 } from '@auth0/auth0-react';
import ApiConfig from './ApiConfig';
import ModalStyle from './ModalStyle';
import ErrorComp from './ErrorComp';
import { LoadingButton } from '@mui/lab';
import { validateOrganizationCharacters, validateOrganizationLength } from '../utils/validation-patterns';


function CreateOrganization(props: any) {
	const { getAccessTokenSilently } = useAuth0();
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => { setOpen(true); refresh() };
	const handleClose = () => { setOpen(false); refresh() };
	const [organization, setOrganization] = useState<Organization>({})
	const [displayError, setDisplayError] = useState({ display: 'none' })
	const [errorMessage, setErrorMessage] = useState('')
	const [loading, setLoading] = useState(false);

	const refresh = () => {
		setOrganization({});
		setDisplayError({ display: 'none' });
		setLoading(false);
	}

	const handleOrganizationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		setOrganization({
			...organization,
			[event.target.name]: newValue
		});
	}

	const createOrganization = async () => {
		const createNewOrganization = async () => {
			if (!validateOrganizationLength.test(organization.name as string)) {
				setErrorMessage("Organization Name must have at least 5 characters")
				setDisplayError({ display: 'block' })
			} else if (!validateOrganizationCharacters.test(organization.name as string)) {
				setErrorMessage("Only characters (a-z), (A-Z), (0-9), -, _ are available")
				setDisplayError({ display: 'block' })
			} else {
				try {
					setLoading(true)
					const token = await getAccessTokenSilently();
					const header = {
						headers: {
							Authorization: `Bearer ${token}`,
							Accept: 'application/json', 'Content-Type': 'application/json'
						}
					};
					const data = (await new OrganizationApi(ApiConfig()).addOrganization({ organization: organization }, header));
					props.setOrganizations([...props.organizations, data])
					return true
				} catch (error: any) {
					const message = await error.response.json()
					if (message.message.includes("The password is too similar to the username")) {
						setErrorMessage("This organization Name is reserved")
					} else {
						setErrorMessage(message.message)
						console.log(message)
					}
					setDisplayError({ display: 'block' })
					setLoading(false)
					return false
				}
			}

		}
		const success = await createNewOrganization();
		if (success) {
			handleClose();
		}

	}

	return (
		<div>
			<Button className='createOrganization-button' onClick={handleOpen} variant="contained">
				Create<AddCircleOutlineOutlinedIcon />
			</Button>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="createOrg"
			>
				<Box sx={ModalStyle}>
					<Stack spacing={2}>
						<Typography id="modal-createOrg" variant="h6" component="h2">
							New Organization
						</Typography>
						<TextField id="orgname-field" name='name' onChange={handleOrganizationChange} label="Organization Name" variant="outlined" />
						<TextField id="orgdesc-field" name='description' onChange={handleOrganizationChange} label="Description" variant="outlined" />
						<TextField id="orgcontact-field" type={'email'} name='contact' onChange={handleOrganizationChange} label="Contact (email)" variant="outlined" />

						<Box sx={displayError}>
							<ErrorComp error={errorMessage} />
						</Box>
						<Box sx={{ display: 'flex', justifyContent: 'space-between', }}>
							<Button variant="contained" onClick={handleClose}>Cancel</Button>
							<LoadingButton loading={loading} id="create-button" onClick={createOrganization} variant="contained">Submit</LoadingButton>
						</Box>
					</Stack>
				</Box>
			</Modal>
		</div>
	)
}
export default CreateOrganization