import { SearchOutlined } from "@mui/icons-material";
import { Box, Grid, InputAdornment, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import ModuleCard from "./ModuleCard";
import { License, Module } from "../api/models";
import { useEffect, useState } from "react";
import { LicenseApi, ModuleApi } from "../api";
import ApiConfig from "./ApiConfig";
import { useAuth0 } from "@auth0/auth0-react";
export interface ModuleProps {
	module: Module,
	license?: License,
	licenseRequest?: License,
	expanded: boolean,
	visible: boolean,
	updateProps: Function,
	requestLicense: Function,
}
function ModuleSearch() {
	const { getAccessTokenSilently, user, isLoading, isAuthenticated } = useAuth0()

	const [moduleProps, setModuleProps] = useState<ModuleProps[]>([])
	const [selectedModule, setSelectedModule] = useState<string>('')

	const [searchQuery, setSearchQuery] = useState("");
	const [alignment, setAlignment] = useState('all');
	const [loading, setLoading] = useState(false)


	const handleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
		//console.log(newAlignment)
		setAlignment(newAlignment);
	};
	useEffect(() => {
		const newProps = moduleProps.map((mod) => {
			return {
				...mod,
				...{ visible: 'all' === alignment || (mod.license && 'installed' === alignment) || (!mod.license && 'availible' === alignment) }
			} as ModuleProps;
		});
		setModuleProps(newProps);
	}, [alignment, searchQuery])
	useEffect(() => {
		setModuleProps([...moduleProps.map(m => { return { ...m, ...{ expanded: m.module.id === selectedModule } } }).sort((a, b) => a.expanded ? -1 : 1)]);
	}, [selectedModule])
	const requestLicense = async (license: License) => {
		const token = await getAccessTokenSilently();
		const header = {
			headers: {
				Authorization: `Bearer ${token}`,
				Accept: 'application/json', 'Content-Type': 'application/json'
			}
		};


		await new LicenseApi(ApiConfig()).createLicenses({ license: license }, header)
		getAllModules();
	}
	const getAllModules = async () => {
		const token = await getAccessTokenSilently();
		const header = { headers: { Authorization: `Bearer ${token}` } };

		try {
			setLoading(true)
			const modules = await new ModuleApi(ApiConfig()).getAllModules(header);
			const licenses = await new LicenseApi(ApiConfig()).getLicenses(header);
			setModuleProps(modules.map(m => {
				return {
					module: m,
					license: licenses.find(license => m.id === license.module?.id && license.approved),
					licenseRequest: licenses.find(license => m.id === license.module?.id && !license.approved),
					expanded: false,
					visible: true,
					requestLicense: requestLicense,
					updateProps: (props: ModuleProps) => {
						console.log(props)
						if (props.module.id) {
							if (props.expanded) {
								setSelectedModule('');
							} else {
								setSelectedModule(props.module.id);
							}
						}
					}
				}
			}));

		} catch (error) {
			console.error(error);
			setLoading(false)
		}
		setLoading(false)
	}

	useEffect(() => {
		if (!isLoading) {
			getAllModules()
		}
	}, [isLoading])

	return isLoading ? <></> :
		<Grid container spacing={4}>
			<Grid item xs={12} sm={12}
				display="flex"
				alignItems="center"
				justifyContent="left"
			>
				<Box justifyContent={'center'} display={'inline'}>
					<TextField
						className="search-bar"
						id="search"
						type="search"
						label="Search"
						value={searchQuery}
						onChange={(e) => {
							setSearchQuery(e.target.value)
						}
						}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<SearchOutlined />
								</InputAdornment>
							),
						}} />
				</Box>
				<Box display={'inline'} justifyContent={'flex-end'}>
					<ToggleButtonGroup
						orientation='horizontal'
						//color="primary"
						value={alignment}
						exclusive
						onChange={handleChange}
						aria-label="Platform"
					>
						<ToggleButton value="all">All</ToggleButton>
						<ToggleButton value="installed">Installed</ToggleButton>
						<ToggleButton value="availible">Available</ToggleButton>
					</ToggleButtonGroup>
				</Box>
			</Grid>

			<Grid container>
				{moduleProps.filter(moduleProps => moduleProps.visible).map((module) => {
					return (
						<Grid item md={module.expanded ? 12 : 4} m={1} xs={12} sm={module.expanded ? 12 : 6} key={module.module.id}>
							<ModuleCard moduleProps={module} />
						</Grid>
					);
				})}
			</Grid>
		</Grid>;
}
export default ModuleSearch;
