/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AttributeRequest } from './AttributeRequest';
import {
    AttributeRequestFromJSON,
    AttributeRequestFromJSONTyped,
    AttributeRequestToJSON,
} from './AttributeRequest';

/**
 * 
 * @export
 * @interface SublabelRequest
 */
export interface SublabelRequest {
    /**
     * 
     * @type {number}
     * @memberof SublabelRequest
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SublabelRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SublabelRequest
     */
    color?: string;
    /**
     * 
     * @type {Array<AttributeRequest>}
     * @memberof SublabelRequest
     */
    attributes?: Array<AttributeRequest>;
    /**
     * 
     * @type {string}
     * @memberof SublabelRequest
     */
    type?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SublabelRequest
     */
    hasParent?: boolean;
}

/**
 * Check if a given object implements the SublabelRequest interface.
 */
export function instanceOfSublabelRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function SublabelRequestFromJSON(json: any): SublabelRequest {
    return SublabelRequestFromJSONTyped(json, false);
}

export function SublabelRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SublabelRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'attributes': !exists(json, 'attributes') ? undefined : ((json['attributes'] as Array<any>).map(AttributeRequestFromJSON)),
        'type': !exists(json, 'type') ? undefined : json['type'],
        'hasParent': !exists(json, 'has_parent') ? undefined : json['has_parent'],
    };
}

export function SublabelRequestToJSON(value?: SublabelRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'color': value.color,
        'attributes': value.attributes === undefined ? undefined : ((value.attributes as Array<any>).map(AttributeRequestToJSON)),
        'type': value.type,
        'has_parent': value.hasParent,
    };
}

