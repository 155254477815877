import './styles/Index.scss'
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Navbar from './Components/Navbar';
import Profile from './Pages/Profile';
import Accounts from './Pages/Accounts';
import Organizations from './Pages/Organizations';
import Projects from './Pages/Projects';
import DataPoints from './Pages/DataPoints';
import WebCamUpload from './Pages/WebCamUpload';
import UploadImage from './Pages/UploadImage';
import Modules from './Pages/Modules';
import Training from './Pages/Training';
import TrainingProject from './Pages/TrainingProject';
import Home from './Pages/Home';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from './Components/Loading';
import Licenses from './Pages/Licenses';
import { red } from '@mui/material/colors';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
	components: {
		MuiIconButton: {
			styleOverrides: {
				root: {
					//backgroundColor: 'rgb(143, 143, 159)',
					//color: 'white',
				}
			}
	  }
	}
}
	
  );
function App() {
	const { isLoading, user } = useAuth0();

	if (isLoading) {
		return (Loading())
	}

	return (
		<ThemeProvider theme={theme}>
		<div className="App">
			<Navbar />
			<Routes>
				<Route>
					<Route path="/" element={<Home />}></Route>
					<Route path="profile" element={<Profile />}></Route>
					<Route path="accounts" element={<Accounts />}></Route>
					<Route path="collections" element={<Projects />}></Route>
					<Route path="projects" element={<Projects />}></Route>
					<Route path="modules" element={<Modules />}></Route>
					<Route path="projects/data/:projectId" element={<DataPoints />}></Route>
					<Route path="projects/uploadImage/:id" element={<UploadImage />}></Route>
					<Route path="projects/uploadImage/webcam/:id" element={<WebCamUpload />}></Route>
                    <Route path="training" element={<Training />}></Route>
                    <Route path="training/project/:id" element={<TrainingProject />}></Route>

					{user && user["https://aimaps.k8s.oniq.solutions/roles"][0] as string === "administrator" ?
						<>
							<Route path="organizations" element={<Organizations />}></Route>
							<Route path="licenses" element={<Licenses />}></Route>
						</>
						: <></>}
				</Route>
			</Routes>
		</div>
		</ThemeProvider>
	);
}

export default App;
