import { useAuth0 } from "@auth0/auth0-react";
import { Project, ProjectApi } from "../api";
import { Module } from "../api";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';

import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, CardContent, Checkbox, CircularProgress, Collapse, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, IconButton, IconButtonProps, InputLabel, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, TextField, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import ApiConfig from "./ApiConfig";

function Rerun(props: { project: Project, modules: Module[], addProject: (project: Project, add: boolean) => void }) {
    const { getAccessTokenSilently } = useAuth0();
    const [expanded, setExpanded] = useState(false);

    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isDone, setIsDone] = useState(false);

    const [state, setState] = useState({
        newProject: false,
        name: '',
        module: '',
    });
    const { project, modules } = props;
    const handleChangeSelect = (event: SelectChangeEvent) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });
    };
    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target)
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });
    };
    const rerun = async () => {
        setIsLoading(true);
        setExpanded(false);
        try {
            const token = await getAccessTokenSilently();
            const header = { headers: { Authorization: `Bearer ${token}` } }
            if (state.module.length === 0) throw new Error("Please select a module");
            if (state.newProject && state.name.length === 0) throw new Error("Please enter a project name");
            if (project.projectId && state.module.length > 0) {
                setIsLoading(true)
                const newProj = await new ProjectApi(ApiConfig()).reRunProjectById({ projectId: project.projectId, create: state.newProject, name: state.name, moduleId: state.module }, header);
                props.addProject(newProj, state.newProject);
                setIsLoading(false);
                setIsDone(true);

            }
        } catch (error) {
            setError("" + error);
            setExpanded(true);
            setIsLoading(false);
        }

    }
    const showFeedBack = () => {
        return < >
            {error.length > 0 ? <Alert severity="error">{error} </Alert> : ''}

            {isLoading ? <Alert severity="info" action={<CircularProgress color="secondary" />}>Starting rerun!</Alert> : ''}
            {isDone ? <Alert severity="success" action={
                <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                        setIsDone(false);
                        setIsLoading(false);
                        setExpanded(false);
                    }}
                ><CloseIcon fontSize="inherit" /></IconButton>}>Rerun started!</Alert> : ''
            }
        </>
    }
    const handleExpand = () => {
        if (!isLoading && !isDone && error.length === 0)
            setExpanded((expanded) => !expanded);
    }


    return (
        <> <Accordion expanded={expanded}>
            <AccordionSummary
                /*                 expandIcon={<ExpandMoreIcon />}
                 */
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>{isLoading || isDone || error.length > 0 ? showFeedBack() : <Alert onClick={handleExpand} severity="warning" action={<ExpandMoreIcon></ExpandMoreIcon>}>Rerun images</Alert>}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>Create a new project or overwrite data predictions for the existing project with a different module.</Typography>
                {<>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <FormControl sx={{ m: 1, minWidth: 120 }} component="fieldset" variant="standard" >
                            <FormGroup>
                                <FormControlLabel sx={{ m: 1, minWidth: 120 }}
                                    control={
                                        <Checkbox checked={state.newProject} onChange={handleChange} name="newProject" />
                                    }
                                    label="Create new Project"
                                />
                                {state.newProject ? <FormControlLabel aria-label="New project name" sx={{ m: 1, minWidth: 120 }} control={<TextField id="projectname-field" name='name' label="New project name" variant="outlined" disabled={!state.newProject} onChange={handleNameChange} />} label="" /> : ''
                                }

                                <FormControl sx={{ m: 1, minWidth: 120 }}>

                                    <Select
                                        value={state.module}
                                        onChange={handleChangeSelect}
                                        name="module"
                                        label="Module"
                                    displayEmpty
                                    //inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem value="">
                                            <em>Select module</em>
                                        </MenuItem>
                                        {modules.filter(m => m.modelVersion && m.inputSize).map((module: Module) => { return <MenuItem key={module.id} value={module.id}>{module.name}</MenuItem> })}

                                    </Select>
                                </FormControl>
                                <FormControl sx={{ m: 1, minWidth: 120 }} component="fieldset" variant="standard" >
                                </FormControl>

                                <FormControlLabel sx={{ m: 1, minWidth: 120 }} control={<Button color="success" variant="contained" disabled={state.module.length === 0 || state.newProject && state.name.length == 0}>Rerun</Button>} label="" onClick={rerun}></FormControlLabel>
                            </FormGroup>
                        </FormControl>

                    </Box>
                </>}
            </AccordionDetails>
        </Accordion>
        </>
    )
}
export default Rerun;
