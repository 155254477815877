import { useAuth0 } from '@auth0/auth0-react';
import { Button, IconButton, Modal, Tooltip, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import React, { useState } from 'react'
import ApiConfig from './ApiConfig';
import DeleteIcon from '@mui/icons-material/Delete';
import { Organization, OrganizationApi, Project, ProjectApi } from '../api';
import ModalStyle from './ModalStyle';
import ErrorComp from './ErrorComp';

function DeleteOrganization(props: any) {
	const { getAccessTokenSilently } = useAuth0();
	const handleOpen = () => { setOpen(true); setDisplayError({ display: 'none' }) };
	const handleClose = () => setOpen(false);
	const [open, setOpen] = useState(false);
	const [displayError, setDisplayError] = useState({ display: 'none' })
	const [errorMessage, setErrorMessage] = useState('')
	const [projects, setProjects] = useState<Project[]>([]);

	const header = async () => {
		return { headers: { Authorization: `Bearer ${await getAccessTokenSilently()}` } };
	}

	const getAllProjectsForOrganization = async (organizationId: string) => {
		const res = await new ProjectApi(ApiConfig()).getAllProjectsForOrganization(header)
		const orgProjects = res.filter(obj => obj.organization?.organizationId === organizationId)
		setProjects(orgProjects)
	}

	const deleteOrganizationById = async (organization: Organization) => {
		try {
			if (organization.organizationId) {
				await new OrganizationApi(ApiConfig())
					.deleteOrganization({ organizationId: organization.organizationId }, header).then(() => {
						props.getAllOrganizations();
						handleClose();
					})
			}
		} catch (error: any) {
			const err = await error.response.json()
			setDisplayError({ display: 'block' })
			setErrorMessage(err.message)
			console.error(error)
		}
	}

	return (
		<>
			<Tooltip title="Delete organization">
				<IconButton onClick={() => { handleOpen(); getAllProjectsForOrganization(props.organization.organizationId); }}>
					<DeleteIcon color={'error'} />
				</IconButton>
			</Tooltip>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="deleteAccount"
			>
				<Box sx={ModalStyle}>
					<Stack spacing={2}>
						<Typography id="modal-deleteAccount" variant="h6" component="h2">
							Are you sure you want to remove '{props.organization.name}'
						</Typography>
						{projects.length > 0 ? <Typography>These Collections will also be deleted: {projects.map(project => <div><b>{project.name}</b></div>)}</Typography>
							: <></>}
						<Box sx={{ display: 'flex', justifyContent: 'space-between', }}>
							<Button variant="contained" onClick={handleClose}>Cancel</Button>
							<Button color="error" id="delete-button" onClick={() => deleteOrganizationById(props.organization)} variant="contained">Remove</Button>
						</Box>
						<Box sx={displayError}>
							<ErrorComp error={errorMessage} />
						</Box>
					</Stack>
				</Box>
			</Modal>
		</>
	)

} export default DeleteOrganization