/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Organization } from './Organization';
import {
    OrganizationFromJSON,
    OrganizationFromJSONTyped,
    OrganizationToJSON,
} from './Organization';

/**
 * 
 * @export
 * @interface Module
 */
export interface Module {
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    modelName?: string;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    image?: string;
    /**
     * Path to module on S3 object storage
     * @type {string}
     * @memberof Module
     */
    path?: string;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    modelType?: string;
    /**
     * 
     * @type {number}
     * @memberof Module
     */
    modelVersion?: number;
    /**
     * 
     * @type {number}
     * @memberof Module
     */
    inputSize?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Module
     */
    labelMap?: Array<string>;
    /**
     * 
     * @type {Array<Organization>}
     * @memberof Module
     */
    organizations?: Array<Organization>;
}

/**
 * Check if a given object implements the Module interface.
 */
export function instanceOfModule(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ModuleFromJSON(json: any): Module {
    return ModuleFromJSONTyped(json, false);
}

export function ModuleFromJSONTyped(json: any, ignoreDiscriminator: boolean): Module {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'modelName': !exists(json, 'modelName') ? undefined : json['modelName'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'path': !exists(json, 'path') ? undefined : json['path'],
        'modelType': !exists(json, 'modelType') ? undefined : json['modelType'],
        'modelVersion': !exists(json, 'modelVersion') ? undefined : json['modelVersion'],
        'inputSize': !exists(json, 'inputSize') ? undefined : json['inputSize'],
        'labelMap': !exists(json, 'labelMap') ? undefined : json['labelMap'],
        'organizations': !exists(json, 'organizations') ? undefined : ((json['organizations'] as Array<any>).map(OrganizationFromJSON)),
    };
}

export function ModuleToJSON(value?: Module | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'modelName': value.modelName,
        'image': value.image,
        'path': value.path,
        'modelType': value.modelType,
        'modelVersion': value.modelVersion,
        'inputSize': value.inputSize,
        'labelMap': value.labelMap,
        'organizations': value.organizations === undefined ? undefined : ((value.organizations as Array<any>).map(OrganizationToJSON)),
    };
}

