/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BasicUser } from './BasicUser';
import {
    BasicUserFromJSON,
    BasicUserFromJSONTyped,
    BasicUserToJSON,
} from './BasicUser';
import type { JobStage } from './JobStage';
import {
    JobStageFromJSON,
    JobStageFromJSONTyped,
    JobStageToJSON,
} from './JobStage';
import type { JobStatus } from './JobStatus';
import {
    JobStatusFromJSON,
    JobStatusFromJSONTyped,
    JobStatusToJSON,
} from './JobStatus';
import type { OperationStatus } from './OperationStatus';
import {
    OperationStatusFromJSON,
    OperationStatusFromJSONTyped,
    OperationStatusToJSON,
} from './OperationStatus';

/**
 * 
 * @export
 * @interface SimpleJob
 */
export interface SimpleJob {
    /**
     * 
     * @type {string}
     * @memberof SimpleJob
     */
    readonly url?: string;
    /**
     * 
     * @type {number}
     * @memberof SimpleJob
     */
    readonly id?: number;
    /**
     * 
     * @type {BasicUser}
     * @memberof SimpleJob
     */
    assignee: BasicUser;
    /**
     * 
     * @type {JobStatus}
     * @memberof SimpleJob
     */
    status?: JobStatus;
    /**
     * 
     * @type {JobStage}
     * @memberof SimpleJob
     */
    stage?: JobStage;
    /**
     * 
     * @type {OperationStatus}
     * @memberof SimpleJob
     */
    state?: OperationStatus;
}

/**
 * Check if a given object implements the SimpleJob interface.
 */
export function instanceOfSimpleJob(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "assignee" in value;

    return isInstance;
}

export function SimpleJobFromJSON(json: any): SimpleJob {
    return SimpleJobFromJSONTyped(json, false);
}

export function SimpleJobFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimpleJob {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'url': !exists(json, 'url') ? undefined : json['url'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'assignee': BasicUserFromJSON(json['assignee']),
        'status': !exists(json, 'status') ? undefined : JobStatusFromJSON(json['status']),
        'stage': !exists(json, 'stage') ? undefined : JobStageFromJSON(json['stage']),
        'state': !exists(json, 'state') ? undefined : OperationStatusFromJSON(json['state']),
    };
}

export function SimpleJobToJSON(value?: SimpleJob | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assignee': BasicUserToJSON(value.assignee),
        'status': JobStatusToJSON(value.status),
        'stage': JobStageToJSON(value.stage),
        'state': OperationStatusToJSON(value.state),
    };
}

