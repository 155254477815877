import { useAuth0 } from '@auth0/auth0-react';
import AimapsLogoPlain from "../assets/uggla_logo.png";
import { Button } from '@mui/material'
import { useEffect } from 'react'
import AdminFront from '../Components/AdminFront';
import ModuleSearch from '../Components/ModuleSearch';
function Home() {
	const { user, isAuthenticated, loginWithRedirect } = useAuth0()

	useEffect(() => {
		console.log(user && user["https://aimaps.k8s.oniq.solutions/roles"][0])
	}, [])

	return (
		<>
			{isAuthenticated ? (
				<>
					{user && user["https://aimaps.k8s.oniq.solutions/roles"][0] as string === "administrator" ?
						<AdminFront />
						:
						<ModuleSearch />}
				</>
			) : (
				<div style={{ position: "absolute", left: "50%", top: "40%", transform: "translate(-50%, -50%)" }}><div>
					<h1 className='welcome'>Welcome to <br></br> <img className="aimaps-welcome-logo" src={AimapsLogoPlain} alt="‘AI Map’ (logo)" /> !</h1>
					<Button className='loginbutton' variant="contained" onClick={() => { loginWithRedirect() }}>Log in</Button>
				</div></div>
			)}
		</>
	)
}

export default Home
