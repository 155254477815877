import { Popup, CircleMarker, Tooltip } from 'react-leaflet'
import { DataPoint } from '../api';
interface MapLayersProps {
	dataPoints: DataPoint[]
	color: string
	projectId: string
}
function MapLayers(props: MapLayersProps) {

	const items = props.dataPoints.filter(d => d.location?.latitude).map(dataPoint => (

		<CircleMarker key={Math.random()}/** TODO id for datapoint */ center={[dataPoint.location?.latitude ? dataPoint.location.latitude : 0, dataPoint.location?.longitude ? dataPoint.location.longitude : 0]}
			pathOptions={{ color: props.color }} radius={7}>
			<Tooltip>{dataPoint.inferredCategory}</Tooltip>
			<Popup>
				{dataPoint.storagePath?.includes("/") ?
					<>
						<p>Label : <b>{dataPoint.inferredCategory}</b>, probability: {dataPoint.inferenceScore}</p>
						<img width={168} src={`https://ai-maps.ams3.digitaloceanspaces.com/${dataPoint.storagePath.split("/")[0]}/thumbnails/${dataPoint.storagePath.split("/")[1]}`} alt="Image" />;
					</>
					:
					<>
						<p>Label : <b>{dataPoint.inferredCategory}</b>, probability: {dataPoint.inferenceScore}</p>
						<img width={168} src={`https://${props.projectId}.ams3.digitaloceanspaces.com/${dataPoint.storagePath}`} alt="Image" />;
					</>
				}
			</Popup>
		</CircleMarker>
	));
	return <div style={{ display: 'none' }}>{items}</div>;
};
export default MapLayers;
