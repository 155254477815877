import { Button, Modal, Typography, IconButton, ListItem, ListItemButton, ListItemText, List, ListSubheader, Tooltip } from '@mui/material';
import { Box, Stack } from '@mui/system';
import React, { useEffect, useState } from 'react'
import PageviewIcon from '@mui/icons-material/Pageview';
import { Module } from '../api';
import { ViewModuleOutlined, ViewModuleRounded, ViewModuleSharp, ViewModuleTwoTone } from "@mui/icons-material";
import ModalStyle from './ModalStyle';


function ViewModule(props: any) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [module, setModule] = useState<Module>({} as Module)
    const { index, style } = props;

    return (
        <>									
            <Tooltip title="View Labels">
                <IconButton onClick={handleOpen}>
                    <ViewModuleOutlined />
                </IconButton>
            </Tooltip>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="view"
            >
                <Box sx={ModalStyle}>
                    <Stack spacing={2}>
                        <Typography id="modal-signup" variant="h6" component="h2">
                            {props.module.name}
                        </Typography>
                        <List
                            sx={{
                                width: '100%',
                                maxWidth: 360,
                                bgcolor: 'background.paper',
                                position: 'relative',
                                overflow: 'auto',
                                maxHeight: 300,
                                '& ul': { padding: 0 },
                            }}
                            subheader={<li />}
                        >
                            <ListSubheader>Labels</ListSubheader>
                            {props.module.labelMap.map((label: string) => (
                                <li key={`section-${label}`}>
                                    <ul>
                                        <ListItem>
                                            <ListItemText primary={label} />
                                        </ListItem>
                                    </ul>
                                </li>
                            ))}
                        </List>
                    </Stack>
                </Box>
            </Modal>
        </>

    )

} export default ViewModule