import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Box } from '@mui/system';
import { License, LicenseApi, Module, ModuleApi, Organization, OrganizationApi, PaginatedPolymorphicProjectList, TrainingApi } from '../api';
import { useAuth0 } from '@auth0/auth0-react';
import ApiConfig from '../Components/ApiConfig';
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import Loading from '../Components/Loading';
import CreateCVATProject from '../Components/CreateCVATProject';



function Training() {
    const { isLoading, getAccessTokenSilently, user } = useAuth0();
    const [isLoadingData, setIsLoadingData] = useState(true)
    const [loading, setLoading] = useState(false);
    const [cvatProjects, setCvatProjects] = useState<PaginatedPolymorphicProjectList>({});
    const [modules, setModules] = useState<Module[]>([])
    const [licences, setLicenses] = useState<License[]>([])
    const [trainableModules, setTrainableModules] = useState<Module[]>([])
    const [organizations, setOrganizations] = useState<Organization[]>([])
    const [rowCountState, setRowCountState] = useState(0);
    const [page, setPage] = useState(0);
    const navigate = useNavigate();

    const header = async () => {
        return { headers: { Authorization: `Bearer ${await getAccessTokenSilently()}` } };
    }

    const getAllCVATProjects = async () => {
        try {
            setLoading(true);
            setCvatProjects(await new TrainingApi(ApiConfig()).projectsList({ page: 1 + page, pageSize: 20 }, header));
            setIsLoadingData(false);
            setLoading(false);

        } catch (error) {
            console.log(error);
            navigate("/")
            setLoading(false)
        }
    }

    const getAllModules = async () => {
        try {
            setModules(await new ModuleApi(ApiConfig()).getAllModules(header))
        } catch (error) {
            console.error(error)
        }
    }
    const getAllLicenses = async () => {
        try {
            setLicenses(await new LicenseApi(ApiConfig()).getLicenses(header))
        }
        catch (error) {
            console.error(error)
        }
    }
    const getAllOrganizations = async () => {
        try {
            setOrganizations(await new OrganizationApi(ApiConfig()).getAllOrganizations(header))
        } catch (e) {
            console.error(e)
        }
    }


    useEffect(() => {
        getAllModules()
        getAllLicenses()
        getAllOrganizations()
    }, [])

    useEffect(() => {
        // TODO fix 
        //setTrainableModules(modules);
        if(user){
            setTrainableModules(modules);
        }
        //if ((user && 'administrator' === user["https://aimaps.k8s.oniq.solutions/roles"] )|| true) {
        //    setTrainableModules(modules);
        //} else if (modules.length > 0 && licences.length > 0) {
        //    setTrainableModules(modules.filter(m => licences.filter(l => l.approved).map(l => l.module?.id).includes(m.id)));
       // }
    }, [modules, user, licences])

    useEffect(() => {
        setRowCountState((prevRowCountState: any) =>
            cvatProjects?.count !== undefined ? cvatProjects.count : prevRowCountState,
        );
    }, [cvatProjects?.count, setRowCountState])

    useEffect(() => {
        getAllCVATProjects();
    }, [getAccessTokenSilently, page]);


    if (isLoading || isLoadingData) {
        return (Loading())
    }

    const dataColumns: GridColDef[] = [
        { field: 'name', headerName: 'Name', width: 150 },
        { field: 'tasks', headerName: 'Tasks', width: 150 },
        { field: 'createdDate', headerName: 'Created Date', width: 150 },
        { field: 'organization', headerName: 'Organization', width: 150}
    ];

    const dataRows = cvatProjects?.results?.map((project) => {

        const owner = project.assignee&&'oniq'!==project.assignee?.username ? project.assignee?.username as string:''
        return {
            id: project.id as number,
            name: project.name as string,
            tasks: project.tasks?.length as number,
            createdDate: project.createdDate?.toDateString() as string,
            organization: owner.replace('_',' ')
        }
    });

    return (
        <>
            <h1>Training</h1>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', m: 2 }}>
                { (1===1)? <CreateCVATProject getAllCVATProjects={getAllCVATProjects} modules={trainableModules} orgs={organizations} cvatProjects={cvatProjects} />: <></>}
            </Box>
            <Box style={{ backgroundColor: 'white', width: '100%', height: 725 }}>
                <DataGrid
                    pageSize={20}
                    paginationMode='server'
                    loading={loading}
                    rowCount={rowCountState}
                    onPageChange={(newPage) => setPage(newPage)}
                    rows={dataRows as readonly any[]}
                    columns={dataColumns}
                    onRowClick={(newSelection) => {
                        navigate(`/training/project/${newSelection.id}`)
                    }}
                />
            </Box>
        </>
    );

} export default Training