import { useAuth0 } from '@auth0/auth0-react';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, IconButton, ImageList, ImageListItem, Box, CircularProgress, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { DataApi, DataPoint, DataPoints } from '../api'
import ApiConfig from './ApiConfig';
import DeleteIcon from '@mui/icons-material/Delete';
import DataPointDetails from './DatapointDetails';

function DataPointsPagination(props: any) {
	const { getAccessTokenSilently } = useAuth0();
	const [isLoading, setIsLoading] = useState(true);
	const [page, setPage] = useState<number>(0);
	const [dataPoints, setDataPoints] = useState<DataPoints>()
	const [cols, setCols] = useState<number>(2);

	const [screenSize, setScreenSize] = useState(getCurrentDimension());
	useEffect(() => {
		function handleResize() {
			setScreenSize(getCurrentDimension());
		}
		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)
	}, [screenSize])

	function getCurrentDimension(){
		return {
			  width: window.innerWidth,
			  height: window.innerHeight
		}
	}
	useEffect(() => {
		setCols(Math.floor(screenSize.width / 256))
	}, [screenSize]);
	const header = async () => {
		return { headers: { Authorization: `Bearer ${await getAccessTokenSilently()}` } };
	}

	const getAllProjects = async () => {
		
		try {
			setIsLoading(true);
			const request = {
				projectId: props.projectId as string,
				page: page
			}
			await new DataApi(ApiConfig()).getDataPointsForProject(request, header)
				.then((res) => setDataPoints(res))
				.then(() => setIsLoading(false))
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		getAllProjects()
	}, [page])

	const deleteDataPointById = async (dataPointId: string) => {
		try {
			await new DataApi(ApiConfig()).deleteDataPointById({ dataPointId: dataPointId }, header)
				.then(() => getAllProjects())
		} catch (error) {
			console.error(error)
		}
	}

	const handleChangePage = (event: any, newPage: number) => {
		setPage(newPage)
	}

	return (

		
		 <>
			  <TablePagination style={{paddingRight:'20px'}}
				rowsPerPageOptions={[dataPoints?.dataPoints?.length as number || 0]}
				component="div"
				count={dataPoints?.totalElements as number || 0}
				rowsPerPage={50}
				page={page}
				onPageChange={handleChangePage}
			/>
			{/* <TableContainer component={Paper}>
				<Table aria-label="account-table">
					<TableHead>
						<TableRow>
							<TableCell>Image</TableCell>
							<TableCell>Label</TableCell>
							<TableCell>Probability</TableCell>
							<TableCell>Location</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{dataPoints?.dataPoints?.map((data: DataPoint) =>
							<TableRow
								key={Math.random()}
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
								<TableCell>
									{data.storagePath?.includes("/") ?
										<DataPointDetails data={data}></DataPointDetails>

										
										:

										<a href={`https://${props.projectId}.ams3.digitaloceanspaces.com/${data.storagePath}`} target="_blank" rel="noopener noreferrer">
											<img className="photo" src={`https://${props.projectId}.ams3.digitaloceanspaces.com/thumbnails/${data.storagePath}`} alt="No thumbnail found" />
										</a>
									}
								</TableCell>
								<TableCell component="th" scope="row">{data.inferredCategory}
								</TableCell>
								<TableCell>{data.inferenceScore}
								</TableCell>
								<TableCell align="left">({data.location?.latitude} , {data.location?.longitude})
								</TableCell>
								<TableCell>
									<IconButton onClick={() => deleteDataPointById(data.dataPointId as string)}>
										<DeleteIcon color='error' />
									</IconButton>
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer> */}
			{isLoading?
			<Box sx={{ scale:2 }}>
				<Tooltip title="Loading data points">
				<CircularProgress size={150} />
				</Tooltip>
		 	 </Box>
			:dataPoints?.dataPoints?<ImageList cols={cols} >
				{dataPoints?.dataPoints?.map((data: DataPoint) =><DataPointDetails data={data as DataPoint} projectId={props.projectId} deleteData={deleteDataPointById}></DataPointDetails>)}
			</ImageList>:'No data points found'}
			
		</> 
	)
}

export default DataPointsPagination
