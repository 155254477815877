/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LocationEnum } from './LocationEnum';
import {
    LocationEnumFromJSON,
    LocationEnumFromJSONTyped,
    LocationEnumToJSON,
} from './LocationEnum';

/**
 * 
 * @export
 * @interface StorageRequest
 */
export interface StorageRequest {
    /**
     * 
     * @type {LocationEnum}
     * @memberof StorageRequest
     */
    location?: LocationEnum;
    /**
     * 
     * @type {number}
     * @memberof StorageRequest
     */
    cloudStorageId?: number;
}

/**
 * Check if a given object implements the StorageRequest interface.
 */
export function instanceOfStorageRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function StorageRequestFromJSON(json: any): StorageRequest {
    return StorageRequestFromJSONTyped(json, false);
}

export function StorageRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StorageRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'location': !exists(json, 'location') ? undefined : LocationEnumFromJSON(json['location']),
        'cloudStorageId': !exists(json, 'cloud_storage_id') ? undefined : json['cloud_storage_id'],
    };
}

export function StorageRequestToJSON(value?: StorageRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'location': LocationEnumToJSON(value.location),
        'cloud_storage_id': value.cloudStorageId,
    };
}

