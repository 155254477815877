/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Module } from './Module';
import {
    ModuleFromJSON,
    ModuleFromJSONTyped,
    ModuleToJSON,
} from './Module';

/**
 * Customer Organization
 * @export
 * @interface Organization
 */
export interface Organization {
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    organizationId?: string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    contact?: string;
    /**
     * 
     * @type {Array<Module>}
     * @memberof Organization
     */
    modules?: Array<Module>;
}

/**
 * Check if a given object implements the Organization interface.
 */
export function instanceOfOrganization(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OrganizationFromJSON(json: any): Organization {
    return OrganizationFromJSONTyped(json, false);
}

export function OrganizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Organization {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organizationId': !exists(json, 'organizationId') ? undefined : json['organizationId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'contact': !exists(json, 'contact') ? undefined : json['contact'],
        'modules': !exists(json, 'modules') ? undefined : ((json['modules'] as Array<any>).map(ModuleFromJSON)),
    };
}

export function OrganizationToJSON(value?: Organization | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organizationId': value.organizationId,
        'name': value.name,
        'description': value.description,
        'contact': value.contact,
        'modules': value.modules === undefined ? undefined : ((value.modules as Array<any>).map(ModuleToJSON)),
    };
}

