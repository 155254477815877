import { Box, Button, IconButton, Modal, Stack, Tooltip, Typography } from '@mui/material'
import { useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import ModalStyle from './ModalStyle';
import { useAuth0 } from '@auth0/auth0-react';
import { Module, ModuleApi } from '../api';
import ApiConfig from './ApiConfig';
import ErrorComp from './ErrorComp';

function DeleteModule(props: any) {
	const { getAccessTokenSilently } = useAuth0()
	const handleOpen = () => { setDisplayError({ display: 'none' }); setOpen(true); };
	const handleClose = () => setOpen(false);
	const [open, setOpen] = useState(false);
	const [displayError, setDisplayError] = useState({ display: 'none' })
	const [errorMessage, setErrorMessage] = useState('')

	const deleteModuleById = async (module: Module) => {
		try {
			const token = await getAccessTokenSilently();
			const header = { headers: { Authorization: `Bearer ${token}` } }
			await new ModuleApi(ApiConfig()).deleteModuleByModuleId({ moduleId: module.id as string }, header)
			await props.getAllModules()
			handleClose()
		} catch (error: any) {
			const err = await error.response.json()
			setDisplayError({ display: 'block' })
			setErrorMessage(err.message)
			console.error(error)
		}
	}

	return (
		<>
			{props.user && props.user["https://aimaps.k8s.oniq.solutions/roles"][0] === "administrator" ?
				<Tooltip title="Delete module"><IconButton onClick={() => { handleOpen(); console.log(props.module) }}>
					<DeleteIcon color='error' />
				</IconButton>
				</Tooltip>
				:
				<></>
			}
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="deleteAccount"
			>
				<Box sx={ModalStyle}>
					<Stack spacing={2}>
						<Typography id="modal-deleteAccount" variant="h6" component="h2">
							Are you sure you want to remove: {props.module.name}
						</Typography>
						<Box sx={{ display: 'flex', justifyContent: 'space-between', }}>
							<Button variant="contained" onClick={handleClose}>Cancel</Button>
							<Button color="error" id="delete-button" onClick={() => deleteModuleById(props.module)} variant="contained">Remove</Button>
						</Box>
						<Box sx={displayError}>
							<ErrorComp error={errorMessage} />
						</Box>
					</Stack>
				</Box>
			</Modal>
		</>
	)
}

export default DeleteModule