import { useAuth0 } from '@auth0/auth0-react';
import { Button, IconButton, Modal, Tooltip, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import React from 'react'
import { Account, AccountApi } from '../api';
import ApiConfig from './ApiConfig';
import DeleteIcon from '@mui/icons-material/Delete';
import ModalStyle from './ModalStyle';

function DeleteAccount(props: any) {
    const { user, getAccessTokenSilently } = useAuth0();
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [open, setOpen] = React.useState(false);

    const deleteAccount = (account: Account) => {
        const deleteAccountById = async () => {
            try {
                const token = await getAccessTokenSilently();
                const header = { headers: { Authorization: `Bearer ${token}` } }
                if (account.auth0Id) {
                    
                    new AccountApi(ApiConfig())
                        .deleteAccountById({id: account.auth0Id}, header).then(() => {
                            
                            props.getAllAccounts();
                        })
                }
            } catch (error) {
                console.error(error)
            }
            finally {
                props.getAllAccounts();
            }
        }
        deleteAccountById();
        handleClose();
    }

    return (
        <>
		{user && user.email !== props.account.email ? 
            <Tooltip title="Delete account">
                <IconButton onClick={handleOpen}>
                    <DeleteIcon color='error'/>
                </IconButton>
            </Tooltip>
		:<></> }
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="deleteAccount"
            >
                <Box sx={ModalStyle}>
                    <Stack spacing={2}>
                        <Typography id="modal-deleteAccount" variant="h6" component="h2">
                            Are you sure you want to remove: {props.account.email}
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', }}>
                            <Button variant="contained" onClick={handleClose}>Cancel</Button>
                            <Button color="error" id="delete-button" onClick={event => deleteAccount(props.account)} variant="contained">Remove</Button>
                        </Box>
                    </Stack>
                </Box>
            </Modal>
        </>
    )
} export default DeleteAccount