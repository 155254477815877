/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Account,
  ErrorResponse,
} from '../models';
import {
    AccountFromJSON,
    AccountToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
} from '../models';

export interface CreateAccountRequest {
    account: Account;
}

export interface DeleteAccountByIdRequest {
    id: string;
}

export interface GetAccountByIdRequest {
    id: string;
}

export interface UpdateAccountByIdRequest {
    id: string;
    account: Account;
}

/**
 * 
 */
export class AccountApi extends runtime.BaseAPI {

    /**
     * Create an account
     * Create an account
     */
    async createAccountRaw(requestParameters: CreateAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Account>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling createAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/account`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AccountToJSON(requestParameters.account),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountFromJSON(jsonValue));
    }

    /**
     * Create an account
     * Create an account
     */
    async createAccount(requestParameters: CreateAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Account> {
        const response = await this.createAccountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete an account by id
     * Delete an account
     */
    async deleteAccountByIdRaw(requestParameters: DeleteAccountByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Account>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteAccountById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountFromJSON(jsonValue));
    }

    /**
     * Delete an account by id
     * Delete an account
     */
    async deleteAccountById(requestParameters: DeleteAccountByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Account> {
        const response = await this.deleteAccountByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get an account by id
     * Get an account
     */
    async getAccountByIdRaw(requestParameters: GetAccountByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Account>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAccountById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountFromJSON(jsonValue));
    }

    /**
     * Get an account by id
     * Get an account
     */
    async getAccountById(requestParameters: GetAccountByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Account> {
        const response = await this.getAccountByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all accounts
     * Get all accounts
     */
    async getAllAccountsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Account>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AccountFromJSON));
    }

    /**
     * Get all accounts
     * Get all accounts
     */
    async getAllAccounts(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Account>> {
        const response = await this.getAllAccountsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get account for current user
     */
    async getOwnAccountRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Account>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountFromJSON(jsonValue));
    }

    /**
     * Get account for current user
     */
    async getOwnAccount(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Account> {
        const response = await this.getOwnAccountRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update an account by Id
     * Update an account
     */
    async updateAccountByIdRaw(requestParameters: UpdateAccountByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Account>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateAccountById.');
        }

        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling updateAccountById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/account/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AccountToJSON(requestParameters.account),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountFromJSON(jsonValue));
    }

    /**
     * Update an account by Id
     * Update an account
     */
    async updateAccountById(requestParameters: UpdateAccountByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Account> {
        const response = await this.updateAccountByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
