/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Box } from './Box';
import {
    BoxFromJSON,
    BoxFromJSONTyped,
    BoxToJSON,
} from './Box';
import type { Location } from './Location';
import {
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
} from './Location';

/**
 * 
 * @export
 * @interface DataPoint
 */
export interface DataPoint {
    /**
     * 
     * @type {string}
     * @memberof DataPoint
     */
    dataPointId?: string;
    /**
     * Path to raw data file (image) on S3 object storage
     * @type {string}
     * @memberof DataPoint
     */
    storagePath?: string;
    /**
     * 
     * @type {number}
     * @memberof DataPoint
     */
    height?: number;
    /**
     * 
     * @type {number}
     * @memberof DataPoint
     */
    width?: number;
    /**
     * ML inferred category
     * @type {string}
     * @memberof DataPoint
     */
    inferredCategory?: string;
    /**
     * Human inferred category, ie for training purpose
     * @type {string}
     * @memberof DataPoint
     */
    confirmedCategory?: string;
    /**
     * 
     * @type {number}
     * @memberof DataPoint
     */
    inferenceScore?: number;
    /**
     * 
     * @type {Location}
     * @memberof DataPoint
     */
    location?: Location;
    /**
     * 
     * @type {Date}
     * @memberof DataPoint
     */
    createdDate?: Date;
    /**
     * 
     * @type {Array<Box>}
     * @memberof DataPoint
     */
    boxes?: Array<Box>;
}

/**
 * Check if a given object implements the DataPoint interface.
 */
export function instanceOfDataPoint(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DataPointFromJSON(json: any): DataPoint {
    return DataPointFromJSONTyped(json, false);
}

export function DataPointFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataPoint {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dataPointId': !exists(json, 'dataPointId') ? undefined : json['dataPointId'],
        'storagePath': !exists(json, 'storagePath') ? undefined : json['storagePath'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'width': !exists(json, 'width') ? undefined : json['width'],
        'inferredCategory': !exists(json, 'inferredCategory') ? undefined : json['inferredCategory'],
        'confirmedCategory': !exists(json, 'confirmedCategory') ? undefined : json['confirmedCategory'],
        'inferenceScore': !exists(json, 'inferenceScore') ? undefined : json['inferenceScore'],
        'location': !exists(json, 'location') ? undefined : LocationFromJSON(json['location']),
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'boxes': !exists(json, 'boxes') ? undefined : ((json['boxes'] as Array<any>).map(BoxFromJSON)),
    };
}

export function DataPointToJSON(value?: DataPoint | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dataPointId': value.dataPointId,
        'storagePath': value.storagePath,
        'height': value.height,
        'width': value.width,
        'inferredCategory': value.inferredCategory,
        'confirmedCategory': value.confirmedCategory,
        'inferenceScore': value.inferenceScore,
        'location': LocationToJSON(value.location),
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'boxes': value.boxes === undefined ? undefined : ((value.boxes as Array<any>).map(BoxToJSON)),
    };
}

