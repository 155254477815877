/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  Module,
} from '../models';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    ModuleFromJSON,
    ModuleToJSON,
} from '../models';

export interface ActivateModuleRequest {
    moduleName: string;
    modelVersion: number;
}

export interface AddModuleRequest {
    module: Module;
}

export interface DeleteModuleByModuleIdRequest {
    moduleId: string;
}

export interface GetModuleByIdRequest {
    moduleId: string;
}

export interface UpdateModuleByIdRequest {
    moduleId: string;
    module: Module;
}

export interface UpdateModuleMetadataByIdRequest {
    moduleId: string;
    module: Module;
}

/**
 * 
 */
export class ModuleApi extends runtime.BaseAPI {

    /**
     * Activate a module using the moduleName
     * Activate a module
     */
    async activateModuleRaw(requestParameters: ActivateModuleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Module>> {
        if (requestParameters.moduleName === null || requestParameters.moduleName === undefined) {
            throw new runtime.RequiredError('moduleName','Required parameter requestParameters.moduleName was null or undefined when calling activateModule.');
        }

        if (requestParameters.modelVersion === null || requestParameters.modelVersion === undefined) {
            throw new runtime.RequiredError('modelVersion','Required parameter requestParameters.modelVersion was null or undefined when calling activateModule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/module/activate/{moduleName}/{modelVersion}`.replace(`{${"moduleName"}}`, encodeURIComponent(String(requestParameters.moduleName))).replace(`{${"modelVersion"}}`, encodeURIComponent(String(requestParameters.modelVersion))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModuleFromJSON(jsonValue));
    }

    /**
     * Activate a module using the moduleName
     * Activate a module
     */
    async activateModule(requestParameters: ActivateModuleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Module> {
        const response = await this.activateModuleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Add a module
     * Add a module
     */
    async addModuleRaw(requestParameters: AddModuleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Module>> {
        if (requestParameters.module === null || requestParameters.module === undefined) {
            throw new runtime.RequiredError('module','Required parameter requestParameters.module was null or undefined when calling addModule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/module`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ModuleToJSON(requestParameters.module),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModuleFromJSON(jsonValue));
    }

    /**
     * Add a module
     * Add a module
     */
    async addModule(requestParameters: AddModuleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Module> {
        const response = await this.addModuleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Method deletes a module
     */
    async deleteModuleByModuleIdRaw(requestParameters: DeleteModuleByModuleIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.moduleId === null || requestParameters.moduleId === undefined) {
            throw new runtime.RequiredError('moduleId','Required parameter requestParameters.moduleId was null or undefined when calling deleteModuleByModuleId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/module/{moduleId}`.replace(`{${"moduleId"}}`, encodeURIComponent(String(requestParameters.moduleId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Method deletes a module
     */
    async deleteModuleByModuleId(requestParameters: DeleteModuleByModuleIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteModuleByModuleIdRaw(requestParameters, initOverrides);
    }

    /**
     * Get the active module
     * Get the active module
     */
    async getActiveModuleRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Module>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/module/active`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModuleFromJSON(jsonValue));
    }

    /**
     * Get the active module
     * Get the active module
     */
    async getActiveModule(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Module> {
        const response = await this.getActiveModuleRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get all modules
     */
    async getAllModulesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Module>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/module`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ModuleFromJSON));
    }

    /**
     * Get all modules
     */
    async getAllModules(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Module>> {
        const response = await this.getAllModulesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get a module by id
     * Get a module
     */
    async getModuleByIdRaw(requestParameters: GetModuleByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Module>> {
        if (requestParameters.moduleId === null || requestParameters.moduleId === undefined) {
            throw new runtime.RequiredError('moduleId','Required parameter requestParameters.moduleId was null or undefined when calling getModuleById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/module/{moduleId}`.replace(`{${"moduleId"}}`, encodeURIComponent(String(requestParameters.moduleId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModuleFromJSON(jsonValue));
    }

    /**
     * Get a module by id
     * Get a module
     */
    async getModuleById(requestParameters: GetModuleByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Module> {
        const response = await this.getModuleByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Module by Id
     * Update Module by Id
     */
    async updateModuleByIdRaw(requestParameters: UpdateModuleByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Module>> {
        if (requestParameters.moduleId === null || requestParameters.moduleId === undefined) {
            throw new runtime.RequiredError('moduleId','Required parameter requestParameters.moduleId was null or undefined when calling updateModuleById.');
        }

        if (requestParameters.module === null || requestParameters.module === undefined) {
            throw new runtime.RequiredError('module','Required parameter requestParameters.module was null or undefined when calling updateModuleById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/module/{moduleId}`.replace(`{${"moduleId"}}`, encodeURIComponent(String(requestParameters.moduleId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ModuleToJSON(requestParameters.module),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModuleFromJSON(jsonValue));
    }

    /**
     * Update Module by Id
     * Update Module by Id
     */
    async updateModuleById(requestParameters: UpdateModuleByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Module> {
        const response = await this.updateModuleByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Module by Id
     * Update Module Metadata
     */
    async updateModuleMetadataByIdRaw(requestParameters: UpdateModuleMetadataByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Module>> {
        if (requestParameters.moduleId === null || requestParameters.moduleId === undefined) {
            throw new runtime.RequiredError('moduleId','Required parameter requestParameters.moduleId was null or undefined when calling updateModuleMetadataById.');
        }

        if (requestParameters.module === null || requestParameters.module === undefined) {
            throw new runtime.RequiredError('module','Required parameter requestParameters.module was null or undefined when calling updateModuleMetadataById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/module/{moduleId}`.replace(`{${"moduleId"}}`, encodeURIComponent(String(requestParameters.moduleId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ModuleToJSON(requestParameters.module),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModuleFromJSON(jsonValue));
    }

    /**
     * Update Module by Id
     * Update Module Metadata
     */
    async updateModuleMetadataById(requestParameters: UpdateModuleMetadataByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Module> {
        const response = await this.updateModuleMetadataByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
