/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserAgreement } from './UserAgreement';
import {
    UserAgreementFromJSON,
    UserAgreementFromJSONTyped,
    UserAgreementToJSON,
} from './UserAgreement';

/**
 * 
 * @export
 * @interface RestrictedRegister
 */
export interface RestrictedRegister {
    /**
     * 
     * @type {string}
     * @memberof RestrictedRegister
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof RestrictedRegister
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof RestrictedRegister
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof RestrictedRegister
     */
    lastName?: string;
    /**
     * 
     * @type {Array<UserAgreement>}
     * @memberof RestrictedRegister
     */
    confirmations?: Array<UserAgreement>;
}

/**
 * Check if a given object implements the RestrictedRegister interface.
 */
export function instanceOfRestrictedRegister(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "username" in value;

    return isInstance;
}

export function RestrictedRegisterFromJSON(json: any): RestrictedRegister {
    return RestrictedRegisterFromJSONTyped(json, false);
}

export function RestrictedRegisterFromJSONTyped(json: any, ignoreDiscriminator: boolean): RestrictedRegister {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'username': json['username'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'confirmations': !exists(json, 'confirmations') ? undefined : ((json['confirmations'] as Array<any>).map(UserAgreementFromJSON)),
    };
}

export function RestrictedRegisterToJSON(value?: RestrictedRegister | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
        'email': value.email,
        'first_name': value.firstName,
        'last_name': value.lastName,
        'confirmations': value.confirmations === undefined ? undefined : ((value.confirmations as Array<any>).map(UserAgreementToJSON)),
    };
}

