import { TableRow, TableCell, Collapse, Box, Typography, Table, TableHead, TableBody, TablePagination, Drawer } from "@mui/material";
import { Module, PaginatedTaskReadList, Project } from "../api";
import Rerun from "./Rerun";

const ProjectDetails = (hooks: any, props: any, routeChange: Function, project:Project, modules: Module[], addProject: (project: Project) => void) => {
    const { open, page, rowsPerPage, handleChangePage, findTrainingProjectName } = hooks;
    const tasks: PaginatedTaskReadList = hooks.tasks;
    return <><TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>

            <Collapse in={open}  timeout="auto" unmountOnExit>
                <Rerun project={project} modules={modules} addProject={addProject}></Rerun>
             <Box sx={{ margin: 1 }}>
                    
                    <Typography variant="h6" gutterBottom component="div">
                        Used in trainings
                    </Typography>
                    <Table size="small" aria-label="purchases">
                        <TableHead>
                            <TableRow>
                                <TableCell><b>Training</b></TableCell>
                                <TableCell><b>ID</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                tasks.results?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(task => {
                                    return (
                                        <TableRow key={task.id} onClick={() => routeChange(task.projectId as number)} hover>
                                            <TableCell>
                                                {findTrainingProjectName(props.trainingProjects, task.projectId).name as string}
                                            </TableCell>
                                            <TableCell>{findTrainingProjectName(props.trainingProjects, task.projectId).id as number}</TableCell>

                                        </TableRow>
                                    )
                                })
                            }
                            <TablePagination
                                rowsPerPageOptions={[tasks.results?.length as number || 0]}
                                component="div"
                                count={tasks.results?.length as number || 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                            />

                        </TableBody>
                    </Table>
                </Box>
            </Collapse>
        </TableCell>
    </TableRow>
    </>
}
export default ProjectDetails;