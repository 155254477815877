import { Button, IconButton, List, ListItem, ListItemText, ListSubheader, Modal, Stack, Tooltip, Typography } from '@mui/material'
import { Box } from '@mui/system';
import React, { useState } from 'react'
import ModalStyle from './ModalStyle';
import { useAuth0 } from '@auth0/auth0-react';
import { License } from '../api';
import { ThumbUpOffAlt } from '@mui/icons-material';

function ApproveLicense(props: { licenseRequest:License, approveLicense:Function }) {

	const { getAccessTokenSilently } = useAuth0();
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => { setOpen(true); refresh(); };
	const handleClose = () => { setOpen(false); refresh(); };
	const [displayError, setDisplayError] = useState({ display: 'none' })
	const [errorMessage, setErrorMessage] = useState('')
	const [loading, setLoading] = useState(false);

	const refresh = () => {
		setDisplayError({ display: 'none' })
		setLoading(false)
	}

	const approveLicense = async (license: License) => {
			try {
				setLoading(true)
				props.approveLicense(license);
				setLoading(false);
				handleClose()
			} catch (error: any) {
				setErrorMessage('Something went wrong')
				setDisplayError({ display: 'block' })
				setLoading(false)
				console.log(error)
			}
	}


	return (
		<>
										<IconButton onClick={handleOpen}>
											<Tooltip title="Approve request"><ThumbUpOffAlt /></Tooltip>
											
										</IconButton>
										<Modal key={'approve'}
											open={open}
											onClose={handleClose}
											aria-labelledby="view"
										>
											<Box sx={ModalStyle}>
												
												<Stack spacing={2}>
												<Typography id="modal-deleteAccount" variant="h6" component="h2">
													Approve license request for module {props.licenseRequest.module?.name} for {props.licenseRequest.organization?.name}?
												</Typography>
												
									
													<List
														sx={{
															width: '100%',
															//maxWidth: 360,
															bgcolor: 'background.paper',
															position: 'relative',
															overflow: 'auto',
															//maxHeight: 300,
															'& ul': { padding: 0 },
														}}
														subheader={<li />}
													>
														<ListSubheader>Labels accessed</ListSubheader>
														{props.licenseRequest.labels?.map((label: string) => (
															<li key={`section-${label}-${props.licenseRequest.id}`}>
																<ul>
																	<ListItem>
																		<ListItemText primary={label} />
																	</ListItem>
																</ul>
															</li>
														))}
													</List>
													<Box sx={{ display: 'flex', justifyContent: 'space-between', }}>
													<Button variant="contained" onClick={handleClose}>Cancel</Button>
													<Button color="success" id="delete-button" onClick={() => { approveLicense(props.licenseRequest); handleClose(); }} variant="contained">Approve <ThumbUpOffAlt></ThumbUpOffAlt></Button>
												</Box>
												</Stack>
											</Box>
										</Modal>
									</>
	)
}

export default ApproveLicense;