/// Module to show more details about data point 
/// Module will show higher resolution image and more details about the data point, draw bounding boxes, etc.

import React, { useEffect, useState } from 'react'
import { DataPoint } from '../api';
import exp from 'constants';
import DeleteIcon from '@mui/icons-material/Delete';

import { Box, IconButton, ImageList, ImageListItem, ImageListItemBar, Tooltip, Typography } from '@mui/material';
const drawBoundingBox = (data:DataPoint, imgSizeX:number, imgSizeY:number)=>{
  return data.boxes?.filter(b=>b.height&&b.width && b.x && b.y).map(b=><Tooltip title={b.label + " : " +b.score}><Box key={Math.random()}
  sx={{
    position: 'absolute',
    width: imgSizeX*(b.width?b.width:0),
    height: imgSizeY*(b.height?b.height:0),
    top: imgSizeY*(b.y?b.y:0),
    left: imgSizeX*(b.x?b.x:0),
    borderRadius: 1,
    borderColor: 'primary.main',
    border: '2px solid primary.main',
    zIndex: 1,
    bgcolor: 'rgba(255, 255, 255, 0.1)',
    '&:hover': {
      bgcolor: 'rgba(255, 255, 255, 0.6)',
    },
  }}
><Typography sx={{fontSize:12,textAlign:'center'}}>{b.label}</Typography></Box></Tooltip>)
}


function DataPointDetails(props: { data: DataPoint,projectId:string, deleteData: (dataPointId: string) => void }) {
    

    const { data, deleteData } = props;
    const [expanded, setExpanded] = useState(false);
    const thumbSize = 256;
    //console.log(props.projectId);
    const showOriginalSizeW = Math.max(Math.min(2*thumbSize, data.width?data.width:2*thumbSize), thumbSize);
    const aspectRatio = data.width && data.height ? data.width / data.height : 1;
    const showOriginalSizeH = showOriginalSizeW / aspectRatio;
    if(data.storagePath?.split("/").length !== 2){
      data.storagePath = props.projectId + "/" + data.storagePath;
    }
    let thumbSrc = '';
    try{
      thumbSrc = data.storagePath? `https://ai-maps.ams3.digitaloceanspaces.com/${data.storagePath.split("/")[0]}/thumbnails/${data.storagePath.split("/")[1].split(".")[0]}.${data.storagePath.endsWith("jpeg") ? 'jpeg' : 'jpg'}`:''
    }catch(e){
      console.log(e);
    }
    const handleExpand = (expanded:boolean) => {
        setExpanded(!expanded);
    }
    const  InfoBar = (data: DataPoint)=>{
      return <ImageListItemBar
      title={data.inferredCategory}
      subtitle={data.inferenceScore}
      actionIcon={
        <IconButton onClick={() => deleteData(data.dataPointId as string)}>
        <DeleteIcon color='error' />
      </IconButton>
      }
    />
    }
    return (
       <>{!expanded? <ImageListItem sx={{position:'relative', width: thumbSize, height: thumbSize}} onClick={()=>handleExpand(expanded)} /* sx={{m:1, height:120}} */ key={data.dataPointId} >
        <img
            src={thumbSrc} alt="No thumbnail found" 
            loading="lazy"
        />
        {drawBoundingBox(data, thumbSize, thumbSize)}
        <InfoBar {...data}></InfoBar>
       
        
        </ImageListItem>: 
        <ImageListItem cols={Math.ceil(showOriginalSizeW/thumbSize)} sx={{position:'relative', width: showOriginalSizeW, height: showOriginalSizeH, zIndex:3}} onClick={()=>setExpanded(exp=>!exp)} key={data.dataPointId} >
                  {drawBoundingBox(data, showOriginalSizeW, showOriginalSizeH) }

        <img
            src={`https://ai-maps.ams3.digitaloceanspaces.com/${data.storagePath}`} alt="No image found" 
            loading="lazy"
        />
                <InfoBar {...data}></InfoBar>

        </ImageListItem> }
        </> 
   )

}
export default DataPointDetails;
{/* <ImageListItem >
                

    </ImageListItem>:<a href={`https://ai-maps.ams3.digitaloceanspaces.com/${data.storagePath}`} target="_blank" rel="noopener noreferrer">}  */}
