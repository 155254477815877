import { Button, Modal, TextField, Typography, IconButton, MenuItem, Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { useAuth0 } from '@auth0/auth0-react';
import React, { useState } from 'react'
import ApiConfig from './ApiConfig';
import { ModelTraining, Help } from '@mui/icons-material';
import { Label, LabeledShapeRequest, LocationEnum, Project, ProjectRead } from '../api';
import ModalStyle from './ModalStyle';
import ErrorComp from './ErrorComp';
import { LoadingButton } from '@mui/lab';
import { TrainingApi } from '../api';


function AddProjectToCVATProject(props: any) {
	const [open, setOpen] = React.useState(false);
	const { getAccessTokenSilently } = useAuth0();
	const handleOpen = () => { setOpen(true); refresh(); };
	const handleClose = () => { setOpen(false); refresh() };
	const [project, setProject] = useState<Project>(props.project)
	const [trainingProject, setTrainingProject] = useState<ProjectRead>()
	const [displayError, setDisplayError] = useState({ display: 'none' })
	const [errorMessage, setErrorMessage] = useState('')
	const [loading, setLoading] = useState(false);
	const [label, setLabel] = useState<Label>()
	const [checked, setChecked] = useState(false)
	const [modalConfirm, setModalConfirm] = useState(false)

	const header = async () => {
		return { headers: { Authorization: `Bearer ${await getAccessTokenSilently()}` } };
	}

	const refresh = () => {
		setProject(props.project)
		setTrainingProject(undefined)
		setLabel(undefined)
		setChecked(false)
		setDisplayError({ display: 'none' })
		setLoading(false)
	}

	const handeLabelModal = () => {
		if (trainingProject === undefined) {
			setErrorMessage('Please select a training-project')
			setDisplayError({ display: 'block' })
		}
		else if (project.heldImages === 0) {
			setErrorMessage('This project has no images')
			setDisplayError({ display: 'block' })
		} else {
			setModalConfirm(true)
		}
	}


	const handleTrainingProjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const trainingProject = props.trainingProjects?.results?.find((o: ProjectRead) => o.name === event.target.value)
		if (module) {
			setTrainingProject(trainingProject)
		}
	};

	const handleLabelChange = (labelName: string) => {
		const label = trainingProject?.labels?.find(label => label.name === labelName)
		setLabel(label)
	}

	function delay(time: number) {
		return new Promise(resolve => setTimeout(resolve, time));
	  }

	const CreateTrainingTask = async () => {
		if (trainingProject === undefined) {
			setErrorMessage('Please select a training-project')
			setDisplayError({ display: 'block' })
		}
		else if (project.heldImages === 0) {
			setErrorMessage('This project has no images')
			setDisplayError({ display: 'block' })
		}
		else {
			try {
				setLoading(true)
				const token = await getAccessTokenSilently();
				const requestOptions = {
					headers: {
						Authorization: `Bearer ${token}`,
						Accept: 'application/json', 'Content-Type': 'application/json'
					},
				}
				const taskWriteRequest = {
					projectId: trainingProject.id,
					name: project.name as string,
					targetStorage: {
						location: LocationEnum.CloudStorage,
						cloudStorageId: project.cloudStorageId
					},
					sourceStorage: {
						location: LocationEnum.CloudStorage,
						cloudStorageId: project.cloudStorageId
					}
				}

				const response = await new TrainingApi(ApiConfig()).tasksCreate({ taskWriteRequest: taskWriteRequest }, requestOptions)
					.then(async response => {
						await new TrainingApi(ApiConfig()).tasksCreateData({
							id: response.id as number,
							projectId: project.projectId as string,
							dataRequest: {
								imageQuality: 70,
								cloudStorageId: project.cloudStorageId as number,
								useCache: true,
								copyData: true,
							}
						}, requestOptions)
						return response
					})

				if (label) {
					await delay(2000)
					await new TrainingApi(ApiConfig()).tasksRetrieve({ id: response.id as number }, header).then(response => {
						let jobList: { jobId: number, startFrame: number, stopFrame: number }[] = []
						response.segments?.map(object => jobList.push({
							jobId: object.jobs[0].id as number,
							startFrame: object.startFrame as number,
							stopFrame: object.stopFrame as number
						})
						)
						jobList.forEach(async job => {
							let shapes: LabeledShapeRequest[] = [];
							for (let i = job.startFrame; i <= job.stopFrame; i++) {
								shapes.push({
									type: "rectangle",
									frame: i,
									labelId: label?.id as number
								})
							}
							const patchedLabeledDataRequest = {
								shapes: shapes
							}
							//console.log(patchedLabeledDataRequest)
							await new TrainingApi(ApiConfig()).jobsPartialUpdateAnnotations({
								action: "update", id: job.jobId, patchedLabeledDataRequest: patchedLabeledDataRequest
							}, requestOptions)
						})
					})
				}
				setLoading(false)
				handleClose()
			} catch (error) {
				console.log(error)
				setLoading(false)
				setErrorMessage('Something went wrong')
				setDisplayError({ display: 'block' })
			}
		}
	}

	return (
		<>
			<Tooltip title="Add to Training">
				<IconButton onClick={handleOpen}>
					<ModelTraining />
				</IconButton>
			</Tooltip>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="createOrg"
			>
				<Box sx={ModalStyle}>
					<Stack spacing={2}>
						<Typography id="modal-project" variant="h6" component="h2">
							Add {props.project.name} to Training
						</Typography>
						<TextField id="role-field" name='Training' defaultValue={''} select onChange={handleTrainingProjectChange} label="Training" variant="outlined">
							{props.trainingProjects?.results?.map((option: ProjectRead) => (
								<MenuItem key={option.name} value={option.name}>
									{option.name}
								</MenuItem>
							))}
						</TextField>
						<Box>
							<FormControlLabel control={<Checkbox onChange={(e) => setChecked(e.target.checked)} />} label="Auto-labeling" />
							<Tooltip title="All images will be automatically annotated with the label you choose">
								<IconButton> <Help /> </IconButton>
							</Tooltip>
						</Box>
						<TextField id="label-images" name="Label Images" defaultValue={''} disabled={!checked} select onChange={(e) => handleLabelChange(e.target.value)} label="Label Images" variant='outlined'>
							{trainingProject?.labels?.map((label) => (
								<MenuItem key={label.id} value={label.name}>
									{label.name}
								</MenuItem>
							))}
						</TextField>
						<Box sx={displayError}>
							<ErrorComp error={errorMessage} />
						</Box>
						<Box sx={{ display: 'flex', justifyContent: 'space-between', }}>
							<Button variant="contained" onClick={handleClose}>Cancel</Button>
							{label === undefined ?
								<>
									<Modal
										open={modalConfirm}
										onClose={() => setModalConfirm(false)}
										aria-labelledby="deleteProject"
									>
										<Box sx={ModalStyle}>
											<Stack spacing={2}>
												<Typography id="modal-deleteProject" variant="h6" component="h2">
													No auto-labeling chosen. Are you sure you want to continue?
												</Typography>
												<Box sx={{ display: 'flex', justifyContent: 'space-between', }}>
													<Button variant="contained" onClick={() => setModalConfirm(false)}>Cancel</Button>
													<Button id="delete-button" onClick={() => { setModalConfirm(false); CreateTrainingTask(); }} variant="contained">Continue</Button>
												</Box>
											</Stack>
										</Box>
									</Modal>
									<LoadingButton loading={loading} onClick={() => handeLabelModal()} variant={"contained"}>Submit</LoadingButton>
								</>
								:
								<LoadingButton loading={loading} id="create-button" onClick={() => CreateTrainingTask()} variant="contained">Submit</LoadingButton>
							}
						</Box>
					</Stack>
				</Box>
			</Modal>
		</>

	)

} export default AddProjectToCVATProject;