/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FrameMeta
 */
export interface FrameMeta {
    /**
     * 
     * @type {number}
     * @memberof FrameMeta
     */
    width: number;
    /**
     * 
     * @type {number}
     * @memberof FrameMeta
     */
    height: number;
    /**
     * 
     * @type {string}
     * @memberof FrameMeta
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof FrameMeta
     */
    hasRelatedContext: boolean;
}

/**
 * Check if a given object implements the FrameMeta interface.
 */
export function instanceOfFrameMeta(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "width" in value;
    isInstance = isInstance && "height" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "hasRelatedContext" in value;

    return isInstance;
}

export function FrameMetaFromJSON(json: any): FrameMeta {
    return FrameMetaFromJSONTyped(json, false);
}

export function FrameMetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrameMeta {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'width': json['width'],
        'height': json['height'],
        'name': json['name'],
        'hasRelatedContext': json['has_related_context'],
    };
}

export function FrameMetaToJSON(value?: FrameMeta | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'width': value.width,
        'height': value.height,
        'name': value.name,
        'has_related_context': value.hasRelatedContext,
    };
}

