/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DataPoint } from './DataPoint';
import {
    DataPointFromJSON,
    DataPointFromJSONTyped,
    DataPointToJSON,
} from './DataPoint';
import type { Module } from './Module';
import {
    ModuleFromJSON,
    ModuleFromJSONTyped,
    ModuleToJSON,
} from './Module';
import type { Organization } from './Organization';
import {
    OrganizationFromJSON,
    OrganizationFromJSONTyped,
    OrganizationToJSON,
} from './Organization';
import type { SummaryItem } from './SummaryItem';
import {
    SummaryItemFromJSON,
    SummaryItemFromJSONTyped,
    SummaryItemToJSON,
} from './SummaryItem';

/**
 * 
 * @export
 * @interface Project
 */
export interface Project {
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    projectId?: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    creatorRole?: string;
    /**
     * 
     * @type {number}
     * @memberof Project
     */
    heldImages?: number;
    /**
     * 
     * @type {Module}
     * @memberof Project
     */
    module?: Module;
    /**
     * 
     * @type {Organization}
     * @memberof Project
     */
    organization?: Organization;
    /**
     * 
     * @type {Array<DataPoint>}
     * @memberof Project
     */
    dataPoint?: Array<DataPoint>;
    /**
     * 
     * @type {number}
     * @memberof Project
     */
    cloudStorageId?: number;
    /**
     * 
     * @type {Array<SummaryItem>}
     * @memberof Project
     */
    dataSummary?: Array<SummaryItem>;
    /**
     * 
     * @type {Array<SummaryItem>}
     * @memberof Project
     */
    taskSummary?: Array<SummaryItem>;
}

/**
 * Check if a given object implements the Project interface.
 */
export function instanceOfProject(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProjectFromJSON(json: any): Project {
    return ProjectFromJSONTyped(json, false);
}

export function ProjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): Project {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'creatorRole': !exists(json, 'creatorRole') ? undefined : json['creatorRole'],
        'heldImages': !exists(json, 'heldImages') ? undefined : json['heldImages'],
        'module': !exists(json, 'module') ? undefined : ModuleFromJSON(json['module']),
        'organization': !exists(json, 'organization') ? undefined : OrganizationFromJSON(json['organization']),
        'dataPoint': !exists(json, 'DataPoint') ? undefined : ((json['DataPoint'] as Array<any>).map(DataPointFromJSON)),
        'cloudStorageId': !exists(json, 'cloudStorageId') ? undefined : json['cloudStorageId'],
        'dataSummary': !exists(json, 'dataSummary') ? undefined : ((json['dataSummary'] as Array<any>).map(SummaryItemFromJSON)),
        'taskSummary': !exists(json, 'taskSummary') ? undefined : ((json['taskSummary'] as Array<any>).map(SummaryItemFromJSON)),
    };
}

export function ProjectToJSON(value?: Project | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'projectId': value.projectId,
        'name': value.name,
        'description': value.description,
        'creatorRole': value.creatorRole,
        'heldImages': value.heldImages,
        'module': ModuleToJSON(value.module),
        'organization': OrganizationToJSON(value.organization),
        'DataPoint': value.dataPoint === undefined ? undefined : ((value.dataPoint as Array<any>).map(DataPointToJSON)),
        'cloudStorageId': value.cloudStorageId,
        'dataSummary': value.dataSummary === undefined ? undefined : ((value.dataSummary as Array<any>).map(SummaryItemToJSON)),
        'taskSummary': value.taskSummary === undefined ? undefined : ((value.taskSummary as Array<any>).map(SummaryItemToJSON)),
    };
}

