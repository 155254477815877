/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const SortingMethod = {
    Lexicographical: 'lexicographical',
    Natural: 'natural',
    Predefined: 'predefined',
    Random: 'random'
} as const;
export type SortingMethod = typeof SortingMethod[keyof typeof SortingMethod];


export function SortingMethodFromJSON(json: any): SortingMethod {
    return SortingMethodFromJSONTyped(json, false);
}

export function SortingMethodFromJSONTyped(json: any, ignoreDiscriminator: boolean): SortingMethod {
    return json as SortingMethod;
}

export function SortingMethodToJSON(value?: SortingMethod | null): any {
    return value as any;
}

