/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const InputTypeEnum = {
    Checkbox: 'checkbox',
    Radio: 'radio',
    Number: 'number',
    Text: 'text',
    Select: 'select'
} as const;
export type InputTypeEnum = typeof InputTypeEnum[keyof typeof InputTypeEnum];


export function InputTypeEnumFromJSON(json: any): InputTypeEnum {
    return InputTypeEnumFromJSONTyped(json, false);
}

export function InputTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): InputTypeEnum {
    return json as InputTypeEnum;
}

export function InputTypeEnumToJSON(value?: InputTypeEnum | null): any {
    return value as any;
}

