import { LoadingButton } from '@mui/lab'
import { Autocomplete, Button, Chip, ListItem, Modal, TextField, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system';
import React, { useEffect, useState } from 'react'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ModalStyle from './ModalStyle';
import ErrorComp from './ErrorComp';
import { useAuth0 } from '@auth0/auth0-react';
import { Module, ModuleApi } from '../api';
import ApiConfig from './ApiConfig';

function AddModule(props: { user: any, getAllModules: any }) {

	const { getAccessTokenSilently } = useAuth0();
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => { setOpen(true); refresh(); };
	const handleClose = () => { setOpen(false); refresh(); };
	const [displayError, setDisplayError] = useState({ display: 'none' })
	const [errorMessage, setErrorMessage] = useState('')
	const [loading, setLoading] = useState(false);
	const [module, setModule] = useState<Module>({})
	const [labels, setLabels] = useState<string[]>([])
	const [selectedImage, setSelectedImage] = useState<File>();

	const refresh = () => {
		setModule({})
		setSelectedImage(undefined)
		setDisplayError({ display: 'none' })
		setLoading(false)
	}

	const addModule = async () => {
		if (module.name == undefined || module.name == "") {
			setErrorMessage('Please name your module')
			setDisplayError({ display: 'block' })
		} else if (labels.length === 0) {
			setErrorMessage('You need at least one label')
			setDisplayError({ display: 'block' })
		} else {
			try {
				setLoading(true)
				const token = await getAccessTokenSilently();
				const header = {
					headers: {
						Authorization: `Bearer ${token}`,
						Accept: 'application/json', 'Content-Type': 'application/json'
					}
				};

				let base64Encoded = undefined;
				if (selectedImage) {
					base64Encoded = await toBase64(selectedImage) as string;
				}

				const moduleRequest = {
					name: module.name,
					description: module.description,
					//modelName: "props.trainerStatus.project",
					image: base64Encoded,
					//path: "/active_models/" + "props.trainerStatus.project",
					modelVersion: 0,
					labelMap: labels
				}

				await new ModuleApi(ApiConfig()).addModule({ module: moduleRequest }, header).then(async () => await props.getAllModules())
				setLoading(false)
				handleClose()

			} catch (error: any) {
				if (error.response.status === 403) {
					setErrorMessage('Name must be unique')
					setDisplayError({ display: 'block' })
				} else {
					setErrorMessage('Something went wrong')
					setDisplayError({ display: 'block' })
				}
				setLoading(false)
				console.log(error)
			}
		}

	}

	const handleNameChange = (name: string) => {
		module.name = name
	}

	const handleDescriptionChange = (description: string) => {
		module.description = description
	}

	const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSelectedImage(e.target.files![0])
		console.log(e.target.files![0])
	}

	const toBase64 = (file: Blob) => new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = error => reject(error);
	});

	return (
		<div>
			<Button className='addModule-button' onClick={handleOpen} variant="contained">
				Create<AddCircleOutlineOutlinedIcon />
			</Button>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="addModule"
			>
				<Box sx={ModalStyle}>
					<Stack spacing={2}>
						<Typography id="modal-signup" variant="h6" component="h2">
							New Module
						</Typography>
						<TextField id="modulename-field" name='name' onChange={(e) => handleNameChange(e.target.value)} label="Name" variant="outlined" />
						<TextField id="moduledesc-field" name='description' onChange={(e) => handleDescriptionChange(e.target.value)} label="Description" variant="outlined" />
						<TextField id="moduleversion-field" defaultValue={0} name='modelVersion' disabled label="modelVersion" variant="outlined" />
						<Button
							component="label"
							variant="outlined"
							startIcon={<UploadFileIcon />}
							sx={{ marginRight: "1rem" }}
						>
							Upload image
							<input type="file" accept="image/jpeg, image/png" hidden onChange={(e) => handleFileUpload(e)} />
						</Button>
						{!selectedImage ? <></> : selectedImage.name}

						<Autocomplete
							multiple
							id="tags-filled"
							options={[]}
							freeSolo
							onChange={(e, newValue, reason) => setLabels(newValue)}
							renderTags={(labels: string[], getTagProps) =>
								labels.map((option: string, index: number) => (
									<Chip variant="outlined" label={option} {...getTagProps({ index })} />
								))
							}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Labels"
									placeholder="Type and press enter"
									value={labels}
								/>
							)}
						/>
						<Box sx={displayError}>
							<ErrorComp error={errorMessage} />
						</Box>
						<Box sx={{ display: 'flex', justifyContent: 'space-between', }}>
							<Button variant="contained" onClick={handleClose}>Cancel</Button>
							<LoadingButton loading={loading} id="create-button" onClick={addModule} variant="contained">Submit</LoadingButton>
						</Box>
					</Stack>
				</Box>
			</Modal>
		</div>
	)
}

export default AddModule