/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Export
 */
export interface Export {
    /**
     * 
     * @type {string}
     * @memberof Export
     */
    exportId?: string;
}

/**
 * Check if a given object implements the Export interface.
 */
export function instanceOfExport(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExportFromJSON(json: any): Export {
    return ExportFromJSONTyped(json, false);
}

export function ExportFromJSONTyped(json: any, ignoreDiscriminator: boolean): Export {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'exportId': !exists(json, 'exportId') ? undefined : json['exportId'],
    };
}

export function ExportToJSON(value?: Export | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'exportId': value.exportId,
    };
}

