/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BasicUserRequest } from './BasicUserRequest';
import {
    BasicUserRequestFromJSON,
    BasicUserRequestFromJSONTyped,
    BasicUserRequestToJSON,
} from './BasicUserRequest';
import type { CredentialsTypeEnum } from './CredentialsTypeEnum';
import {
    CredentialsTypeEnumFromJSON,
    CredentialsTypeEnumFromJSONTyped,
    CredentialsTypeEnumToJSON,
} from './CredentialsTypeEnum';
import type { ProviderTypeEnum } from './ProviderTypeEnum';
import {
    ProviderTypeEnumFromJSON,
    ProviderTypeEnumFromJSONTyped,
    ProviderTypeEnumToJSON,
} from './ProviderTypeEnum';

/**
 * 
 * @export
 * @interface CloudStorageWriteRequest
 */
export interface CloudStorageWriteRequest {
    /**
     * 
     * @type {ProviderTypeEnum}
     * @memberof CloudStorageWriteRequest
     */
    providerType: ProviderTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CloudStorageWriteRequest
     */
    resource: string;
    /**
     * 
     * @type {string}
     * @memberof CloudStorageWriteRequest
     */
    displayName: string;
    /**
     * 
     * @type {BasicUserRequest}
     * @memberof CloudStorageWriteRequest
     */
    owner?: BasicUserRequest;
    /**
     * 
     * @type {CredentialsTypeEnum}
     * @memberof CloudStorageWriteRequest
     */
    credentialsType: CredentialsTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CloudStorageWriteRequest
     */
    sessionToken?: string;
    /**
     * 
     * @type {string}
     * @memberof CloudStorageWriteRequest
     */
    accountName?: string;
    /**
     * 
     * @type {string}
     * @memberof CloudStorageWriteRequest
     */
    key?: string;
    /**
     * 
     * @type {string}
     * @memberof CloudStorageWriteRequest
     */
    secretKey?: string;
    /**
     * 
     * @type {Blob}
     * @memberof CloudStorageWriteRequest
     */
    keyFile?: Blob;
    /**
     * 
     * @type {string}
     * @memberof CloudStorageWriteRequest
     */
    specificAttributes?: string;
    /**
     * 
     * @type {string}
     * @memberof CloudStorageWriteRequest
     */
    description?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CloudStorageWriteRequest
     */
    manifests?: Array<string>;
}

/**
 * Check if a given object implements the CloudStorageWriteRequest interface.
 */
export function instanceOfCloudStorageWriteRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "providerType" in value;
    isInstance = isInstance && "resource" in value;
    isInstance = isInstance && "displayName" in value;
    isInstance = isInstance && "credentialsType" in value;

    return isInstance;
}

export function CloudStorageWriteRequestFromJSON(json: any): CloudStorageWriteRequest {
    return CloudStorageWriteRequestFromJSONTyped(json, false);
}

export function CloudStorageWriteRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CloudStorageWriteRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'providerType': ProviderTypeEnumFromJSON(json['provider_type']),
        'resource': json['resource'],
        'displayName': json['display_name'],
        'owner': !exists(json, 'owner') ? undefined : BasicUserRequestFromJSON(json['owner']),
        'credentialsType': CredentialsTypeEnumFromJSON(json['credentials_type']),
        'sessionToken': !exists(json, 'session_token') ? undefined : json['session_token'],
        'accountName': !exists(json, 'account_name') ? undefined : json['account_name'],
        'key': !exists(json, 'key') ? undefined : json['key'],
        'secretKey': !exists(json, 'secret_key') ? undefined : json['secret_key'],
        'keyFile': !exists(json, 'key_file') ? undefined : json['key_file'],
        'specificAttributes': !exists(json, 'specific_attributes') ? undefined : json['specific_attributes'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'manifests': !exists(json, 'manifests') ? undefined : json['manifests'],
    };
}

export function CloudStorageWriteRequestToJSON(value?: CloudStorageWriteRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'provider_type': ProviderTypeEnumToJSON(value.providerType),
        'resource': value.resource,
        'display_name': value.displayName,
        'owner': BasicUserRequestToJSON(value.owner),
        'credentials_type': CredentialsTypeEnumToJSON(value.credentialsType),
        'session_token': value.sessionToken,
        'account_name': value.accountName,
        'key': value.key,
        'secret_key': value.secretKey,
        'key_file': value.keyFile,
        'specific_attributes': value.specificAttributes,
        'description': value.description,
        'manifests': value.manifests,
    };
}

