import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import { Box, Button, Checkbox, Divider, Drawer, FormControl, FormControlLabel, FormGroup, FormLabel, Tooltip } from '@mui/material';
import { CheckCircle, PendingActions } from '@mui/icons-material';
import { ModuleProps } from './ModuleSearch';
import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const maxChars = 50;
const Img = styled('img')({
	margin: '5px',
	display: 'block',
	maxWidth: '100%',
	maxHeight: '100%',
	float: 'left',
});

export default function ModuleCard(props: { moduleProps: ModuleProps }) {
	const [selectLabels, setSelectLabels] = useState<string[]>(props.moduleProps.license?.labels ? props.moduleProps.license.labels : []);
	const { user } = useAuth0()

	let firstHalf = props.moduleProps.module.labelMap?.slice(0, props.moduleProps.module.labelMap.length / 2) as string[]
	let lastHalf = props.moduleProps.module.labelMap?.slice(props.moduleProps.module.labelMap.length / 2, props.moduleProps.module.labelMap.length) as string[]

	const addOrRemoveLabel = (label: string) => {
		const index = selectLabels.indexOf(label);
		if (index === -1) {
			setSelectLabels([...selectLabels, label])
		} else {
			setSelectLabels([...selectLabels.splice(0, index), ...selectLabels.splice(index + 1)])
		}
	}
	const requestLicense = () => {
		const license = { module: props.moduleProps.module, labels: selectLabels, version: props.moduleProps.module.modelVersion }
		props.moduleProps.requestLicense(license);
	}
	const isRequestDisabled = () => {
		console.log(props.moduleProps.license?.labels?.length)
		if (props.moduleProps.licenseRequest) {
			return true;
		}
		if (selectLabels.length === 0) {
			return true;
		}
		if (selectLabels.length > 0 && !props.moduleProps.license) {
			return false;
		}

		console.log(selectLabels)
		const newLabelsSelected = props.moduleProps.license?.labels && props.moduleProps.license.labels.length !== selectLabels.length;
		return !newLabelsSelected;
	}
	type Anchor = 'top' | 'left' | 'bottom' | 'right';

	const [state, setState] = useState({
		top: false,
		left: false,
		bottom: false,
		right: false,
	});

	const toggleDrawer =
		(anchor: Anchor, open: boolean) =>
			(event: React.KeyboardEvent | React.MouseEvent) => {
				if (
					event.type === 'keydown' &&
					((event as React.KeyboardEvent).key === 'Tab' ||
						(event as React.KeyboardEvent).key === 'Shift')
				) {
					return;
				}

				setState({ ...state, [anchor]: open });
			};

	return (
		<Paper
			elevation={2}
			sx={{
				borderRadius: 2.5,
				p: 2,
				margin: 'auto',
				flexGrow: 1,
				backgroundColor: (theme) =>
					theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
			}}
		>
			<Grid container spacing={1}>
				<Grid item xs={3} >
					<ButtonBase sx={{ height: 128, borderRadius: '50%' }}>
						<Img sx={{
							display: "inline",
							margin: "auto",
							height: "100%",
							width: "auto",
							borderRadius: "50%"
						}} alt="No thumbnail found" src={`https://regis.ams3.digitaloceanspaces.com${props.moduleProps.module.image}`} />
					</ButtonBase>

				</Grid>
				<Grid item xs={7} sm container>
					<Grid item xs container direction="column" spacing={2}>
						<Typography gutterBottom variant="subtitle1" component="div">

							<Button onClick={toggleDrawer('right', true)}>
								{props.moduleProps.module.name}{props.moduleProps.license ? <Tooltip title={"Already installed"}><CheckCircle></CheckCircle></Tooltip> : <></>}
								{props.moduleProps.licenseRequest ? <Tooltip title={"Module requested"}><PendingActions></PendingActions></Tooltip> : <></>}
							</Button>
							<Drawer
								anchor={'right'}
								open={state['right']}
								onClose={toggleDrawer('right', false)}
							>
								<Grid container width={500} padding={5}>

									<Grid xs={12} >
										<Typography variant="h3" marginLeft={2} textAlign={'center'}>
											{props.moduleProps.module.name}
										</Typography>
										<Divider variant="middle" />
									</Grid>
									<Img sx={{
										//height: "100",
										padding: 2,
										//width: "30%",
										display: "block",
										marginLeft: "auto",
										marginRight: "auto"
									}} alt="No thumbnail found" src={`https://regis.ams3.digitaloceanspaces.com${props.moduleProps.module.image}`} />
									<Grid xs={12}>
										<Typography variant="body2" marginLeft={2} textAlign={'center'} padding={2}>
											{props.moduleProps.module.description}
										</Typography>
									</Grid>
									<Grid xs={12} padding={2} sx={{
										border: "2px solid grey",
										borderRadius: "5px"
									}}>
										<FormLabel component="legend" sx={{ textAlign: 'center' }}>Labels</FormLabel>
										<Box sx={{ display: 'flex' }}>
											<Grid container>

												<Grid item xs={6} padding={2}>
													{props.moduleProps.module.labelMap ? firstHalf.map(label =>
														<FormControlLabel disabled={user && user["https://aimaps.k8s.oniq.solutions/roles"][0] !== "superuser"} sx={{ padding: "5px 0 0 0" }} key={label} control={<Checkbox onChange={() => addOrRemoveLabel(label)} defaultChecked={props.moduleProps.license?.labels?.includes(label) || props.moduleProps.licenseRequest?.labels?.includes(label)} disabled={props.moduleProps.license?.labels?.includes(label) || props.moduleProps.licenseRequest !== undefined} />} label={label} />
													) : ''}
												</Grid>
												<Grid item xs={6} padding={2}>
													{props.moduleProps.module.labelMap ? lastHalf.map(label =>
														<FormControlLabel disabled={user && user["https://aimaps.k8s.oniq.solutions/roles"][0] !== "superuser"} sx={{ padding: "5px 0 0 0" }} key={label} control={<Checkbox onChange={() => addOrRemoveLabel(label)} defaultChecked={props.moduleProps.license?.labels?.includes(label) || props.moduleProps.licenseRequest?.labels?.includes(label)} disabled={props.moduleProps.license?.labels?.includes(label) || props.moduleProps.licenseRequest !== undefined} />} label={label} />
													) : ''}
												</Grid>
											</Grid>
										</Box>
									</Grid>
									<Grid xs={12} padding={1}>
										{user && user["https://aimaps.k8s.oniq.solutions/roles"][0] !== "superuser" ?
											<Tooltip title="You do not have permission to request licenses">
												<span>
													<Button disabled>Request Module</Button>
												</span>
											</Tooltip> :
											<Button color={"primary"} disabled={isRequestDisabled()} onClick={requestLicense}>{props.moduleProps.licenseRequest ? 'Module requested' : 'Request module'}</Button>
										}
									</Grid>

								</Grid>
							</Drawer>
						</Typography>
						<Grid container>
							<Grid xs={12} lg={12}>
								<Typography variant="body2" gutterBottom height={props.moduleProps.expanded ? '' : 20} marginLeft={2} >
									{(props.moduleProps.module.description && props.moduleProps.module.description?.length > maxChars && !props.moduleProps.expanded) ? props.moduleProps.module.description?.substring(0, 50) + '...' : props.moduleProps.module.description}
								</Typography>
							</Grid>

							<Grid xs={12} lg={12} style={{ textAlign: 'left', paddingTop: 20, paddingLeft: 20 }}>
								{props.moduleProps.expanded ?
									<FormControl component="fieldset" variant="standard" style={{ textAlign: 'left' }}>
										<FormGroup>
											<FormLabel component="legend">Labels</FormLabel>
											{props.moduleProps.module.labelMap ? props.moduleProps.module.labelMap.map(label => <FormControlLabel key={label} control={<Checkbox onChange={() => addOrRemoveLabel(label)} defaultChecked={props.moduleProps.license?.labels?.includes(label) || props.moduleProps.licenseRequest?.labels?.includes(label)} disabled={props.moduleProps.license?.labels?.includes(label) || props.moduleProps.licenseRequest !== undefined} />} label={label} />) : ''}
											<Button classes={"primary"} disabled={isRequestDisabled()} onClick={requestLicense}>{props.moduleProps.licenseRequest ? 'Module requested' : 'Request module'}</Button>
										</FormGroup></FormControl> : <></>}
							</Grid>
						</Grid>

					</Grid>


				</Grid>
			</Grid>

		</Paper>
	);
}