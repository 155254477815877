/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Account,
  ErrorResponse,
  Organization,
} from '../models';
import {
    AccountFromJSON,
    AccountToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    OrganizationFromJSON,
    OrganizationToJSON,
} from '../models';

export interface AddAccountForOrganizationRequest {
    account: Account;
}

export interface AddOrganizationRequest {
    organization: Organization;
}

export interface DeleteOrganizationRequest {
    organizationId: string;
}

export interface UpdateOrganizationRequest {
    organizationId: string;
    organization: Organization;
}

/**
 * 
 */
export class OrganizationApi extends runtime.BaseAPI {

    /**
     * Add new Account for Organization
     * Add new Account for Organization
     */
    async addAccountForOrganizationRaw(requestParameters: AddAccountForOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.account === null || requestParameters.account === undefined) {
            throw new runtime.RequiredError('account','Required parameter requestParameters.account was null or undefined when calling addAccountForOrganization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/organization/account`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AccountToJSON(requestParameters.account),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Add new Account for Organization
     * Add new Account for Organization
     */
    async addAccountForOrganization(requestParameters: AddAccountForOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.addAccountForOrganizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Add new Customer Organization
     * Add new Customer Organization
     */
    async addOrganizationRaw(requestParameters: AddOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Organization>> {
        if (requestParameters.organization === null || requestParameters.organization === undefined) {
            throw new runtime.RequiredError('organization','Required parameter requestParameters.organization was null or undefined when calling addOrganization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/organization`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrganizationToJSON(requestParameters.organization),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationFromJSON(jsonValue));
    }

    /**
     * Add new Customer Organization
     * Add new Customer Organization
     */
    async addOrganization(requestParameters: AddOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Organization> {
        const response = await this.addOrganizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete an Customer Organization
     */
    async deleteOrganizationRaw(requestParameters: DeleteOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Organization>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling deleteOrganization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/organization/{organizationId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationFromJSON(jsonValue));
    }

    /**
     * Delete an Customer Organization
     */
    async deleteOrganization(requestParameters: DeleteOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Organization> {
        const response = await this.deleteOrganizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all Accounts for an Organization
     * Get all Accounts for an Organization
     */
    async getAllAccountsForOrganizationRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Account>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/organization/account`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AccountFromJSON));
    }

    /**
     * Get all Accounts for an Organization
     * Get all Accounts for an Organization
     */
    async getAllAccountsForOrganization(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Account>> {
        const response = await this.getAllAccountsForOrganizationRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get all Organizations
     * Get all Organizations
     */
    async getAllOrganizationsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Organization>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/organization`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrganizationFromJSON));
    }

    /**
     * Get all Organizations
     * Get all Organizations
     */
    async getAllOrganizations(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Organization>> {
        const response = await this.getAllOrganizationsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update an Customer Organization
     */
    async updateOrganizationRaw(requestParameters: UpdateOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Organization>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling updateOrganization.');
        }

        if (requestParameters.organization === null || requestParameters.organization === undefined) {
            throw new runtime.RequiredError('organization','Required parameter requestParameters.organization was null or undefined when calling updateOrganization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/organization/{organizationId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OrganizationToJSON(requestParameters.organization),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationFromJSON(jsonValue));
    }

    /**
     * Update an Customer Organization
     */
    async updateOrganization(requestParameters: UpdateOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Organization> {
        const response = await this.updateOrganizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
