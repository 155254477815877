/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DataPoint,
  DataPoints,
  DataUploadRequest,
} from '../models';
import {
    DataPointFromJSON,
    DataPointToJSON,
    DataPointsFromJSON,
    DataPointsToJSON,
    DataUploadRequestFromJSON,
    DataUploadRequestToJSON,
} from '../models';

export interface CreateDataPointRequest {
    projectId: string;
    dataUploadRequest: DataUploadRequest;
}

export interface DeleteDataPointByIdRequest {
    dataPointId: string;
}

export interface GetDataPointByIdRequest {
    dataPointId: string;
}

export interface GetDataPointsForProjectRequest {
    projectId: string;
    page?: number;
}

/**
 * 
 */
export class DataApi extends runtime.BaseAPI {

    /**
     * Create a new data point by uploading an image in base64 encoded json
     */
    async createDataPointRaw(requestParameters: CreateDataPointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DataPoint>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling createDataPoint.');
        }

        if (requestParameters.dataUploadRequest === null || requestParameters.dataUploadRequest === undefined) {
            throw new runtime.RequiredError('dataUploadRequest','Required parameter requestParameters.dataUploadRequest was null or undefined when calling createDataPoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/data/project/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DataUploadRequestToJSON(requestParameters.dataUploadRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DataPointFromJSON(jsonValue));
    }

    /**
     * Create a new data point by uploading an image in base64 encoded json
     */
    async createDataPoint(requestParameters: CreateDataPointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DataPoint> {
        const response = await this.createDataPointRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove a datapoint by dataPointId
     * Remove a datapoint
     */
    async deleteDataPointByIdRaw(requestParameters: DeleteDataPointByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.dataPointId === null || requestParameters.dataPointId === undefined) {
            throw new runtime.RequiredError('dataPointId','Required parameter requestParameters.dataPointId was null or undefined when calling deleteDataPointById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/data/{dataPointId}`.replace(`{${"dataPointId"}}`, encodeURIComponent(String(requestParameters.dataPointId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove a datapoint by dataPointId
     * Remove a datapoint
     */
    async deleteDataPointById(requestParameters: DeleteDataPointByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteDataPointByIdRaw(requestParameters, initOverrides);
    }

    /**
     * Get a dataPoint by dataPointId
     * Get dataPoint by dataPointId
     */
    async getDataPointByIdRaw(requestParameters: GetDataPointByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DataPoint>> {
        if (requestParameters.dataPointId === null || requestParameters.dataPointId === undefined) {
            throw new runtime.RequiredError('dataPointId','Required parameter requestParameters.dataPointId was null or undefined when calling getDataPointById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/data/{dataPointId}`.replace(`{${"dataPointId"}}`, encodeURIComponent(String(requestParameters.dataPointId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DataPointFromJSON(jsonValue));
    }

    /**
     * Get a dataPoint by dataPointId
     * Get dataPoint by dataPointId
     */
    async getDataPointById(requestParameters: GetDataPointByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DataPoint> {
        const response = await this.getDataPointByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all DataPoints for a project
     * Get all DataPoints for a Project
     */
    async getDataPointsForProjectRaw(requestParameters: GetDataPointsForProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DataPoints>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getDataPointsForProject.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/data/project/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DataPointsFromJSON(jsonValue));
    }

    /**
     * Get all DataPoints for a project
     * Get all DataPoints for a Project
     */
    async getDataPointsForProject(requestParameters: GetDataPointsForProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DataPoints> {
        const response = await this.getDataPointsForProjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
