/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LabeledImageRequest } from './LabeledImageRequest';
import {
    LabeledImageRequestFromJSON,
    LabeledImageRequestFromJSONTyped,
    LabeledImageRequestToJSON,
} from './LabeledImageRequest';
import type { LabeledShapeRequest } from './LabeledShapeRequest';
import {
    LabeledShapeRequestFromJSON,
    LabeledShapeRequestFromJSONTyped,
    LabeledShapeRequestToJSON,
} from './LabeledShapeRequest';
import type { LabeledTrackRequest } from './LabeledTrackRequest';
import {
    LabeledTrackRequestFromJSON,
    LabeledTrackRequestFromJSONTyped,
    LabeledTrackRequestToJSON,
} from './LabeledTrackRequest';

/**
 * 
 * @export
 * @interface PatchedLabeledDataRequest
 */
export interface PatchedLabeledDataRequest {
    /**
     * 
     * @type {number}
     * @memberof PatchedLabeledDataRequest
     */
    version?: number;
    /**
     * 
     * @type {Array<LabeledImageRequest>}
     * @memberof PatchedLabeledDataRequest
     */
    tags?: Array<LabeledImageRequest>;
    /**
     * 
     * @type {Array<LabeledShapeRequest>}
     * @memberof PatchedLabeledDataRequest
     */
    shapes?: Array<LabeledShapeRequest>;
    /**
     * 
     * @type {Array<LabeledTrackRequest>}
     * @memberof PatchedLabeledDataRequest
     */
    tracks?: Array<LabeledTrackRequest>;
}

/**
 * Check if a given object implements the PatchedLabeledDataRequest interface.
 */
export function instanceOfPatchedLabeledDataRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PatchedLabeledDataRequestFromJSON(json: any): PatchedLabeledDataRequest {
    return PatchedLabeledDataRequestFromJSONTyped(json, false);
}

export function PatchedLabeledDataRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedLabeledDataRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'version': !exists(json, 'version') ? undefined : json['version'],
        'tags': !exists(json, 'tags') ? undefined : ((json['tags'] as Array<any>).map(LabeledImageRequestFromJSON)),
        'shapes': !exists(json, 'shapes') ? undefined : ((json['shapes'] as Array<any>).map(LabeledShapeRequestFromJSON)),
        'tracks': !exists(json, 'tracks') ? undefined : ((json['tracks'] as Array<any>).map(LabeledTrackRequestFromJSON)),
    };
}

export function PatchedLabeledDataRequestToJSON(value?: PatchedLabeledDataRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'version': value.version,
        'tags': value.tags === undefined ? undefined : ((value.tags as Array<any>).map(LabeledImageRequestToJSON)),
        'shapes': value.shapes === undefined ? undefined : ((value.shapes as Array<any>).map(LabeledShapeRequestToJSON)),
        'tracks': value.tracks === undefined ? undefined : ((value.tracks as Array<any>).map(LabeledTrackRequestToJSON)),
    };
}

