import { useAuth0 } from "@auth0/auth0-react";
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useEffect, useState } from "react";
import { Organization, OrganizationApi } from "../api";
import CreateOrganization from "../Components/CreateOrganization";
import ApiConfig from "../Components/ApiConfig";
import EditOrganization from "../Components/EditOrganization";
import DeleteOrganization from "../Components/DeleteOrganization";
import Loading from "../Components/Loading";
import { useNavigate } from "react-router-dom";


function Organizations() {
    const { isLoading, getAccessTokenSilently } = useAuth0();
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const navigate = useNavigate();
    const [isLoadingData, setIsLoadingData] = useState(true)
    const getAllOrganizations = async () => {
        try {
            const token = await getAccessTokenSilently();
            const header = { headers: { Authorization: `Bearer ${token}` } };
            const res = await new OrganizationApi(ApiConfig())
                .getAllOrganizations(header)

            const data = res;
            setOrganizations(data);
            setIsLoadingData(false);
        } catch (error) {
            navigate("/")
            console.log(error);
        }
    }

    useEffect(() => {
        getAllOrganizations();
    }, [getAccessTokenSilently]);

    if (isLoading || isLoadingData) {
        return (Loading())
    }

    return (
        <div className="organizations">
            <h1>Organizations</h1>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', m: 2 }}>
                <CreateOrganization setOrganizations={setOrganizations} organizations={organizations} />
            </Box>
            <TableContainer component={Paper}>
                <Table aria-label="account-table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Organization Name</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {organizations.map((organization) =>
                            <TableRow
                                key={organization.organizationId}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">{organization.name}
                                </TableCell>
                                <TableCell>{organization.description}
                                </TableCell>
                                <TableCell align="right" padding='none'>
                                    <EditOrganization getAllOrganizations={getAllOrganizations} setOrganization={setOrganizations} organization={organization} />
                                    <DeleteOrganization getAllOrganizations={getAllOrganizations} organization={organization} />
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );

}
export default Organizations