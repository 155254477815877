import { useAuth0 } from '@auth0/auth0-react';
import { Alert } from '@mui/material';
import React, { useEffect, useState } from 'react'
import Loading from '../Components/Loading';


function Profile() {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [userRole, setUserRole] = useState<string>('');
  useEffect(() => {
    if (user && user["https://aimaps.k8s.oniq.solutions/roles"].length) {
      setUserRole(user["https://aimaps.k8s.oniq.solutions/roles"][0]);
    }
  }, [user])
  if (isLoading) {
    return (Loading())
  }
  if(!user){
    return (
      <Alert severity="warning">Logged out</Alert>
    )
  }

  return (
    <div className="profile">
      {userRole.length ? <></>:
        <Alert severity="warning">You have not been assigned any role or organization, ask an admin or superuser to add your account</Alert>
      }
      <h1>Profile</h1>
      {
        user !== undefined && isAuthenticated && (
          <div>

            <img src={user.picture} alt={user.name} />
            <h2>{user.name}</h2>
            <p>{user.email}</p>
            {userRole.length ? <p>Role : {userRole}</p> : <></>}


          </div>
        )
      }
    </div>
  );
}

export default Profile