import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { DataApi, DataPoint, Location, DataUploadRequest } from "../api";
import ApiConfig from "../Components/ApiConfig";
import { useParams } from 'react-router-dom'
import "leaflet/dist/leaflet.css";
import Webcam from "react-webcam";
import React from "react";
import { Alert, Button, Container } from "@mui/material";
import Loading from "../Components/Loading";
import { useInterval } from "usehooks-ts";
const videoConstraints = {
	facingMode: "environment",
	width: { min: 480 },
	height: { min: 720 },
};

function WebCamUpload() {
	const { isLoading, getAccessTokenSilently } = useAuth0();
	const { id } = useParams()
	const [isUploading, setIsUploading] = useState(false)
	const webcamRef = useRef<Webcam>(null);
	const [location, setLocation] = useState<Location>()
	const [dataPoints, setDataPoints] = useState<DataPoint[]>([{}])

	const createDataPoint = async (imageSrc: string) => {
		const requestOptions = {
			headers: {
				Authorization: `Bearer ${await getAccessTokenSilently()}`,
				Accept: 'application/json', 'Content-Type': 'application/json'
			},
		}
		const requestBody = {
			projectId: id!,
			dataUploadRequest: {
				location: location as Location,
				confirmedCategory: "string",
				image: {
					data: JSON.stringify(imageSrc).split(",")[1].slice(0, -1),
					fileType: 'jpg'
				}
			}
		}

		try {
			const response = await new DataApi(ApiConfig()).createDataPoint(requestBody, requestOptions)
			return response as DataPoint;
		} catch (error) {
			throw error;
		}
	}

	const capture = useCallback(
		async () => {
			if (webcamRef.current) {
				setIsUploading(true)

				const imageSrc = webcamRef.current.getScreenshot();
				//const loc = await getLocationPromise();
				try {
					const point = await createDataPoint(imageSrc as string)
					const dataPointsCopy = [point, ...dataPoints];
					setDataPoints(dataPointsCopy)
				} catch (error) {
					console.log(error)
				}
				setIsUploading(false)

			}
		},
		[webcamRef, dataPoints, location]
	);
	
	const toBase64 = (file: Blob) => new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = reject;
	});

	const createDataPointOnInput = async (files: FileList) =>  {
		setIsUploading(true)
		try {
			let imageSrc = await toBase64(files[0])
			const point = await createDataPoint(imageSrc as string)
			const dataPointsCopy = [point, ...dataPoints];
			setDataPoints(dataPointsCopy)
		} catch (error) {
			console.log(error)
		}
		setIsUploading(false)
	}

	useInterval(() => {
		console.log(location)
	}, 2500)

	useEffect(() => {
		//console.log(navigator.geolocation.watchPosition(position => console.log(position.coords)))
		const listener = navigator.geolocation.watchPosition(position => {
			setLocation({ latitude: position.coords.latitude, longitude: position.coords.longitude } as Location)
			//console.log(position.coords)
		});

		return () => navigator.geolocation.clearWatch(listener)
	}, [])


	if (isLoading) {
		return (Loading())
	}
	return (
		<Container maxWidth="sm">
			<br></br>
			{/*!isUploading ? <>
				<Webcam
					audio={false}
					width={'100%'}
					height={'100%'}
					ref={webcamRef}
					screenshotFormat="image/jpeg"
					videoConstraints={videoConstraints}
				//forceScreenshotSourceSize={true}
				/>
				<Button onClick={capture} disabled={!location}>Capture photo</Button>
			</> : <Loading />
	*/}
	{!isUploading ? <>

		<input type="file" accept="image/jpeg, image/png" id="file-upload-input" onChange={(e) => createDataPointOnInput(e.target.files as FileList)} />
			<label id="file-upload-label" htmlFor="file-upload-input">
				<div>
					<p>Take a picture</p>
				</div>
			</label>
	</> : <Loading />}

			{dataPoints.filter(d => d.createdDate).map(d => (
				<Alert sx={{ m: 1 }} severity="success" key={d.storagePath}>
					Taken at: {d.createdDate?.toLocaleTimeString()} <br />
					Label : {d.inferredCategory ? d.inferredCategory : "Unlabelled"} <br />
					Probablity: {d.inferenceScore ? d.inferenceScore : "Unkown"}
				</Alert>
			))}

		</Container>
	);

} export default WebCamUpload 