/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Attribute } from './Attribute';
import {
    AttributeFromJSON,
    AttributeFromJSONTyped,
    AttributeToJSON,
} from './Attribute';

/**
 * 
 * @export
 * @interface Sublabel
 */
export interface Sublabel {
    /**
     * 
     * @type {number}
     * @memberof Sublabel
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Sublabel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Sublabel
     */
    color?: string;
    /**
     * 
     * @type {Array<Attribute>}
     * @memberof Sublabel
     */
    attributes?: Array<Attribute>;
    /**
     * 
     * @type {string}
     * @memberof Sublabel
     */
    type?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Sublabel
     */
    hasParent?: boolean;
}

/**
 * Check if a given object implements the Sublabel interface.
 */
export function instanceOfSublabel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function SublabelFromJSON(json: any): Sublabel {
    return SublabelFromJSONTyped(json, false);
}

export function SublabelFromJSONTyped(json: any, ignoreDiscriminator: boolean): Sublabel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'attributes': !exists(json, 'attributes') ? undefined : ((json['attributes'] as Array<any>).map(AttributeFromJSON)),
        'type': !exists(json, 'type') ? undefined : json['type'],
        'hasParent': !exists(json, 'has_parent') ? undefined : json['has_parent'],
    };
}

export function SublabelToJSON(value?: Sublabel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'color': value.color,
        'attributes': value.attributes === undefined ? undefined : ((value.attributes as Array<any>).map(AttributeToJSON)),
        'type': value.type,
        'has_parent': value.hasParent,
    };
}

