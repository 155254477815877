/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DataPoint } from './DataPoint';
import {
    DataPointFromJSON,
    DataPointFromJSONTyped,
    DataPointToJSON,
} from './DataPoint';

/**
 * 
 * @export
 * @interface DataPoints
 */
export interface DataPoints {
    /**
     * 
     * @type {number}
     * @memberof DataPoints
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof DataPoints
     */
    totalPages?: number;
    /**
     * 
     * @type {Array<DataPoint>}
     * @memberof DataPoints
     */
    dataPoints?: Array<DataPoint>;
}

/**
 * Check if a given object implements the DataPoints interface.
 */
export function instanceOfDataPoints(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DataPointsFromJSON(json: any): DataPoints {
    return DataPointsFromJSONTyped(json, false);
}

export function DataPointsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataPoints {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalElements': !exists(json, 'totalElements') ? undefined : json['totalElements'],
        'totalPages': !exists(json, 'totalPages') ? undefined : json['totalPages'],
        'dataPoints': !exists(json, 'dataPoints') ? undefined : ((json['dataPoints'] as Array<any>).map(DataPointFromJSON)),
    };
}

export function DataPointsToJSON(value?: DataPoints | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalElements': value.totalElements,
        'totalPages': value.totalPages,
        'dataPoints': value.dataPoints === undefined ? undefined : ((value.dataPoints as Array<any>).map(DataPointToJSON)),
    };
}

