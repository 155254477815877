/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InputTypeEnum } from './InputTypeEnum';
import {
    InputTypeEnumFromJSON,
    InputTypeEnumFromJSONTyped,
    InputTypeEnumToJSON,
} from './InputTypeEnum';

/**
 * 
 * @export
 * @interface AttributeRequest
 */
export interface AttributeRequest {
    /**
     * 
     * @type {string}
     * @memberof AttributeRequest
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof AttributeRequest
     */
    mutable: boolean;
    /**
     * 
     * @type {InputTypeEnum}
     * @memberof AttributeRequest
     */
    inputType: InputTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AttributeRequest
     */
    defaultValue: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AttributeRequest
     */
    values: Array<string>;
}

/**
 * Check if a given object implements the AttributeRequest interface.
 */
export function instanceOfAttributeRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "mutable" in value;
    isInstance = isInstance && "inputType" in value;
    isInstance = isInstance && "defaultValue" in value;
    isInstance = isInstance && "values" in value;

    return isInstance;
}

export function AttributeRequestFromJSON(json: any): AttributeRequest {
    return AttributeRequestFromJSONTyped(json, false);
}

export function AttributeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttributeRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'mutable': json['mutable'],
        'inputType': InputTypeEnumFromJSON(json['input_type']),
        'defaultValue': json['default_value'],
        'values': json['values'],
    };
}

export function AttributeRequestToJSON(value?: AttributeRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'mutable': value.mutable,
        'input_type': InputTypeEnumToJSON(value.inputType),
        'default_value': value.defaultValue,
        'values': value.values,
    };
}

