/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CredentialsTypeEnum = {
    KeySecretKeyPair: 'KEY_SECRET_KEY_PAIR',
    AccountNameTokenPair: 'ACCOUNT_NAME_TOKEN_PAIR',
    KeyFilePath: 'KEY_FILE_PATH',
    AnonymousAccess: 'ANONYMOUS_ACCESS'
} as const;
export type CredentialsTypeEnum = typeof CredentialsTypeEnum[keyof typeof CredentialsTypeEnum];


export function CredentialsTypeEnumFromJSON(json: any): CredentialsTypeEnum {
    return CredentialsTypeEnumFromJSONTyped(json, false);
}

export function CredentialsTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): CredentialsTypeEnum {
    return json as CredentialsTypeEnum;
}

export function CredentialsTypeEnumToJSON(value?: CredentialsTypeEnum | null): any {
    return value as any;
}

