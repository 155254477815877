import { useAuth0 } from '@auth0/auth0-react';
import React, { useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useEffect } from 'react';
import { Account, AccountApi } from '../api';
import CreateAccount from '../Components/CreateAccount';
import { Box, Tooltip } from '@mui/material';
import ApiConfig from '../Components/ApiConfig';
import EditAccount from '../Components/EditAccount';
import DeleteAccount from '../Components/DeleteAccount';
import Loading from '../Components/Loading';
import { useNavigate } from 'react-router-dom';
import { SortableTable, simpleComparator } from '../Components/SortableTable';

function Accounts() {
  const { isLoading, getAccessTokenSilently } = useAuth0();
  const [accounts, setAccounts] = useState<Account[]>([]);
  const navigate = useNavigate();
  const [isLoadingData, setIsLoadingData] = useState(true)
  
  const getAllAccounts = async () => {
    try {
      const token = await getAccessTokenSilently();
      const header = { headers: { Authorization: `Bearer ${token}` } };
      const res = await new AccountApi(ApiConfig()).getAllAccounts(header)
          const data = res; 
          setAccounts(data);
          setIsLoadingData(false);
    } catch (error) {
      navigate("/");
      console.log(error);
    }
  }

  

  useEffect(() => {
    getAllAccounts();
  }, [getAccessTokenSilently]);

  if (isLoading) {
    return (Loading())
  }

  return (
    <div className="accounts">
      <h1>Accounts</h1>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', m: 2 }}>
        <CreateAccount setAccounts={setAccounts} accounts={accounts} />
      </Box>
      <TableContainer component={Paper}>
        <SortableTable tableProps={{'aria-label': "account-table"}}
        	columns={[
        		{ key: 'u', header: "Username", comparator: simpleComparator },
        		{ key: 'm', header: "Email", comparator: simpleComparator },
        		{ key: 'r', header: "Role", comparator: simpleComparator },
        		{ key: 'o', header: "Organization", comparator: simpleComparator },
        		{ key: "btns", header: <></> },
        	]}
        	rows={accounts.map(account => { return {
        		props: () => { return {
        			key: account.auth0Id,
        			sx: { '&:last-child td, &:last-child th': { border: 0 } },
        			hover: true,
        		} },
        		cells: {
        			'u': {
        				props: () => { return {
        					component: "th",
        					scope: "row",
        				}; },
        				v: account.name,
        			},
        			'm': account.email,
        			'r': {
        				props: () => { return {
        					align: "left",
        				}; },
        				v: account.role,
        			},
        			'o': account.organization?.name,
        			'btns': {
        				props: () => { return {
        					align: 'right',
        					padding: 'none',
        				}; },
        				c: () => <>
        					<EditAccount getAllAccounts={getAllAccounts} setAccount={setAccounts} account={account} />
        					<DeleteAccount getAllAccounts={getAllAccounts} account={account} />
        				</>
        			},
        		},
        	};})}
        />
      </TableContainer>
    </div>
  );
}

export default Accounts
