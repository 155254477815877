import { useAuth0 } from '@auth0/auth0-react';
import { Button, MenuItem, Modal, TextField, Typography, IconButton, Tooltip } from '@mui/material';
import { Box, Stack } from '@mui/system';
import React, { useEffect, useState } from 'react'
import { Account, AccountApi, Organization, OrganizationApi } from '../api';
import ApiConfig from './ApiConfig';
import EditIcon from '@mui/icons-material/Edit';
import ModalStyle from './ModalStyle';
import ErrorComp from './ErrorComp';
import { LoadingButton } from '@mui/lab';


function EditAccount(props: any) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => { setOpen(true); refresh(); };
    const handleClose = () => { setOpen(false); refresh(); };
    const { getAccessTokenSilently, user } = useAuth0();
    const [userRole, setUserRole] = useState<string>('user');
    const [orgs, setOrgs] = useState<Organization[]>([]);
    const [role, setRole] = React.useState('');
    const [organization, setOrganization] = useState<string>('');
    const [account, setAccount] = useState<Account>(props.account);
    const [displayError, setDisplayError] = useState({ display: 'none' })
    const [errorMessage, setErrorMessage] = useState('')
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        if (account.organization && account.organization.organizationId) {
            setOrganization(account.organization.organizationId)
        }
        
    }, [account])

    const roles = [
        {
          value: 'administrator',
          label: 'Administrator'
        },
        {
          value: 'superuser',
          label: 'Superuser'
        },
        {
          value: 'traininguser',
          label: 'Traininguser'
        },
        {
          value: 'user',
          label: 'User'
        },
      ];

    const refresh = () => {
        setAccount(props.account)
        setDisplayError({ display: 'none' })
        setLoading(false)
    }
    useEffect(() => {
        if (user && user["https://aimaps.k8s.oniq.solutions/roles"].length) {
            setUserRole(user["https://aimaps.k8s.oniq.solutions/roles"][0]);
        }
    }, [user])

    useEffect(() => {
        const getAllOrganizations = async () => {
            try {
                const token = await getAccessTokenSilently();
                const header = { headers: { Authorization: `Bearer ${token}` } };
                
                new OrganizationApi(ApiConfig())
                    .getAllOrganizations(header)
                    .then((res) => {
                        const data = res; setOrgs(
                            data.map((organization: any) => {
                                return organization;
                            })
                        );
                        
                    });
            } catch (error) {
                
            }
        }
        getAllOrganizations();
    }, [getAccessTokenSilently]);

    const handleAccountEdit = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        setAccount({
            ...account,
            [event.target.name]: newValue
        });
    }

    const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRole(event.target.value);
        setAccount({
            ...account,
            role: event.target.value
        });
    };

    const handleOrganizationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const organization = orgs.find(o => o.organizationId === event.target.value)
        if (organization) {
            setOrganization(event.target.value);
            setAccount({
                ...account,
                organization: organization
            });
        }
    };

    const editAccount = async (account: Account) => {
        const editAccountById = async () => {
            try {
                const token = await getAccessTokenSilently();
                const header = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: 'application/json', 'Content-Type': 'application/json'
                    }
                }

                if (account.auth0Id) {
                    const data = await new AccountApi(ApiConfig()).updateAccountById({id: account.auth0Id, account: account}, header);
                    props.getAllAccounts()
                    return true
                }
            } catch (error: any) {
                setErrorMessage(error.response.data.message)
                setDisplayError({ display: 'block' })
                setLoading(false)
                return false
            }
        }
        await setLoading(true)
        const success = await editAccountById();
        if (success) {
            handleClose();
        }
        
    }


    return (
        <>
            <Tooltip title="Edit account">
                <IconButton onClick={handleOpen}>
                <EditIcon />
            </IconButton>
            </Tooltip>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="Signup"
            >
                <Box sx={ModalStyle}>
                    <Stack spacing={2}>
                        <Typography id="modal-createaccount" variant="h6" component="h2">
                            Edit Account
                        </Typography>
                        <TextField id="name-field" name='name' value={account.name} label="Name" onChange={handleAccountEdit} variant="outlined" />
                        <TextField id="email-field" name='email' disabled={true} value={account.email} label="Email" onChange={handleAccountEdit} variant="outlined" />
                        <TextField id="role-field" name='role' disabled={!(userRole === 'administrator')} select value={account.role} onChange={handleRoleChange} label="Role" variant="outlined">
                            {roles.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                        {<TextField id="org-field" name='organization' disabled={!(userRole === 'administrator')} select value={organization} onChange={handleOrganizationChange} label="Organization" variant="outlined" >
                            {orgs.map((org) => (
                                <MenuItem key={org.organizationId} value={org.organizationId}>
                                    {org.name}
                                </MenuItem>
                            ))}
                        </TextField>}
                        <Box sx={displayError}>
                            <ErrorComp error={errorMessage} />
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', }}>
                            <Button variant="contained" onClick={handleClose}>Cancel</Button>
                            <LoadingButton loading={loading} id="edit-button" onClick={event => editAccount(account)} variant="contained">Submit</LoadingButton>
                        </Box>
                    </Stack>
                </Box>
            </Modal>
        </>
    )

} export default EditAccount