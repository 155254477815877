/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Module } from './Module';
import {
    ModuleFromJSON,
    ModuleFromJSONTyped,
    ModuleToJSON,
} from './Module';
import type { Organization } from './Organization';
import {
    OrganizationFromJSON,
    OrganizationFromJSONTyped,
    OrganizationToJSON,
} from './Organization';

/**
 * 
 * @export
 * @interface License
 */
export interface License {
    /**
     * 
     * @type {string}
     * @memberof License
     */
    id?: string;
    /**
     * 
     * @type {Organization}
     * @memberof License
     */
    organization?: Organization;
    /**
     * 
     * @type {Module}
     * @memberof License
     */
    module?: Module;
    /**
     * 
     * @type {Array<string>}
     * @memberof License
     */
    labels?: Array<string>;
    /**
     * version of module, use latest if empty / 0
     * @type {number}
     * @memberof License
     */
    version?: number;
    /**
     * If false it is a request for a licence, if true it is approved
     * @type {boolean}
     * @memberof License
     */
    approved?: boolean;
}

/**
 * Check if a given object implements the License interface.
 */
export function instanceOfLicense(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LicenseFromJSON(json: any): License {
    return LicenseFromJSONTyped(json, false);
}

export function LicenseFromJSONTyped(json: any, ignoreDiscriminator: boolean): License {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'organization': !exists(json, 'organization') ? undefined : OrganizationFromJSON(json['organization']),
        'module': !exists(json, 'module') ? undefined : ModuleFromJSON(json['module']),
        'labels': !exists(json, 'labels') ? undefined : json['labels'],
        'version': !exists(json, 'version') ? undefined : json['version'],
        'approved': !exists(json, 'approved') ? undefined : json['approved'],
    };
}

export function LicenseToJSON(value?: License | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'organization': OrganizationToJSON(value.organization),
        'module': ModuleToJSON(value.module),
        'labels': value.labels,
        'version': value.version,
        'approved': value.approved,
    };
}

