import { Button, MenuItem, Modal, TextField, Typography } from '@mui/material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { useAuth0 } from '@auth0/auth0-react';
import ApiConfig from './ApiConfig';
import { Box, Stack } from '@mui/system';
import React, { useEffect, useState } from 'react'
import { Module, Organization, Project, ProjectApi, OrganizationApi, ModuleApi } from '../api';
import ModalStyle from './ModalStyle';
import ErrorComp from './ErrorComp';
import { LoadingButton } from '@mui/lab';

function CreateProject(props: any) {
	const { getAccessTokenSilently } = useAuth0();
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => { setOpen(true); refresh(); };
	const handleClose = () => { setOpen(false); refresh(); };
	const [project, setProject] = useState<Project>({})
	const [organization, setOrganization] = useState<string>();
	const [orgs, setOrgs] = useState<Organization[]>([]);
	const [displayError, setDisplayError] = useState({ display: 'none' })
	const [errorMessage, setErrorMessage] = useState('')
	const [loading, setLoading] = useState(false);

	const refresh = () => {
		setProject({ ...{}, organization: orgs.length ? orgs[0] : undefined })
		setOrganization(undefined)
		setDisplayError({ display: 'none' })
		setLoading(false)
	}

	useEffect(() => {
		const getAllOrganizations = async () => {
			try {
				setLoading(true)
				const token = await getAccessTokenSilently();
				const header = { headers: { Authorization: `Bearer ${token}`, accept: 'application/json' } };
				new OrganizationApi(ApiConfig())
					.getAllOrganizations(header)
					.then((res) => {
						const data = res;
						setOrgs(data);
						if (data.length === 1) {

							setOrganization((data[0] && data[0].organizationId) ? data[0].organizationId : '');
							setProject({
								...project,
								organization: data[0]
							});
						}
					});
			} catch (error) {

			}
		}
		getAllOrganizations();
	}, [getAccessTokenSilently]);


	const createProject = async () => {
		try {
			setLoading(true)
			const token = await getAccessTokenSilently();
			const header = {
				headers: {
					Authorization: `Bearer ${token}`,
					Accept: 'application/json', 'Content-Type': 'application/json'
				}
			};
			const data = (await new ProjectApi(ApiConfig()).addProjectForOrganization({ project: project }, header));
			props.setProjects([...props.projects, data])
			handleClose()
		} catch (error: any) {
			error.response.json().then(
				(error: any) => {
					setErrorMessage(error.message)
				}
			)
			setDisplayError({ display: 'block' })
			setLoading(false)
		}
	}

	const handleProjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		setProject({
			...project,
			[event.target.name]: newValue
		});
	}

	const handleModuleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		let module = props.modules.find((module: Module) => module.name === event.target.value)
		setProject({
			...project,
			module: module
		})
	};

	const handleOrganizationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const organization = orgs.find(o => o.organizationId === event.target.value)
		if (organization) {
			setOrganization(event.target.value);
			setProject({
				...project,
				organization: organization
			});
		}
	};

	return (
		<div>
			<Button className='createOrganization-button' onClick={handleOpen} variant="contained" disabled={props.disabledButtons}>
				Create<AddCircleOutlineOutlinedIcon />
			</Button>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="createOrg"
			>
				<Box sx={ModalStyle}>
					<Stack spacing={2}>
						<Typography id="modal-signup" variant="h6" component="h2">
							{props.user && (props.user["https://aimaps.k8s.oniq.solutions/roles"][0] === "user" || props.user["https://aimaps.k8s.oniq.solutions/roles"][0] === "superuser") ?
								<>New Project</>
								:
								<>New Collection</>
							}
						</Typography>
						<TextField id="projectname-field" name='name' onChange={handleProjectChange} label="Name" variant="outlined" />
						<TextField id="projectdesc-field" name='description' onChange={handleProjectChange} label="Description" variant="outlined" />
						<TextField id="projectmodule-field" name='module' select defaultValue={""} onChange={handleModuleChange} label="Module" variant="outlined">
							{props.modules.map((module: Module) => (
								<MenuItem disabled={module.modelVersion == 10} key={module.name} value={module.name}>
									{module.name}
								</MenuItem>
							))}
						</TextField>
						{orgs.length < 2?<></>:<TextField id="org-field" disabled={orgs.length < 2} name='organization' select value={organization} onChange={handleOrganizationChange} label="Organization" variant="outlined" >
							{orgs.map((org) => (
								<MenuItem key={org.organizationId} value={org.organizationId}>
									{org.name}
								</MenuItem>
							))}
						</TextField>}
						<Box sx={displayError}>
							<ErrorComp error={errorMessage} />
						</Box>
						<Box sx={{ display: 'flex', justifyContent: 'space-between', }}>
							<Button variant="contained" onClick={handleClose}>Cancel</Button>
							<LoadingButton loading={loading} id="create-button" onClick={createProject} variant="contained">Submit</LoadingButton>
						</Box>
					</Stack>
				</Box>
			</Modal>
		</div>
	)
} export default CreateProject