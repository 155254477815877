/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Image } from './Image';
import {
    ImageFromJSON,
    ImageFromJSONTyped,
    ImageToJSON,
} from './Image';
import type { Location } from './Location';
import {
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
} from './Location';

/**
 * 
 * @export
 * @interface DataUploadRequest
 */
export interface DataUploadRequest {
    /**
     * 
     * @type {Location}
     * @memberof DataUploadRequest
     */
    location?: Location;
    /**
     * Human infered category, ie for training purpose
     * @type {string}
     * @memberof DataUploadRequest
     */
    confirmedCategory?: string;
    /**
     * 
     * @type {Image}
     * @memberof DataUploadRequest
     */
    image?: Image;
    /**
     * 
     * @type {Date}
     * @memberof DataUploadRequest
     */
    createdDate?: Date;
}

/**
 * Check if a given object implements the DataUploadRequest interface.
 */
export function instanceOfDataUploadRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DataUploadRequestFromJSON(json: any): DataUploadRequest {
    return DataUploadRequestFromJSONTyped(json, false);
}

export function DataUploadRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataUploadRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'location': !exists(json, 'location') ? undefined : LocationFromJSON(json['location']),
        'confirmedCategory': !exists(json, 'confirmedCategory') ? undefined : json['confirmedCategory'],
        'image': !exists(json, 'image') ? undefined : ImageFromJSON(json['image']),
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
    };
}

export function DataUploadRequestToJSON(value?: DataUploadRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'location': LocationToJSON(value.location),
        'confirmedCategory': value.confirmedCategory,
        'image': ImageToJSON(value.image),
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
    };
}

