import { Button, Modal, Typography } from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useState } from "react";
import { Box, Stack } from "@mui/system";
import ModalStyle from "./ModalStyle";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { TrainingApi } from "../api";
import ApiConfig from "./ApiConfig";
import { useNavigate } from 'react-router-dom';

function DeleteCVATProject() {
    const { getAccessTokenSilently } = useAuth0();
    const [projectId, setProjectId] = useState<number>(0);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
		if (id) {
           const idNumber: number = +id;
			setProjectId(idNumber);
		}
	}, [id]);


    const deleteCvatProject = (projectId: number) => {
        const destroyCvatProject = async () => {
            try {
                const token = await getAccessTokenSilently();
                const header = { headers: { Authorization: `Bearer ${token}` } }
                const res = new TrainingApi(ApiConfig()).projectsDestroy({trainingProjectId: projectId}, header);
            } catch (error) {
                console.log(error)
            }
        }
        destroyCvatProject();
        navigate('/training');
    }

    return (
        <>
            <Button className='deleteOrganization-button' color="error" onClick={handleOpen} variant="contained">
                Delete Project<DeleteIcon />
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="deleteAccount"
            >
                <Box sx={ModalStyle}>
                    <Stack spacing={2}>
                        <Typography id="modal-deleteAccount" variant="h6" component="h2">
                            Are you sure you want to remove project #{id}
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', }}>
                            <Button variant="contained" onClick={handleClose}>Cancel</Button>
                            <Button color="error" id="delete-button" onClick={event => deleteCvatProject(projectId)} variant="contained">Remove</Button>
                        </Box>
                    </Stack>
                </Box>
            </Modal>
        </>
    )

} export default DeleteCVATProject