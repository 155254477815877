/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const StorageMethod = {
    Cache: 'cache',
    FileSystem: 'file_system'
} as const;
export type StorageMethod = typeof StorageMethod[keyof typeof StorageMethod];


export function StorageMethodFromJSON(json: any): StorageMethod {
    return StorageMethodFromJSONTyped(json, false);
}

export function StorageMethodFromJSONTyped(json: any, ignoreDiscriminator: boolean): StorageMethod {
    return json as StorageMethod;
}

export function StorageMethodToJSON(value?: StorageMethod | null): any {
    return value as any;
}

