import { useAuth0 } from "@auth0/auth0-react";
import { Box, IconButton, List, ListItem, ListItemText, ListSubheader, Modal, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { License, LicenseApi, Module, ModuleApi, Organization, OrganizationApi } from "../api";
import ApiConfig from "../Components/ApiConfig";
import Loading from "../Components/Loading";
import { useNavigate } from "react-router-dom";
import DeleteLicense from "../Components/DeleteLicense";
import { ViewModuleOutlined } from "@mui/icons-material";
import ModalStyle from "../Components/ModalStyle";
import CreateLicense from "../Components/CreateLicense";
import EditLicense from "../Components/EditLicense";
import ApproveLicense from "../Components/ApproveLicense";

function Licenses() {
	const { isLoading, getAccessTokenSilently, user } = useAuth0();
	const [modules, setModules] = useState<Module[]>([]);
	const [organizations, setOrganizations] = useState<Organization[]>([])
	const [licenses, setLicenses] = useState<License[]>([])
	const [licenseRequests, setLicenseRequests] = useState<License[]>([])

	const navigate = useNavigate();
	const [isLoadingData, setIsLoadingData] = useState(true)
	const [open, setOpen] = useState<string|void>('');
	const handleOpen = (id?: string) => setOpen(id);
	const handleClose = () => setOpen('');

	const getAllModules = async () => {
		const token = await getAccessTokenSilently();
		const header = { headers: { Authorization: `Bearer ${token}` } };

		try {
			await new ModuleApi(ApiConfig())
				.getAllModules(header)
				.then((res) => {
					const data = res;
					setModules(data);
					setIsLoadingData(false)
				});
		} catch (error) {
			navigate("/");
			console.log(error);
		}
	}

	const getAllOrganizations = async () => {
		const token = await getAccessTokenSilently();
		const header = { headers: { Authorization: `Bearer ${token}` } };
		try {
			setOrganizations(await new OrganizationApi(ApiConfig()).getAllOrganizations(header))
		} catch (error) {
			console.error(error)
		}
	}

	const getAllLicenses = async () => {
		const token = await getAccessTokenSilently();
		const header = { headers: { Authorization: `Bearer ${token}` } };

		try {
			const fetched = await new LicenseApi(ApiConfig()).getLicenses(header);
			setLicenses(fetched.filter(l=>l.approved))
			setLicenseRequests(fetched.filter(l=>false===l.approved))
		} catch (error) {
			console.error(error)
		}
	}

	useEffect(() => {
		getAllModules();
		getAllOrganizations();
		getAllLicenses();
	}, []);

	if (isLoading || isLoadingData) {
		return (Loading())
	}

	const approveLicense = async (license: License)=> {
		const token = await getAccessTokenSilently();
		const header = {
			headers: {
				Authorization: `Bearer ${token}`,
				Accept: 'application/json', 'Content-Type': 'application/json'
			}
		};
		if(license.id){
			await new LicenseApi(ApiConfig()).approveLicenseRequest({license: license }, header);
			getAllLicenses();
		}
	}

	return (
		<div className="modules">
			<h1>Licenses</h1>
			<Box sx={{ display: 'flex', justifyContent: 'flex-end', m: 2 }}>
				<CreateLicense user={user} getAllLicenses={getAllLicenses} modules={modules} organizations={organizations} />
			</Box>
			<TableContainer component={Paper}>
				<Table aria-label="account-table">
					<TableHead>
						<TableRow>
							<TableCell>Organization</TableCell>
							<TableCell>Licensed Module</TableCell>
							<TableCell></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						
						{licenses.map((license) =>
							<TableRow
								key={license.id}
								
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
								hover
							>
								<TableCell>
									{license.organization?.name}
								</TableCell>
								<TableCell component="th" scope="row">{license.module?.name}
								</TableCell>
				
								<TableCell align="right" padding='none'>
									<>
									<Tooltip title="View labels">
										<IconButton onClick={()=>handleOpen(license.id)}>
											<ViewModuleOutlined />
										</IconButton>
									</Tooltip>
										<Modal
											open={open===license.id}
											onClose={handleClose}
											aria-labelledby="view"
										>
											<Box sx={ModalStyle}>
												<Stack spacing={2}>
													<Typography id="modal-signup" variant="h6" component="h2">
														{license.module?.name}
													</Typography>
													<List
														sx={{
															width: '100%',
															maxWidth: 360,
															bgcolor: 'background.paper',
															position: 'relative',
															overflow: 'auto',
															maxHeight: 300,
															'& ul': { padding: 0 },
														}}
														subheader={<li />}
													>
														<ListSubheader>Labels accessed</ListSubheader>
														{license.labels?.map((label: string) => (
															<li key={`section-${label}`}>
																<ul>
																	<ListItem>
																		<ListItemText primary={label} />
																	</ListItem>
																</ul>
															</li>
														))}
													</List>
												</Stack>
											</Box>
										</Modal>
									</>
									<EditLicense license={license} getAllLicenses={getAllLicenses} />
									<DeleteLicense license={license} getAllLicenses={getAllLicenses} user={user} />
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<h1>License Requests</h1>
			<TableContainer component={Paper}>
				<Table aria-label="account-table">
					<TableHead>
						<TableRow>
							<TableCell>Organization</TableCell>
							<TableCell>Licensed Module</TableCell>
							<TableCell></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{licenseRequests.map((licenseRequest) =>
							<TableRow
								key={licenseRequest.id}
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
								hover
							>
								<TableCell>
									{licenseRequest.organization?.name}
								</TableCell>
								<TableCell component="th" scope="row">{licenseRequest.module?.name}
								</TableCell>
								<TableCell align="right" padding='none'>
									<ApproveLicense approveLicense={approveLicense} licenseRequest={licenseRequest}></ApproveLicense>
									<DeleteLicense license={licenseRequest} getAllLicenses={getAllLicenses} user={user} />
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);

}
export default Licenses;
