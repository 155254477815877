/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PatchedLabelRequest } from './PatchedLabelRequest';
import {
    PatchedLabelRequestFromJSON,
    PatchedLabelRequestFromJSONTyped,
    PatchedLabelRequestToJSON,
} from './PatchedLabelRequest';
import type { StorageRequest } from './StorageRequest';
import {
    StorageRequestFromJSON,
    StorageRequestFromJSONTyped,
    StorageRequestToJSON,
} from './StorageRequest';

/**
 * Adds support for write once fields to serializers.
 * To use it, specify a list of fields as `write_once_fields` on the
 * serializer's Meta:
 * ```
 * class Meta:
 *     model = SomeModel
 *     fields = '__all__'
 *     write_once_fields = ('collection', )
 * ```
 * Now the fields in `write_once_fields` can be set during POST (create),
 * but cannot be changed afterwards via PUT or PATCH (update).
 * Inspired by http://stackoverflow.com/a/37487134/627411.
 * @export
 * @interface TaskWriteRequest
 */
export interface TaskWriteRequest {
    /**
     * 
     * @type {string}
     * @memberof TaskWriteRequest
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof TaskWriteRequest
     */
    projectId?: number;
    /**
     * 
     * @type {number}
     * @memberof TaskWriteRequest
     */
    ownerId?: number;
    /**
     * 
     * @type {number}
     * @memberof TaskWriteRequest
     */
    assigneeId?: number;
    /**
     * 
     * @type {string}
     * @memberof TaskWriteRequest
     */
    bugTracker?: string;
    /**
     * 
     * @type {number}
     * @memberof TaskWriteRequest
     */
    overlap?: number;
    /**
     * 
     * @type {number}
     * @memberof TaskWriteRequest
     */
    segmentSize?: number;
    /**
     * 
     * @type {Array<PatchedLabelRequest>}
     * @memberof TaskWriteRequest
     */
    labels?: Array<PatchedLabelRequest>;
    /**
     * 
     * @type {string}
     * @memberof TaskWriteRequest
     */
    subset?: string;
    /**
     * 
     * @type {StorageRequest}
     * @memberof TaskWriteRequest
     */
    targetStorage?: StorageRequest;
    /**
     * 
     * @type {StorageRequest}
     * @memberof TaskWriteRequest
     */
    sourceStorage?: StorageRequest;
}

/**
 * Check if a given object implements the TaskWriteRequest interface.
 */
export function instanceOfTaskWriteRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function TaskWriteRequestFromJSON(json: any): TaskWriteRequest {
    return TaskWriteRequestFromJSONTyped(json, false);
}

export function TaskWriteRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskWriteRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'projectId': !exists(json, 'project_id') ? undefined : json['project_id'],
        'ownerId': !exists(json, 'owner_id') ? undefined : json['owner_id'],
        'assigneeId': !exists(json, 'assignee_id') ? undefined : json['assignee_id'],
        'bugTracker': !exists(json, 'bug_tracker') ? undefined : json['bug_tracker'],
        'overlap': !exists(json, 'overlap') ? undefined : json['overlap'],
        'segmentSize': !exists(json, 'segment_size') ? undefined : json['segment_size'],
        'labels': !exists(json, 'labels') ? undefined : ((json['labels'] as Array<any>).map(PatchedLabelRequestFromJSON)),
        'subset': !exists(json, 'subset') ? undefined : json['subset'],
        'targetStorage': !exists(json, 'target_storage') ? undefined : StorageRequestFromJSON(json['target_storage']),
        'sourceStorage': !exists(json, 'source_storage') ? undefined : StorageRequestFromJSON(json['source_storage']),
    };
}

export function TaskWriteRequestToJSON(value?: TaskWriteRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'project_id': value.projectId,
        'owner_id': value.ownerId,
        'assignee_id': value.assigneeId,
        'bug_tracker': value.bugTracker,
        'overlap': value.overlap,
        'segment_size': value.segmentSize,
        'labels': value.labels === undefined ? undefined : ((value.labels as Array<any>).map(PatchedLabelRequestToJSON)),
        'subset': value.subset,
        'target_storage': StorageRequestToJSON(value.targetStorage),
        'source_storage': StorageRequestToJSON(value.sourceStorage),
    };
}

