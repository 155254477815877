/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserAgreementRequest } from './UserAgreementRequest';
import {
    UserAgreementRequestFromJSON,
    UserAgreementRequestFromJSONTyped,
    UserAgreementRequestToJSON,
} from './UserAgreementRequest';

/**
 * 
 * @export
 * @interface RestrictedRegisterRequest
 */
export interface RestrictedRegisterRequest {
    /**
     * 
     * @type {string}
     * @memberof RestrictedRegisterRequest
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof RestrictedRegisterRequest
     */
    password1: string;
    /**
     * 
     * @type {string}
     * @memberof RestrictedRegisterRequest
     */
    password2: string;
    /**
     * 
     * @type {string}
     * @memberof RestrictedRegisterRequest
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof RestrictedRegisterRequest
     */
    lastName?: string;
    /**
     * 
     * @type {Array<UserAgreementRequest>}
     * @memberof RestrictedRegisterRequest
     */
    confirmations?: Array<UserAgreementRequest>;
}

/**
 * Check if a given object implements the RestrictedRegisterRequest interface.
 */
export function instanceOfRestrictedRegisterRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "password1" in value;
    isInstance = isInstance && "password2" in value;

    return isInstance;
}

export function RestrictedRegisterRequestFromJSON(json: any): RestrictedRegisterRequest {
    return RestrictedRegisterRequestFromJSONTyped(json, false);
}

export function RestrictedRegisterRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RestrictedRegisterRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'username': json['username'],
        'password1': json['password1'],
        'password2': json['password2'],
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'confirmations': !exists(json, 'confirmations') ? undefined : ((json['confirmations'] as Array<any>).map(UserAgreementRequestFromJSON)),
    };
}

export function RestrictedRegisterRequestToJSON(value?: RestrictedRegisterRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
        'password1': value.password1,
        'password2': value.password2,
        'first_name': value.firstName,
        'last_name': value.lastName,
        'confirmations': value.confirmations === undefined ? undefined : ((value.confirmations as Array<any>).map(UserAgreementRequestToJSON)),
    };
}

