/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BasicUser } from './BasicUser';
import {
    BasicUserFromJSON,
    BasicUserFromJSONTyped,
    BasicUserToJSON,
} from './BasicUser';
import type { JobStatus } from './JobStatus';
import {
    JobStatusFromJSON,
    JobStatusFromJSONTyped,
    JobStatusToJSON,
} from './JobStatus';
import type { Label } from './Label';
import {
    LabelFromJSON,
    LabelFromJSONTyped,
    LabelToJSON,
} from './Label';
import type { Storage } from './Storage';
import {
    StorageFromJSON,
    StorageFromJSONTyped,
    StorageToJSON,
} from './Storage';

/**
 * 
 * @export
 * @interface ProjectRead
 */
export interface ProjectRead {
    /**
     * 
     * @type {string}
     * @memberof ProjectRead
     */
    readonly url?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectRead
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectRead
     */
    name: string;
    /**
     * 
     * @type {Array<Label>}
     * @memberof ProjectRead
     */
    readonly labels?: Array<Label>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProjectRead
     */
    readonly tasks?: Array<number>;
    /**
     * 
     * @type {BasicUser}
     * @memberof ProjectRead
     */
    owner?: BasicUser;
    /**
     * 
     * @type {BasicUser}
     * @memberof ProjectRead
     */
    assignee?: BasicUser;
    /**
     * 
     * @type {string}
     * @memberof ProjectRead
     */
    bugTracker?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectRead
     */
    readonly taskSubsets?: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof ProjectRead
     */
    readonly createdDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProjectRead
     */
    readonly updatedDate?: Date;
    /**
     * 
     * @type {JobStatus}
     * @memberof ProjectRead
     */
    status?: JobStatus;
    /**
     * 
     * @type {string}
     * @memberof ProjectRead
     */
    dimension?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectRead
     */
    organization?: number;
    /**
     * 
     * @type {Storage}
     * @memberof ProjectRead
     */
    targetStorage?: Storage;
    /**
     * 
     * @type {Storage}
     * @memberof ProjectRead
     */
    sourceStorage?: Storage;
}

/**
 * Check if a given object implements the ProjectRead interface.
 */
export function instanceOfProjectRead(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function ProjectReadFromJSON(json: any): ProjectRead {
    return ProjectReadFromJSONTyped(json, false);
}

export function ProjectReadFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectRead {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'url': !exists(json, 'url') ? undefined : json['url'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'labels': !exists(json, 'labels') ? undefined : ((json['labels'] as Array<any>).map(LabelFromJSON)),
        'tasks': !exists(json, 'tasks') ? undefined : json['tasks'],
        'owner': !exists(json, 'owner') ? undefined : BasicUserFromJSON(json['owner']),
        'assignee': !exists(json, 'assignee') ? undefined : BasicUserFromJSON(json['assignee']),
        'bugTracker': !exists(json, 'bug_tracker') ? undefined : json['bug_tracker'],
        'taskSubsets': !exists(json, 'task_subsets') ? undefined : json['task_subsets'],
        'createdDate': !exists(json, 'created_date') ? undefined : (new Date(json['created_date'])),
        'updatedDate': !exists(json, 'updated_date') ? undefined : (new Date(json['updated_date'])),
        'status': !exists(json, 'status') ? undefined : JobStatusFromJSON(json['status']),
        'dimension': !exists(json, 'dimension') ? undefined : json['dimension'],
        'organization': !exists(json, 'organization') ? undefined : json['organization'],
        'targetStorage': !exists(json, 'target_storage') ? undefined : StorageFromJSON(json['target_storage']),
        'sourceStorage': !exists(json, 'source_storage') ? undefined : StorageFromJSON(json['source_storage']),
    };
}

export function ProjectReadToJSON(value?: ProjectRead | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'owner': BasicUserToJSON(value.owner),
        'assignee': BasicUserToJSON(value.assignee),
        'bug_tracker': value.bugTracker,
        'status': JobStatusToJSON(value.status),
        'dimension': value.dimension,
        'organization': value.organization,
        'target_storage': StorageToJSON(value.targetStorage),
        'source_storage': StorageToJSON(value.sourceStorage),
    };
}

