/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Organization } from './Organization';
import {
    OrganizationFromJSON,
    OrganizationFromJSONTyped,
    OrganizationToJSON,
} from './Organization';

/**
 * 
 * @export
 * @interface Account
 */
export interface Account {
    /**
     * Date and time of account creation
     * @type {Date}
     * @memberof Account
     */
    createdAt?: Date;
    /**
     * email of the account
     * @type {string}
     * @memberof Account
     */
    email?: string;
    /**
     * name of the account
     * @type {string}
     * @memberof Account
     */
    name?: string;
    /**
     * nickname of the account
     * @type {string}
     * @memberof Account
     */
    nickName?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    auth0Id?: string;
    /**
     * Role of the account
     * @type {string}
     * @memberof Account
     */
    role?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    password?: string;
    /**
     * 
     * @type {Organization}
     * @memberof Account
     */
    organization?: Organization;
}

/**
 * Check if a given object implements the Account interface.
 */
export function instanceOfAccount(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AccountFromJSON(json: any): Account {
    return AccountFromJSONTyped(json, false);
}

export function AccountFromJSONTyped(json: any, ignoreDiscriminator: boolean): Account {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'nickName': !exists(json, 'nickName') ? undefined : json['nickName'],
        'auth0Id': !exists(json, 'auth0Id') ? undefined : json['auth0Id'],
        'role': !exists(json, 'role') ? undefined : json['role'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'organization': !exists(json, 'organization') ? undefined : OrganizationFromJSON(json['organization']),
    };
}

export function AccountToJSON(value?: Account | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'email': value.email,
        'name': value.name,
        'nickName': value.nickName,
        'auth0Id': value.auth0Id,
        'role': value.role,
        'password': value.password,
        'organization': OrganizationToJSON(value.organization),
    };
}

