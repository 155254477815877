/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AttributeValRequest } from './AttributeValRequest';
import {
    AttributeValRequestFromJSON,
    AttributeValRequestFromJSONTyped,
    AttributeValRequestToJSON,
} from './AttributeValRequest';
import type { ShapeType } from './ShapeType';
import {
    ShapeTypeFromJSON,
    ShapeTypeFromJSONTyped,
    ShapeTypeToJSON,
} from './ShapeType';

/**
 * 
 * @export
 * @interface SubLabeledShapeRequest
 */
export interface SubLabeledShapeRequest {
    /**
     * 
     * @type {ShapeType}
     * @memberof SubLabeledShapeRequest
     */
    type: ShapeType;
    /**
     * 
     * @type {boolean}
     * @memberof SubLabeledShapeRequest
     */
    occluded?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SubLabeledShapeRequest
     */
    outside?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SubLabeledShapeRequest
     */
    zOrder?: number;
    /**
     * 
     * @type {number}
     * @memberof SubLabeledShapeRequest
     */
    rotation?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof SubLabeledShapeRequest
     */
    points?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof SubLabeledShapeRequest
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof SubLabeledShapeRequest
     */
    frame: number;
    /**
     * 
     * @type {number}
     * @memberof SubLabeledShapeRequest
     */
    labelId: number;
    /**
     * 
     * @type {number}
     * @memberof SubLabeledShapeRequest
     */
    group?: number;
    /**
     * 
     * @type {string}
     * @memberof SubLabeledShapeRequest
     */
    source?: string;
    /**
     * 
     * @type {Array<AttributeValRequest>}
     * @memberof SubLabeledShapeRequest
     */
    attributes?: Array<AttributeValRequest>;
}

/**
 * Check if a given object implements the SubLabeledShapeRequest interface.
 */
export function instanceOfSubLabeledShapeRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "frame" in value;
    isInstance = isInstance && "labelId" in value;

    return isInstance;
}

export function SubLabeledShapeRequestFromJSON(json: any): SubLabeledShapeRequest {
    return SubLabeledShapeRequestFromJSONTyped(json, false);
}

export function SubLabeledShapeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubLabeledShapeRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': ShapeTypeFromJSON(json['type']),
        'occluded': !exists(json, 'occluded') ? undefined : json['occluded'],
        'outside': !exists(json, 'outside') ? undefined : json['outside'],
        'zOrder': !exists(json, 'z_order') ? undefined : json['z_order'],
        'rotation': !exists(json, 'rotation') ? undefined : json['rotation'],
        'points': !exists(json, 'points') ? undefined : json['points'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'frame': json['frame'],
        'labelId': json['label_id'],
        'group': !exists(json, 'group') ? undefined : json['group'],
        'source': !exists(json, 'source') ? undefined : json['source'],
        'attributes': !exists(json, 'attributes') ? undefined : ((json['attributes'] as Array<any>).map(AttributeValRequestFromJSON)),
    };
}

export function SubLabeledShapeRequestToJSON(value?: SubLabeledShapeRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': ShapeTypeToJSON(value.type),
        'occluded': value.occluded,
        'outside': value.outside,
        'z_order': value.zOrder,
        'rotation': value.rotation,
        'points': value.points,
        'id': value.id,
        'frame': value.frame,
        'label_id': value.labelId,
        'group': value.group,
        'source': value.source,
        'attributes': value.attributes === undefined ? undefined : ((value.attributes as Array<any>).map(AttributeValRequestToJSON)),
    };
}

