import { TableRow, TableCell, Table, TableHead, TableBody, Box, Collapse, Typography, IconButton, Tooltip, TablePagination, CircularProgress, CircularProgressProps } from '@mui/material'
import React, { useState } from 'react'
import AddProjectToCVATProject from './AddProjectToCVATProject';
import DeleteProject from './DeleteProject';
import EditProject from './EditProject';
import AddIcon from '@mui/icons-material/Add';
import PageviewIcon from '@mui/icons-material/Pageview';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { Module, PaginatedPolymorphicProjectList, PaginatedTaskReadList, Project, ProjectRead, SummaryItem, TrainingApi } from '../api';
import ApiConfig from './ApiConfig';
import { Check } from '@mui/icons-material';
import ExportProject from './ExportProject';
import { SortableRow } from '../Components/SortableTable';
import ProjectDetails from './ProjectDetails';
const noLicenceTooltip = (name:string)=>{
    const NO_LICENCE = `You do not have a licence for this projects module (${name}). Please contact your administrator`
    return NO_LICENCE
}
function CircularProgressWithLabel(
	props: CircularProgressProps & { tasks: SummaryItem[], images: number },
) {
	const predictTasks= props.tasks? props.tasks.find((t:SummaryItem)=>t.label === "INFERENCE"):false;
	const thumbTasks=props.tasks?props.tasks.find((t:SummaryItem)=>t.label === "THUMBNAIL"):false;
	const [open, setOpen] = React.useState(false);
    
	return (
		<Tooltip title={(!predictTasks&&!thumbTasks)?'Tasks completed': `Tasks in progress \r
		${predictTasks?`Predict: ${predictTasks.count}`:''}
		${thumbTasks?`Thumbnail: ${thumbTasks.count}`:''}
		 `}>
			<Box sx={{ position: 'relative', display: 'inline-flex' }}>
			{(predictTasks||thumbTasks)?<CircularProgress variant={'indeterminate'} {...props} />:<CircularProgress variant={'determinate'} {...props} />}
			<Box
				sx={{
					 top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					position: 'absolute',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center', 
				}}
			>

				<Typography
					variant="caption"
					component="div"
					color="text.secondary"
				>{props.images}</Typography>
			</Box>

		</Box>
		</Tooltip>
	);
}

function projectRow(props: any): SortableRow {
	const getAccessTokenSilently = props.getAccessTokenSilently;
	const navigate = props.navigate;
	const addProject = props.addProject;

	const routeChange = (projectId: number) => {
		let path = `/training/project/${projectId}`;
		navigate(path);
	}

	const header = async () => {
		return { headers: { Authorization: `Bearer ${await getAccessTokenSilently()}` } };
	}

	return {
		useHooks: () => {
			const [open, setOpen] = React.useState(false);
			const [showDelete, setShowDelete] = React.useState(false);
			const [page, setPage] = useState<number>(0);
			const [rowsPerPage, setRowsPerPage] = useState<number>(5);
			const [tasks, setTasks] = useState<PaginatedTaskReadList>({} as PaginatedTaskReadList)

			const getTrainingTasksByName = async () => {
				try {
					const response = await new TrainingApi(ApiConfig()).tasksList({
						filter: `{ "and": [{ "==": [{ "var": "name" }, "${props.project.name}"] }] }`
					}, header)
					await new TrainingApi(ApiConfig()).tasksList({
						filter: `{ "and": [{ "==": [{ "var": "name" }, "${props.project.name}"] }] }`,
						page: 1,
						pageSize: response.count
					}, header)
						.then(res => {
							var filteredResults = res.results!.filter(task => {
								return task.targetStorage?.cloudStorageId === props.project.cloudStorageId;
							})
							setTasks({
								count: filteredResults.length,
								results: filteredResults
							})
						})
				} catch (error) {
					console.error(error)
				}
			}

			const handleOpen = async () => {
				if (props.user && props.user["https://aimaps.k8s.oniq.solutions/roles"][0] !== "user") {
					if (open) {
						setOpen(!open)
					} else {
						await getTrainingTasksByName()
							.then(() => {
								setOpen(!open)
							})
					}
				}
			}

			const handleChangePage = async (event: any, newPage: number) => {
				setPage(newPage)
				await getTrainingTasksByName();
			}


			const findTrainingProjectName = (trainingProjects: PaginatedPolymorphicProjectList, projectId: number | undefined) => {
				var filteredTrainingProjects = trainingProjects.results?.find(project => {
					return project.id === projectId
				});

				return filteredTrainingProjects as ProjectRead
			}

			return {
				open: open, setOpen: setOpen,
				page: page, setPage: setPage,
				rowsPerPage: rowsPerPage, setRowsPerPage: setRowsPerPage,
				tasks: tasks, setTasks: setTasks,
				getTrainingTasksByName: getTrainingTasksByName,
				handleOpen: handleOpen,
				handleChangePage: handleChangePage,
				findTrainingProjectName: findTrainingProjectName,
			}
		},
		props: (hooks: any) => {
			return {
				sx: { '& > *': { borderBottom: 'unset' } },
				hover: true,
			}
		},
		cells: {
			'p': {
				props: (hooks: any) => {
					return {
						component: "th",
						scope: "row",
						onClick: hooks.handleOpen,
					};
				},
				v: props.project.name,
			},
			'o': {
				props: (hooks: any) => {
					return {
						onClick: hooks.handleOpen,
					};
				},
				v: props.project.organization.name,
			},
			'd': {
				props: (hooks: any) => {
					return {
						onClick: hooks.handleOpen,
					};
				},
				v: props.project.description,
			},
			'm': {
				props: (hooks: any) => {
					return {
						onClick: hooks.handleOpen,
					};
				},
				v: props.project.module ? props.project.module.name : '',
			},
			/*{ {props.project.heldImages} */
			'i': {
				props: (hooks: any) => {
					return {
						onClick: hooks.handleOpen,
					};
				},
				c: () => {
					return <CircularProgressWithLabel tasks={props.project.taskSummary as SummaryItem[]} images={props.project.heldImages}></CircularProgressWithLabel> 
				},
				v: props.project.heldImages,
			},
			'up': {
				props: (hooks: any) => {
					return {
						onClick: hooks.handleOpen,
					};
				},
				c: () => <Tooltip title={'User project'}>
					{props.project.creatorRole !== "administrator" ? <Check></Check> : <></>}
				</Tooltip>
			},
			'btns': {
				props: () => {
					return {
						align: 'right',
						padding: 'none',
					};
				},
				c: () => {
					let showDelete = true;
					//const [showDelete, setShowDelete] = React.useState(true);
					return <>
						<ExportProject project={props.project} disabledButtons={false} />
						{(props.role==='traininguser' || props.role=='administrator') ? <AddProjectToCVATProject project={props.project} trainingProjects={props.trainingProjects} />:<></>}
						<EditProject user={props.user} getAllProjects={props.getAllProjects} setProjects={props.setProjects} modules={props.modules} organizations={props.organizations} project={props.project} disabledButtons={false} />
						<Tooltip title="View Images">
							<IconButton  onClick={() => navigate(`/projects/data/${props.project.projectId}`)} >
								<PageviewIcon />
							</IconButton>
						</Tooltip>
                        <Tooltip title={props.uploadDisabled?noLicenceTooltip(props.moduleName): "Upload Files"}>
                            <span>
                                <IconButton disabled={props.uploadDisabled} onClick={() => navigate(`/projects/uploadImage/${props.project.projectId}`)} >
                                    <AddIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title={props.uploadDisabled?noLicenceTooltip(props.moduleName):"Take Pictures"}>
                            <span>
                                <IconButton disabled={props.uploadDisabled} onClick={() => navigate(`/projects/uploadImage/webcam/${props.project.projectId}`)}>
                                    <AddAPhotoIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
						<DeleteProject getAllProjects={props.getAllProjects} project={props.project} disabledButtons={false} />
					</>
				}
			},
		},
		subrow: (hooks: any) => ProjectDetails(hooks, props, routeChange, props.project as Project, props.modules as Module[], addProject), 
			
	}
}

export default projectRow
