import { useAuth0 } from "@auth0/auth0-react";
import { ChangeEvent, useEffect, useState } from "react";
import { DataApi, DataPoint } from "../api";
import ApiConfig from "../Components/ApiConfig";
import { useParams } from 'react-router-dom'
import { MapContainer, TileLayer } from 'react-leaflet'
import { LatLng } from "leaflet";
import "leaflet/dist/leaflet.css";
import MapLayers from "../Components/MapLayers";
import Loading from "../Components/Loading";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import DataPointsPagination from "../Components/DataPointsPagination";

function DataPointsPage() {
	const { isLoading, getAccessTokenSilently } = useAuth0();
	const [dataPoints, setDataPoints] = useState<DataPoint[]>([]);
	const { projectId } = useParams()
	const [center, setCenter] = useState<LatLng>()
	const [viewMap, setViewMap] = useState(false);

	useEffect(() => {
		getAllProjects()
		getLocation()
	}, []);

	const getAllProjects = async () => {
		try {
			const token = await getAccessTokenSilently();
			const header = { headers: { Authorization: `Bearer ${token}` } };
			new DataApi(ApiConfig())
				.getDataPointsForProject({ projectId: projectId as string }, header)
				.then((res) => setDataPoints(res.dataPoints as DataPoint[]))
		} catch (error) {
			console.log(error);
		}
	}

	const getLocation = () => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition((position) => {
				setCenter({ lat: position.coords.latitude, lng: position.coords.longitude } as LatLng)
			});
		} else {
			console.log("Geolocation is not supported by this browser.");
			setCenter({ lat: 60.1282, lng: 18.6435 } as LatLng)
		}
	}

	if (isLoading) {
		return (Loading())
	} else if (dataPoints.length === 0) {
		return (<h1></h1>)
	}

	const handleViewMap = async (e: ChangeEvent<HTMLInputElement>) => {
		setViewMap(e.target.checked)
	}

	return (
		<>
			<FormGroup>
				<FormControlLabel control={<Switch onChange={(event) => { handleViewMap(event) }} />} label="View Map" disabled={!center} />
			</FormGroup>
			{viewMap ?
				<MapContainer center={center} zoom={10} >
					<TileLayer
						attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
						url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
					<MapLayers dataPoints={dataPoints} projectId={projectId as string} color={'#4742a6'} />
				</MapContainer>
				:
				<DataPointsPagination projectId={projectId} />
			}</>

	)
} export default DataPointsPage 