function ModalStyle(){

    return(
          {
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            bgcolor: '#e4f0e2',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
        }
    )
    
} export default ModalStyle