/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SimpleJob } from './SimpleJob';
import {
    SimpleJobFromJSON,
    SimpleJobFromJSONTyped,
    SimpleJobToJSON,
} from './SimpleJob';

/**
 * 
 * @export
 * @interface Segment
 */
export interface Segment {
    /**
     * 
     * @type {number}
     * @memberof Segment
     */
    readonly startFrame?: number;
    /**
     * 
     * @type {number}
     * @memberof Segment
     */
    readonly stopFrame?: number;
    /**
     * 
     * @type {Array<SimpleJob>}
     * @memberof Segment
     */
    jobs: Array<SimpleJob>;
}

/**
 * Check if a given object implements the Segment interface.
 */
export function instanceOfSegment(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "jobs" in value;

    return isInstance;
}

export function SegmentFromJSON(json: any): Segment {
    return SegmentFromJSONTyped(json, false);
}

export function SegmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Segment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startFrame': !exists(json, 'start_frame') ? undefined : json['start_frame'],
        'stopFrame': !exists(json, 'stop_frame') ? undefined : json['stop_frame'],
        'jobs': ((json['jobs'] as Array<any>).map(SimpleJobFromJSON)),
    };
}

export function SegmentToJSON(value?: Segment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'jobs': ((value.jobs as Array<any>).map(SimpleJobToJSON)),
    };
}

