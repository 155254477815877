import { Autocomplete, Button, Modal, TextField, Typography, IconButton, Tooltip } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { useAuth0 } from '@auth0/auth0-react';
import React, { useState } from 'react'
import ApiConfig from './ApiConfig';
import EditIcon from '@mui/icons-material/Edit';
import { License, LicenseApi } from '../api';
import ModalStyle from './ModalStyle';
import ErrorComp from './ErrorComp';
import { LoadingButton } from '@mui/lab';
import { Help } from '@mui/icons-material';

function EditLicense(props: any) {
	const [open, setOpen] = React.useState(false);
	const { getAccessTokenSilently } = useAuth0();
	const handleOpen = () => { setOpen(true); refresh(); };
	const handleClose = () => { setOpen(false); refresh(); };
	const [license, setLicense] = useState<License>(props.license);
	const [displayError, setDisplayError] = useState({ display: 'none' });
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	const refresh = () => {
		setLicense(props.license);
		setDisplayError({ display: 'none' });
		setLoading(false)
	}

	const handleLicenseChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		setLicense({
			...license,
			[event.target.name]: newValue
		});
	}

	// FIXME: Partially copied from CreateLicense.tsx. Refactor at some point.
	const handleVersionChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const regex = /^[0-9\b]+$/;
		if (e.target.value === "" || regex.test(e.target.value)) {
			const newValue = parseInt(e.target.value);
			setLicense({
				...license,
				version: newValue
			});
		}
	};

	const handleLabelsChange = (newValue: string[]) => {
		setLicense({
			...license,
			labels: newValue
		});
	};

	const editLicense = async (license: License) => {
		if (license.labels === undefined || license.labels.length === 0) {
			setErrorMessage('You need at least one label')
			setDisplayError({ display: 'block' })
			return;
		} else if (license.version == undefined || license.version === 0) {
			setErrorMessage('Invalid version')
			setDisplayError({ display: 'block' })
			return;
		}
		const editLicenseById = async () => {
			try {
				const token = await getAccessTokenSilently();
				const header = {
					headers: {
						Authorization: `Bearer ${token}`,
						Accept: 'application/json', 'Content-Type': 'application/json'
					}
				}

				if (license.id) {
					const updateLicenseRequest = { licenseId: license.id, license: license }
					const data = await new LicenseApi(ApiConfig()).updateLicense(updateLicenseRequest, header)
					props.getAllLicenses()
					return true
				}
			} catch (error: any) {
				setErrorMessage(error.response.data.message)
				setDisplayError({ display: 'block' })
				setLoading(false)
				return false
			}
		}
		await setLoading(true)
		const success = await editLicenseById();
		if (success) {
			handleClose();
		}

	}

	return (
		<>
			<Tooltip title={"Edit license"}>
				<IconButton onClick={handleOpen}>
					<EditIcon />
				</IconButton>
			</Tooltip>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="editLicense"
			>
				<Box sx={ModalStyle}>
					<Stack spacing={2}>
						<Typography id="modal-editLicense" variant="h6" component="h2">
							Edit License
						</Typography>
						<Box sx={{
							display: 'grid',
							gridAutoColumns: '1fr',
							gap: 1,
						}}>
							<Box sx={{ gridRow: '1', gridColumn: 'span 5' }}>
								<TextField id="licenseorg-field" name='Organization' value={license.organization?.name} label="Organization" variant="outlined" disabled />
							</Box>
							<Box sx={{ gridRow: '1' }}>
								<Tooltip title="Organization change not allowed">
									<IconButton> <Help /> </IconButton>
								</Tooltip>
							</Box>
						</Box>
						<Box sx={{
							display: 'grid',
							gridAutoColumns: '1fr',
							gap: 1,
						}}>
							<Box sx={{ gridRow: '1', gridColumn: 'span 5' }}>
								<TextField id="licensemodule-field" name='Module' value={license.module?.name} label="Module" variant="outlined" disabled />
							</Box>
							<Box sx={{ gridRow: '1' }}>
								<Tooltip title="Module change not allowed">
									<IconButton> <Help /> </IconButton>
								</Tooltip>
							</Box>
						</Box>
{/* 						<TextField id="moduleversion-field" defaultValue={0} type='number' name='modelVersion' label="Module Version" onChange={(e) => handleVersionChange(e)} value={license.version} variant="outlined" />
 */}						<Autocomplete
							multiple
							id="tags-outlined"
							options={license.module?.labelMap || []}
							onChange={(e, newValue, reason) => handleLabelsChange(newValue)}
							getOptionLabel={(label) => label}
							value={license.labels}
							filterSelectedOptions
							renderInput={(params) => (
								<TextField
									{...params}
									label="Labels"
									placeholder="Choose labels"
								/>
							)}
						/>
						<Box sx={displayError}>
							<ErrorComp error={errorMessage} />
						</Box>
						<Box sx={{ display: 'flex', justifyContent: 'space-between', }}>
							<Button variant="contained" onClick={handleClose}>Cancel</Button>
							<LoadingButton loading={loading} id="create-button" onClick={event => editLicense(license)} variant="contained">Submit</LoadingButton>
						</Box>
					</Stack>
				</Box>
			</Modal>
		</>
	)

} export default EditLicense
