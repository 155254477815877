import { useAuth0 } from '@auth0/auth0-react';
import { Button, IconButton, Modal, Tooltip, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import React, { useState } from 'react'
import ApiConfig from './ApiConfig';
import DeleteIcon from '@mui/icons-material/Delete';
import { ProjectApi } from '../api';
import ModalStyle from './ModalStyle';
import { LoadingButton } from '@mui/lab';

function DeleteProject(props: any) {
	const { getAccessTokenSilently } = useAuth0();
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const [open, setOpen] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false)


	const deleteProject = async (projectId: string) => {
		try {
			setIsDeleting(true)
			const token = await getAccessTokenSilently();
			const header = { headers: { Authorization: `Bearer ${token}` } }
			new ProjectApi(ApiConfig())
				.deleteProjectById({ projectId: projectId }, header).then(() => {

					props.getAllProjects();
				}).then(() => setIsDeleting(false))

		} catch (error) {
			console.error(error)
		}
		handleClose()
	}

	return (
		<>
			{isDeleting ?
				<LoadingButton loading={isDeleting} />
				:
				<Tooltip title={props.user && (props.user["https://aimaps.k8s.oniq.solutions/roles"][0] === "user" || props.user["https://aimaps.k8s.oniq.solutions/roles"][0] === "superuser") ? "Delete project" : "Delete collection"}>
					<IconButton onClick={handleOpen} disabled={props.disabledButtons}>
						<DeleteIcon color={props.disabledButtons ? undefined : 'error'}/>
					</IconButton>
				</Tooltip>
			}


			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="deleteProject"
			>
				<Box sx={ModalStyle}>
					<Stack spacing={2}>
						<Typography id="modal-deleteProject" variant="h6" component="h2">
							Are you sure you want to remove: {props.project.name}
						</Typography>
						<Box sx={{ display: 'flex', justifyContent: 'space-between', }}>
							<Button variant="contained" onClick={handleClose}>Cancel</Button>
							<Button color="error" id="delete-button" onClick={event => deleteProject(props.project.projectId)} variant="contained">Remove</Button>
						</Box>
					</Stack>
				</Box>
			</Modal>
		</>
	)

} export default DeleteProject
