import { LoadingButton } from '@mui/lab'
import { Autocomplete, Button, MenuItem, Modal, TextField, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system';
import React, { useEffect, useState } from 'react'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import ModalStyle from './ModalStyle';
import ErrorComp from './ErrorComp';
import { useAuth0 } from '@auth0/auth0-react';
import { LicenseApi, Module, Organization } from '../api';
import ApiConfig from './ApiConfig';
import ModuleMenuItem from './ModuleMenuItem';

function CreateLicense(props: { user: any, getAllLicenses: any, modules: Module[], organizations: Organization[] }) {

	const { getAccessTokenSilently } = useAuth0();
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => { setOpen(true); refresh(); };
	const handleClose = () => { setOpen(false); refresh(); };
	const [displayError, setDisplayError] = useState({ display: 'none' })
	const [errorMessage, setErrorMessage] = useState('')
	const [loading, setLoading] = useState(false);
	const [selectedOrganization, setSelectedOrganization] = useState<Organization>()
	const [selectedModule, setSelectedModule] = useState<Module>()
	const [selectedVersion, setSelectedVersion] = useState<number>(0)
	const [selectedLabels, setSelectedLabels] = useState<string[]>([])

	const refresh = () => {
		setSelectedOrganization(undefined)
		setSelectedModule(undefined)
		setSelectedVersion(0)
		setSelectedLabels([])
		setDisplayError({ display: 'none' })
		setLoading(false)
	}

	const createLicense = async () => {
		if (selectedModule == undefined) {
			setErrorMessage('Please choose a module')
			setDisplayError({ display: 'block' })
		} else if (selectedLabels.length === 0) {
			setErrorMessage('You need at least one label')
			setDisplayError({ display: 'block' })
		} else if (selectedVersion == undefined || selectedVersion === 0) {
			setErrorMessage('Invalid version')
			setDisplayError({ display: 'block' })
		} else if (selectedOrganization == undefined) {
			setErrorMessage('Please choose an organization')
			setDisplayError({ display: 'block' })
		} else {
			try {
				setLoading(true)
				const token = await getAccessTokenSilently();
				const header = {
					headers: {
						Authorization: `Bearer ${token}`,
						Accept: 'application/json', 'Content-Type': 'application/json'
					}
				};

				const licenseRequest = {
					module: selectedModule,
					organization: selectedOrganization,
					labels: selectedLabels,
					version: selectedVersion
				}
				await new LicenseApi(ApiConfig()).createLicenses({ license: licenseRequest }, header).then(async () => await props.getAllLicenses())
				setLoading(false)
				handleClose()

			} catch (error: any) {
				setErrorMessage('Something went wrong')
				setDisplayError({ display: 'block' })

				setLoading(false)
				console.log(error)
			}
		}
	}

	useEffect(() => {
		setSelectedVersion(selectedModule?.modelVersion || 0)
	}, [selectedModule])

	const handleOrganizationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const organization = props.organizations.find((org: Organization) => org.organizationId === event.target.value)
		if (organization) {
			setSelectedOrganization(organization)
		}
	}

	const handleModuleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.value)
		const module = props.modules.find((module: Module) => module.id === event.target.value)
		if (module) {
			setSelectedModule(module)
		}
	}

	const handleVersionChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const regex = /^[0-9\b]+$/;
		if (e.target.value === "" || regex.test(e.target.value)) {
			setSelectedVersion(parseInt(e.target.value));
		}
	};

	return (
		<div>
			<Button className='addLicense-button' onClick={handleOpen} variant="contained">
				Create<AddCircleOutlineOutlinedIcon />
			</Button>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="addLicense"
			>
				<Box sx={ModalStyle}>
					<Stack spacing={2}>
						<Typography id="modal-signup" variant="h6" component="h2">
							New License
						</Typography>
						<TextField id="licenseorg-field" name='Organization' defaultValue={''} select onChange={handleOrganizationChange} label="Organization" variant="outlined">
							{props.organizations?.map((organization: Organization) => (
								<MenuItem key={organization.organizationId} value={organization.organizationId}>
									{organization.name}
								</MenuItem>
							))}
						</TextField>
						<TextField id="licensemodule-field" name='Module' defaultValue={''} select onChange={handleModuleChange} label="Module" variant="outlined">
							{props.modules?.map((module: Module) => (
                                ModuleMenuItem({module: module, props:{key:module.id,value:module.id}}  )
							))}
						</TextField>
				{/* 		<TextField id="moduleversion-field"
							type='number'
							name='modelVersion' label="modelVersion"
							disabled={!selectedModule}
							InputProps={{ inputProps: { min: 0, max: selectedModule?.modelVersion } }}
							onChange={(e) => handleVersionChange(e)} value={selectedVersion} variant="outlined" /> */}
						<Autocomplete
							multiple
							id="tags-outlined"
							disabled={!selectedModule}
							options={selectedModule?.labelMap || []}
							onChange={(e, newValue, reason) => setSelectedLabels(newValue)}
							getOptionLabel={(label) => label}
							defaultValue={[]}
							filterSelectedOptions
							renderInput={(params) => (
								<TextField
									{...params}
									label="Labels"
									placeholder="Choose labels"
								/>
							)}
						/>
						<Box sx={displayError}>
							<ErrorComp error={errorMessage} />
						</Box>
						<Box sx={{ display: 'flex', justifyContent: 'space-between', }}>
							<Button variant="contained" onClick={handleClose}>Cancel</Button>
							<LoadingButton loading={loading} id="create-button" onClick={createLicense} variant="contained">Submit</LoadingButton>
						</Box>
					</Stack>
				</Box>
			</Modal>
		</div>
	)
}

export default CreateLicense