import React, { useEffect, useState } from 'react'
import AimapsLogoPlain from "../assets/uggla_logo.png";
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';

interface MenuItemI {
	link: string;
	text: string;
}

function Navbar() {
	const { isAuthenticated, isLoading, user, loginWithRedirect, logout } = useAuth0()
	const navigate = useNavigate()
	const [role, setRole] = useState<string>()
	const [org, setOrg] = useState<string>()
	const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
	const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
	const menuItemsAdministrator: MenuItemI[] = [{ link: '/accounts', text: 'Accounts' }, { link: '/organizations', text: 'Organizations' }, { link: '/collections', text: 'Collections' }, { link: '/modules', text: 'Modules' }, { link: '/training', text: 'Training' }, { link: '/licenses', text: 'Licenses' }]
	const menuItemsSuperUser: MenuItemI[] = [{ link: '/accounts', text: 'Accounts' }, { link: '/collections', text: 'Projects' }, { link: '/modules', text: 'Modules' }]
	const menuItemsTrainingUser: MenuItemI[] = [{ link: '/collections', text: 'Collections' }, { link: '/modules', text: 'Modules' }, { link: '/training', text: 'Training' }]
	const menuItemsUser: MenuItemI[] = [{ link: '/projects', text: 'Projects' }, { link: '/modules', text: 'Modules' }]
	const [menuItems, setMenuItems] = useState<MenuItemI[]>([])
	const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElNav(event.currentTarget);
	};
	const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};
	useEffect(() => {
		if (role === "administrator") {
			setMenuItems(menuItemsAdministrator)
		} else if (role === "superuser") {
			setMenuItems(menuItemsSuperUser)
		} else if (role === "traininguser") {
			setMenuItems(menuItemsTrainingUser)
		} else if (role === "user") {
			setMenuItems(menuItemsUser)
		}
	}, [role, org])


	useEffect(() => {

		if (user && user["https://aimaps.k8s.oniq.solutions/roles"].length) {
			setRole(user["https://aimaps.k8s.oniq.solutions/roles"][0]);
		}
		if (user && user["https://aimaps.k8s.oniq.solutions/organization"]) {
			setOrg(user["https://aimaps.k8s.oniq.solutions/organization"].name);
		}
	}, [isLoading, user])

	if (isLoading) {
		return (<></>)
	}

	return (
		<AppBar position="static" sx={{backgroundColor: "rgb(143, 143, 159)"}}>
			<Container maxWidth="xl">
				<Toolbar disableGutters>
					<Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
						{isAuthenticated ?
							[<IconButton
								size="large"
								key={menuItems.length}
								aria-label="account of current user"
								aria-controls="menu-appbar"
								aria-haspopup="true"
								onClick={handleOpenNavMenu}
								color="inherit"
							>
								<MenuIcon />
							</IconButton>] : []}
						{isAuthenticated && menuItems.length ? <Menu
							id="menu-appbar"
							anchorEl={anchorElNav}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
							keepMounted
							transformOrigin={{
								vertical: 'top',
								horizontal: 'left',
							}}
							open={Boolean(anchorElNav)}
							onClose={handleCloseNavMenu}
							sx={{
								display: { xs: 'block', md: 'none' },
							}}
						>
							{menuItems.map(item => {
								return <MenuItem onClick={handleCloseNavMenu} key={Math.random()} component={Link} to={item.link}>
									{item.text}
								</MenuItem>
							})}

						</Menu> : <></>}
					</Box>
					<Link to="/">
						<img className="aimaps-logo" src={AimapsLogoPlain} alt="logo" />
					</Link>
					<Typography
						variant="h5"
						noWrap
						component="a"
						href=""
						sx={{
							mr: 2,
							display: { xs: 'flex', md: 'none' },
							flexGrow: 1,
							fontFamily: 'monospace',
							fontWeight: 700,
							letterSpacing: '.3rem',
							color: 'inherit',
							textDecoration: 'none',
						}}
					>
					</Typography>
					<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
						{isAuthenticated && menuItems.length ? menuItems.map(i => <NavLink to={i.link} key={Math.random() + i.link} className="link"
						  style={({ isActive }) =>
						  isActive
							? {
								color: '#9ff1fa',
								display: "block",
								height: "100%"
							  }
							: {}
						}
						>
							{i.text}
						</NavLink>) : <></>}
					</Box>

					<Box sx={{ flexGrow: 0 }}>
						<Tooltip title="Open settings">
							<IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
								<Avatar alt="Remy Sharp" src={user?.picture} />
							</IconButton>
						</Tooltip>
						<Menu
							sx={{ mt: '45px' }}
							id="menu-appbar"
							anchorEl={anchorElUser}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							keepMounted
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							open={Boolean(anchorElUser)}
							onClose={handleCloseUserMenu}
						>{isAuthenticated ?
							<div>
								<MenuItem onClick={() => { handleCloseUserMenu(); navigate("/profile") }}>
									<Typography align="center">Profile</Typography>
								</MenuItem>
								<MenuItem onClick={() => { handleCloseUserMenu(); logout({ returnTo: window.location.origin }) }}>
									<Typography align="center">Log out</Typography>
								</MenuItem>
							</div>
							:
							<MenuItem onClick={() => { handleCloseUserMenu(); loginWithRedirect() }}>
								<Typography align="center">Log in</Typography>
							</MenuItem>}
						</Menu>
					</Box>
				</Toolbar>
			</Container>
		</AppBar >
	);
}

export default Navbar
