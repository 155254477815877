import { TableRow, TableCell, Table, TableHead, TableBody, TableSortLabel } from '@mui/material'
import React, { useState } from 'react'

type Order = 'asc' | 'desc';

// Might be defined in some library somewhere, IDK
export const simpleComparator = (a: any, b: any) => {
	if (a < b) return -1;
	if (a > b) return +1;
	return 0;
}

export interface SortableColumn {
	key: string;
	header: any;
	comparator?: (a: any, b: any) => number;
	disabled?: boolean;
}

export interface SortableRow {
	//id: string;
	cells: any;
	subrow?: any;
	props?: any;
	useHooks?: () => any; // Must be start with "use", since it functions as custom React hook
}

const isSimpleType = (v: any) => {
	if (v === null) return true;
	const type = typeof v;
	return type == 'string' || type == 'number';
}

const getCellValue = (row : SortableRow, key: string) => {
	const cells = row.cells;
	if (isSimpleType(cells[key])) return cells[key];
	return cells[key].v;
}

const getCellDisplay = (row : SortableRow, hooks: any, key: string) => {
	const cells = row.cells;
	if (isSimpleType(cells[key])) return cells[key];
	if (cells[key].c) {
		return cells[key].c(hooks);
	} else {
		return cells[key].v;
	}
}

const getCellProps = (row : SortableRow, hooks: any, key: string) => {
	const cell = row.cells[key];
	return (cell && cell.props) ? cell.props(hooks) : null;
}

const getSubrow = (row : SortableRow, hooks: any) => {
	return row.subrow ? row.subrow(hooks) : <></>;
}

const Row = (props: { columns: SortableColumn[], row: SortableRow, }) => {
	const columns = props.columns;
	const row = props.row;
	const hooks = row.useHooks ? row.useHooks() : null;
	const rowProps = row.props ? row.props(hooks) : null;
	return <>
		<TableRow  {...rowProps}>{columns.map((column) => column.disabled ? <></> :
			<TableCell key={"H2" + Math.random()}  {...getCellProps(row, hooks, column.key)}>{getCellDisplay(row, hooks, column.key)}</TableCell>
		)}</TableRow>
		{getSubrow(row, hooks)}
	</>;
}

export function SortableTable(props: {columns: SortableColumn[], rows: SortableRow[], tableProps?: any}) {
	const { columns, rows } = props;
	const [order, setOrder] = useState<Order>('asc');
	const [orderBy, setOrderBy] = useState<string | null>(null);
	const sortHandler = (key: string) => {
		const isAsc = orderBy === key && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(key);
	};

	const comparatorMap = new Map(columns.map(col => [col.key, col.comparator]));
	const sortedRows = React.useMemo(
		() => {
			if (orderBy === null) {
				return rows;
			}
			const comparator = comparatorMap.get(orderBy);
			if (comparator != undefined) {
				return rows.sort(order === 'desc'
					? (a, b) => -comparator(getCellValue(a, orderBy), getCellValue(b, orderBy))
					: (a, b) => +comparator(getCellValue(a, orderBy), getCellValue(b, orderBy)));
			} else {
				return rows;
			}
		},
		[rows, order, orderBy],
	);

	return (<Table {...props.tableProps}>
		<TableHead>
			<TableRow>{columns.map((column) => column.disabled ? <></> :
				<TableCell
					key={column.key}
					sortDirection={orderBy === column.key ? order : false}
				>{
					(column.comparator) ?
						<TableSortLabel
							active={orderBy === column.key}
							direction={orderBy === column.key ? order : 'asc'}
							onClick={(event: React.MouseEvent<unknown>) => {
								sortHandler(column.key);
							}}
						>
							{column.header}
						</TableSortLabel>
					: column.header
				}</TableCell>
			)}</TableRow>
		</TableHead>
		<TableBody>{sortedRows.map((row) =>
			<Row  key={"H"+ Math.random()} columns={columns} row={row}/>
		)}</TableBody>
	</Table>);
}

