/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SummaryItem
 */
export interface SummaryItem {
    /**
     * 
     * @type {string}
     * @memberof SummaryItem
     */
    label?: string;
    /**
     * 
     * @type {number}
     * @memberof SummaryItem
     */
    count?: number;
}

/**
 * Check if a given object implements the SummaryItem interface.
 */
export function instanceOfSummaryItem(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SummaryItemFromJSON(json: any): SummaryItem {
    return SummaryItemFromJSONTyped(json, false);
}

export function SummaryItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): SummaryItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': !exists(json, 'label') ? undefined : json['label'],
        'count': !exists(json, 'count') ? undefined : json['count'],
    };
}

export function SummaryItemToJSON(value?: SummaryItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label': value.label,
        'count': value.count,
    };
}

