import { useAuth0 } from '@auth0/auth0-react';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { InitOverrideFunction, Module, ModuleApi, ProjectRead, TrainingApi, TrainingStatus } from '../api';
import ApiConfig from './ApiConfig';
import ErrorComp from './ErrorComp';
import ModalStyle from './ModalStyle';
interface SaveModelProps {
	trainerStatus: TrainingStatus;
	lastTrainedProject: any;
}
function SaveModel(props: SaveModelProps) {

	const { getAccessTokenSilently } = useAuth0();
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => { setOpen(true); refresh(); };
	const handleClose = () => { setOpen(false); refresh(); };
	const [displayError, setDisplayError] = useState({ display: 'none' })
	const [errorMessage, setErrorMessage] = useState('')
	const [loading, setLoading] = useState(false);


	const refresh = () => {
		setDisplayError({ display: 'none' })
		setLoading(false)
	}

	const getModuleByName = async () => {
		const token = await getAccessTokenSilently();
		const header = { headers: { Authorization: `Bearer ${token}`, accept: 'application/json' } };

		const res = await new ModuleApi(ApiConfig()).getAllModules(header)
		return res.find(module => module.name === props.lastTrainedProject.name.substring(0, props.lastTrainedProject.name.lastIndexOf("-")))
	}


	const updateModuleById = async () => {
		try {
			setLoading(true)
			const module = await getModuleByName()
			const token = await getAccessTokenSilently();
			const header = {
				headers: {
					Authorization: `Bearer ${token}`,
					Accept: 'application/json', 'Content-Type': 'application/json'
				}
			};

			var moduleName = props.lastTrainedProject.name.substring(0, props.lastTrainedProject.name.lastIndexOf("-"));
			var version = props.lastTrainedProject.name.split('-').pop();

			const moduleRequest = {
				name: moduleName,
				path: "/active_models/" + moduleName,
				modelVersion: version,
				inputSize: props.trainerStatus.inputSize,
				modelType: props.trainerStatus.modelType
			}

			if (module?.modelVersion as number > version) {
				moduleRequest.modelVersion = module?.modelVersion;
			}

			await new ModuleApi(ApiConfig()).updateModuleById({ moduleId: module?.id as string, module: moduleRequest }, header);

			setLoading(false)
			handleClose()
		} catch (error: any) {
			setErrorMessage('Something went wrong')
			setDisplayError({ display: 'block' })
			setLoading(false)
			console.log(error)
		}
	}

	return (
		<>
			<Button sx={{
				':hover': {
					bgcolor: 'primary.main', // theme.palette.primary.main
					color: 'white',
				},
			}} onClick={handleOpen}>
				Save as Module
			</Button>

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{"Save module?"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Are you sure you want to save the latest training
						on module: <b>{props.lastTrainedProject.name.substring(0, props.lastTrainedProject.name.lastIndexOf("-"))}</b> and version: <b>{props.lastTrainedProject.name.split('-').pop()}</b>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Box sx={displayError}>
						<ErrorComp error={errorMessage} />
					</Box>
					<Button onClick={handleClose}>Cancel</Button>
					<LoadingButton loading={loading} id="create-button" onClick={updateModuleById} variant="contained">
						Save
					</LoadingButton>
				</DialogActions>
			</Dialog>
		</>
	)
}

export default SaveModel