/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AttributeRequest } from './AttributeRequest';
import {
    AttributeRequestFromJSON,
    AttributeRequestFromJSONTyped,
    AttributeRequestToJSON,
} from './AttributeRequest';
import type { SublabelRequest } from './SublabelRequest';
import {
    SublabelRequestFromJSON,
    SublabelRequestFromJSONTyped,
    SublabelRequestToJSON,
} from './SublabelRequest';

/**
 * 
 * @export
 * @interface PatchedLabelRequest
 */
export interface PatchedLabelRequest {
    /**
     * 
     * @type {number}
     * @memberof PatchedLabelRequest
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PatchedLabelRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedLabelRequest
     */
    color?: string;
    /**
     * 
     * @type {Array<AttributeRequest>}
     * @memberof PatchedLabelRequest
     */
    attributes?: Array<AttributeRequest>;
    /**
     * Delete label if value is true from proper Task/Project object
     * @type {boolean}
     * @memberof PatchedLabelRequest
     */
    deleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatchedLabelRequest
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedLabelRequest
     */
    svg?: string;
    /**
     * 
     * @type {Array<SublabelRequest>}
     * @memberof PatchedLabelRequest
     */
    sublabels?: Array<SublabelRequest>;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedLabelRequest
     */
    hasParent?: boolean;
}

/**
 * Check if a given object implements the PatchedLabelRequest interface.
 */
export function instanceOfPatchedLabelRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PatchedLabelRequestFromJSON(json: any): PatchedLabelRequest {
    return PatchedLabelRequestFromJSONTyped(json, false);
}

export function PatchedLabelRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedLabelRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'attributes': !exists(json, 'attributes') ? undefined : ((json['attributes'] as Array<any>).map(AttributeRequestFromJSON)),
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'svg': !exists(json, 'svg') ? undefined : json['svg'],
        'sublabels': !exists(json, 'sublabels') ? undefined : ((json['sublabels'] as Array<any>).map(SublabelRequestFromJSON)),
        'hasParent': !exists(json, 'has_parent') ? undefined : json['has_parent'],
    };
}

export function PatchedLabelRequestToJSON(value?: PatchedLabelRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'color': value.color,
        'attributes': value.attributes === undefined ? undefined : ((value.attributes as Array<any>).map(AttributeRequestToJSON)),
        'deleted': value.deleted,
        'type': value.type,
        'svg': value.svg,
        'sublabels': value.sublabels === undefined ? undefined : ((value.sublabels as Array<any>).map(SublabelRequestToJSON)),
        'has_parent': value.hasParent,
    };
}

