import { useAuth0 } from "@auth0/auth0-react";
import { Alert, AlertTitle, Box, Button, AlertColor, LinearProgress, LinearProgressProps, Typography, Tooltip, IconButton } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Organization, OrganizationApi, PaginatedTaskReadList, ProjectRead, TrainingApi, TrainingStatus } from "../api";
import ApiConfig from "../Components/ApiConfig";
import TrainCVATProject from "../Components/TrainCVATProject"
import CreateCVATTask from "../Components/CreateCVATTask";
import DeleteCVATProject from "../Components/DeleteCVATProject";
import Loading from "../Components/Loading";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useInterval } from 'usehooks-ts'
import TrainingError from "../Components/TrainingError";
import SaveModel from "../Components/SaveModel";
import { Info } from "@mui/icons-material";
import SelectOrg from "../Components/SelectOrg";

function TrainingProject() {
    const NO_ORG = "oniq"
	const { isLoading,user } = useAuth0();
	const { id } = useParams();
	const { getAccessTokenSilently } = useAuth0();
	const [loading, setLoading] = useState(false);
	const [isLoadingData, setIsLoadingData] = useState(true)
	const [taskData, setTaskData] = useState<PaginatedTaskReadList>()
	const [rowCountState, setRowCountState] = useState(0);
	const [page, setPage] = useState(0);
	const pageSize = 20;
	const [trainerStatus, setTrainerStatus] = useState<TrainingStatus>()
	const [delay, setDelay] = useState<number>(1000)
	const [trainingButton, setTrainingButton] = useState(false)
	const [cvatProject, setCvatProject] = useState<ProjectRead>()
	const [lastTrainedProject, setLastTrainedProject] = useState<ProjectRead>()
    const [orgs, setOrgs] = useState<Organization[]>([])
    

	const getTaskInfo = async () => {
		getCookies()
		try {
			const token = await getAccessTokenSilently();
			const header = { headers: { Authorization: `Bearer ${token}` } };
			setLoading(true);

			const res = await new TrainingApi(ApiConfig()).tasksList({
				filter: `{ "and": [{ "==": [{ "var": "project_id" }, ${id}] }] }`,
				page: 1 + page,
				pageSize: pageSize
			}, header);
			setTaskData(res);

			const cvatProjectRes = await new TrainingApi(ApiConfig()).projectsRetrieve({ trainingProjectId: parseInt(id as string) }, header)
			setCvatProject(cvatProjectRes)
			setIsLoadingData(false);
            let orgs = await new OrganizationApi(ApiConfig()).getAllOrganizations(header);
            orgs.splice(0, 0, { name: NO_ORG})
            setOrgs(orgs)
			setLoading(false);


		} catch (error) {
			console.log(error);
		}
	}

	const getCookies = async () => {
		const token = await getAccessTokenSilently();
		const header = { headers: { Authorization: `Bearer ${token}` } };
		try {
			let res = await new TrainingApi(ApiConfig()).getCookies(header)
			document.cookie = res[0].split(';')[0] + ";domain=.aimap.cloud"
			document.cookie = res[1].split(';')[0] + ";domain=.aimap.cloud"
		} catch (error) {
			console.log(error);
		}
	}

	const getLatestTrainedProjectById = async (trainingId: number) => {
		const token = await getAccessTokenSilently();
		const header = { headers: { Authorization: `Bearer ${token}` } };
		const res = await new TrainingApi(ApiConfig()).projectsRetrieve({ trainingProjectId: trainingId }, header)
		setLastTrainedProject(res)
		console.log(res)
	}

	const getStatus = async () => {
		const token = await getAccessTokenSilently();
		const header = { headers: { Authorization: `Bearer ${token}` } };

		const trainingRequest = { trainingProjectId: parseInt(id as string) };
		try {
			await new TrainingApi(ApiConfig()).trainingStatus(trainingRequest, header).then((res) => {
				setTrainerStatus(res)
				if (lastTrainedProject === undefined || lastTrainedProject.id !== res.project) {
					getLatestTrainedProjectById(res.project as number)
				}
			})
			//document.cookie=res[0].split(';')[0] + ";domain=.aimap.cloud"
			//document.cookie=res[1].split(';')[0] + ";domain=.aimap.cloud"
		} catch (error) {
			console.log(error);
		}

	}
	useInterval(
		() => {
			// Your custom logic here
			console.log(trainerStatus?.status)
			if (trainerStatus?.status === undefined) {
				setDelay(5000)
			}
			else if (trainerStatus?.status === "idle") {
				setTrainingButton(false)
				setDelay(5000)
			} else if (trainerStatus?.status === "training-error") {
				setTrainingButton(false)
				setDelay(5000)
			} else if (trainerStatus?.status === "Done") {
				setTrainingButton(false)
				setDelay(5000)
			}
			else {
				setTrainingButton(true)
				setDelay(1000)
			}

			getStatus()
		},
		// Delay in milliseconds or null to stop it
		delay,
	)

	useEffect(() => {
		setRowCountState((prevRowCountState: any) =>
			taskData?.count !== undefined ? taskData.count : prevRowCountState,
		);
	}, [taskData?.count, setRowCountState])

	useEffect(() => {
		getStatus()
	}, [])

	useEffect(() => {
		getTaskInfo();
	}, [id, page])

	if (isLoading || isLoadingData) {
		return (Loading())
	}

	const dataColumns: GridColDef[] = [
		{ field: 'Project', headerName: 'Project', width: 150 },
		{ field: 'taskImages', headerName: 'Number of images', width: 150 },
		{ field: 'taskStatus', headerName: 'Status', width: 150 },
		//{ field: 'taskId', headerName: 'ID', width: 150 },
		{ field: 'jobs', headerName: 'Jobs completed', width: 150 }
	];

	const dataRows = taskData?.results?.map((task) => {
		return {
			id: task.id,
			Project: task.name,
			taskImages: task.segmentSize,
			taskStatus: task.status,
			//taskId: task.id,
			jobs: `${task.segments!.map(segment => segment.jobs.filter(job => job.stage === "acceptance").length)} / ${task.segments!.length}`
		}
	});

	const alertClass = (): AlertColor => {
		if (!trainerStatus || trainerStatus.status === "training-error") {
			return 'warning'
		} else if (trainerStatus?.status === "Done") {
			return 'success'
		}else if(trainerStatus.status==='down'){
            return 'error'
        } else  {
			return 'info'
		}
	}
	const progress = (): number => {
		if (!trainerStatus) {
			return 0
		} else if (trainerStatus?.status === "idle") {
			return 0
		} else if (trainerStatus.status === 'initializing') {
			return 0.1
		} else if (trainerStatus.status === 'downloading') {
			return 0.25
		} else if (trainerStatus.status === 'sampling_images') {
			return 0.3
		}else if(trainerStatus.status==='preparing'){
			return 0.35
		}
		else if (trainerStatus.status === 'training') {
			let epoch = trainerStatus.epoch ? trainerStatus.epoch : 0;
			let epochs = trainerStatus.epochs ? trainerStatus.epochs : 1;
			return 0.35 + 0.65 * epoch / epochs;
		} else if (trainerStatus.status === "Done") {
			return 1
		}
		return 0;

	}
	function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
		return (
			<Box sx={{ display: 'flex', alignItems: 'center', padding: "5px", width: "90%" }}>
				<Box sx={{ width: '100%', mr: 1, ml: 3 }}>
					<LinearProgress variant="determinate" {...props} />
				</Box>
				<Box sx={{ minWidth: 35 }}>
					<Typography variant="body2" color="text.secondary">{`${Math.round(
						props.value,
					)}%`}</Typography>
				</Box>
			</Box>
		);
	}


    async function handleOrganizationChange(projectId?: number | undefined, orgName?: string | undefined){
        console.log(projectId, orgName)
        console.log(cvatProject?.assignee)
        const token = await getAccessTokenSilently();
        if(orgName===NO_ORG.toLowerCase()){
            orgName = "oniq"
        }
		const header = { headers: { Authorization: `Bearer ${token}` } };
        if(projectId && orgName){
            const updated = await new TrainingApi(ApiConfig()).projectsPartialUpdate({trainingProjectId: projectId,org:orgName},header)
            setCvatProject(updated)
        }
        return null;
    }

	return (
		<div>
			<Alert severity={alertClass()} sx={{ display: 'flex', justifyContent: 'center', m: 2, overflowX: "hidden" }}>
				<b>{lastTrainedProject?.name}</b>
				{trainerStatus?.modelType?<Tooltip title='The type of model trained'><b><br></br>{trainerStatus.modelType}</b></Tooltip>:''}
				<AlertTitle>
					Training status: {trainerStatus?.status}
					{trainerStatus?.status === "Done" &&
						<Tooltip title="Once the training is done and the result is acceptable, you can save the training as a module">
							<IconButton sx={{ marginLeft: "10px" }}> <Info /> </IconButton>
						</Tooltip>
					}
				</AlertTitle>
				<LinearProgressWithLabel variant="determinate" value={progress() * 100} />
				{!lastTrainedProject ? <></> :
					<>
						<Button sx={{
							backgroundColor: "rgb(143, 143, 159)",
							marginRight: "10px",
							'&:hover': {
								color: '#9ff1fa',
								backgroundColor: "rgb(143, 143, 159)",
							}
						}} hidden={trainerStatus?.status !== 'training'} href="https://tensorboard.aimap.oniq.solutions" target="_blank" variant="contained">View Logs</Button>
						
						{trainerStatus?.status === "training-error" || trainerStatus?.status==='down'  && <TrainingError trainerStatus={trainerStatus} />}
						{trainerStatus?.status !== "Done" ? (<></>) : <SaveModel trainerStatus={trainerStatus} lastTrainedProject={lastTrainedProject} />}
					</>
				}
			</Alert>

			<h1>{cvatProject?.name}</h1>
			<Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', m: 2 }}>

				<TrainCVATProject projectId={id} disabled={trainingButton} setDisabled={setTrainingButton} />
                <CreateCVATTask projectId={id} getTaskInfo={getTaskInfo} />
                {user && user["https://aimaps.k8s.oniq.solutions/roles"][0] === "administrator" && cvatProject ?  <><SelectOrg organizations={orgs} project={cvatProject} handleOrganizationChange={handleOrganizationChange}></SelectOrg> 
				<DeleteCVATProject /></>: <></>}
				<a href={`https://cvat.aimap.cloud/projects/${id}`} target="_blank" rel="noreferrer">
					<Button variant="contained">To Training page<RemoveRedEyeIcon /> </Button>
				</a>
			</Box>
			<Box style={{ backgroundColor: 'white', width: '100%', height: 400 }}>
				<DataGrid
					pageSize={pageSize}
					rowsPerPageOptions={[pageSize]}
					paginationMode='server'
					loading={loading}
					rowCount={rowCountState}
					onPageChange={(newPage) => setPage(newPage)}
					rows={dataRows as readonly any[]}
					columns={dataColumns}
					onRowClick={(newSelection) => {
						window.open(`https://cvat.aimap.cloud/tasks/${newSelection.id}`)
					}}
				/>
			</Box>
		</div>
	)
} export default TrainingProject;
