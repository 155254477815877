/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AttributeValRequest } from './AttributeValRequest';
import {
    AttributeValRequestFromJSON,
    AttributeValRequestFromJSONTyped,
    AttributeValRequestToJSON,
} from './AttributeValRequest';
import type { SubLabeledTrackRequest } from './SubLabeledTrackRequest';
import {
    SubLabeledTrackRequestFromJSON,
    SubLabeledTrackRequestFromJSONTyped,
    SubLabeledTrackRequestToJSON,
} from './SubLabeledTrackRequest';
import type { TrackedShapeRequest } from './TrackedShapeRequest';
import {
    TrackedShapeRequestFromJSON,
    TrackedShapeRequestFromJSONTyped,
    TrackedShapeRequestToJSON,
} from './TrackedShapeRequest';

/**
 * 
 * @export
 * @interface LabeledTrackRequest
 */
export interface LabeledTrackRequest {
    /**
     * 
     * @type {number}
     * @memberof LabeledTrackRequest
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof LabeledTrackRequest
     */
    frame: number;
    /**
     * 
     * @type {number}
     * @memberof LabeledTrackRequest
     */
    labelId: number;
    /**
     * 
     * @type {number}
     * @memberof LabeledTrackRequest
     */
    group?: number;
    /**
     * 
     * @type {string}
     * @memberof LabeledTrackRequest
     */
    source?: string;
    /**
     * 
     * @type {Array<TrackedShapeRequest>}
     * @memberof LabeledTrackRequest
     */
    shapes: Array<TrackedShapeRequest>;
    /**
     * 
     * @type {Array<AttributeValRequest>}
     * @memberof LabeledTrackRequest
     */
    attributes?: Array<AttributeValRequest>;
    /**
     * 
     * @type {Array<SubLabeledTrackRequest>}
     * @memberof LabeledTrackRequest
     */
    elements?: Array<SubLabeledTrackRequest>;
}

/**
 * Check if a given object implements the LabeledTrackRequest interface.
 */
export function instanceOfLabeledTrackRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "frame" in value;
    isInstance = isInstance && "labelId" in value;
    isInstance = isInstance && "shapes" in value;

    return isInstance;
}

export function LabeledTrackRequestFromJSON(json: any): LabeledTrackRequest {
    return LabeledTrackRequestFromJSONTyped(json, false);
}

export function LabeledTrackRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): LabeledTrackRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'frame': json['frame'],
        'labelId': json['label_id'],
        'group': !exists(json, 'group') ? undefined : json['group'],
        'source': !exists(json, 'source') ? undefined : json['source'],
        'shapes': ((json['shapes'] as Array<any>).map(TrackedShapeRequestFromJSON)),
        'attributes': !exists(json, 'attributes') ? undefined : ((json['attributes'] as Array<any>).map(AttributeValRequestFromJSON)),
        'elements': !exists(json, 'elements') ? undefined : ((json['elements'] as Array<any>).map(SubLabeledTrackRequestFromJSON)),
    };
}

export function LabeledTrackRequestToJSON(value?: LabeledTrackRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'frame': value.frame,
        'label_id': value.labelId,
        'group': value.group,
        'source': value.source,
        'shapes': ((value.shapes as Array<any>).map(TrackedShapeRequestToJSON)),
        'attributes': value.attributes === undefined ? undefined : ((value.attributes as Array<any>).map(AttributeValRequestToJSON)),
        'elements': value.elements === undefined ? undefined : ((value.elements as Array<any>).map(SubLabeledTrackRequestToJSON)),
    };
}

