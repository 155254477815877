import { LoadingButton } from '@mui/lab'
import { Autocomplete, Button, Chip, IconButton, ListItem, Modal, TextField, Tooltip, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system';
import React, { useEffect, useState } from 'react'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ModalStyle from './ModalStyle';
import ErrorComp from './ErrorComp';
import { useAuth0 } from '@auth0/auth0-react';
import { Module, ModuleApi } from '../api';
import ApiConfig from './ApiConfig';
import { Edit, EditNotifications } from '@mui/icons-material';

function EditModule(props: { user: any, getAllModules: any, module: Module }) {

	const { getAccessTokenSilently } = useAuth0();
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => { setOpen(true); refresh(); };
	const handleClose = () => { setOpen(false); refresh(); };
	const [displayError, setDisplayError] = useState({ display: 'none' })
	const [errorMessage, setErrorMessage] = useState('')
	const [loading, setLoading] = useState(false);
	const [module, setModule] = useState<Module>({})
	const [labels, setLabels] = useState<string[]>([])
	const [selectedImage, setSelectedImage] = useState<File>();
	const [imageName, setImageName] = useState<string | undefined>('');
	const refresh = () => {
		setModule(props.module)
		setSelectedImage(undefined)
		setImageName(props.module.image);
		setDisplayError({ display: 'none' })
		setLabels(props.module.labelMap ? props.module.labelMap : []);
		console.log(labels)
		setLoading(false)
	}

	const addModule = async () => {
		if (module.name == undefined || module.name == "") {
			setErrorMessage('Please name your module')
			setDisplayError({ display: 'block' })
		} else if (labels.length === 0) {
			setErrorMessage('You need at least one label')
			setDisplayError({ display: 'block' })
		} else {
			try {
				setLoading(true)
				const token = await getAccessTokenSilently();
				const header = {
					headers: {
						Authorization: `Bearer ${token}`,
						Accept: 'application/json', 'Content-Type': 'application/json'
					}
				};

				let base64Encoded = undefined;
				if (selectedImage) {
					base64Encoded = await toBase64(selectedImage) as string;
				}

				const moduleRequest = {
					description: module.description,
					image: base64Encoded,
				}
				if (module.id) {
					await new ModuleApi(ApiConfig()).updateModuleMetadataById({ module: moduleRequest, moduleId: module.id }, header).then(async () => await props.getAllModules())
				}
				setLoading(false)
				handleClose()

			} catch (error: any) {
				if (error.response.status === 403) {
					setErrorMessage('Name must be unique')
					setDisplayError({ display: 'block' })
				} else {
					setErrorMessage('Something went wrong')
					setDisplayError({ display: 'block' })
				}
				setLoading(false)
				console.log(error)
			}
		}

	}


	const handleDescriptionChange = (description: string) => {
		setModule({ ...module, ...{ description: description } })
	}

	const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSelectedImage(e.target.files![0])
		setImageName(e.target.files![0].name)
		console.log(e.target.files![0])
	}

	const toBase64 = (file: Blob) => new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = error => reject(error);
	});

	return (
		<>
			{props.user && props.user["https://aimaps.k8s.oniq.solutions/roles"][0] === "administrator" ?
				<Tooltip title="Edit module">
					<IconButton onClick={handleOpen}>
						<Edit />
					</IconButton>
				</Tooltip>
				:
				<></>
			}

			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="editModule"
			>
				<Box sx={ModalStyle}>
					<Stack spacing={2}>
						<Typography id="modal-signup" variant="h6" component="h2">
							Edit Module
						</Typography>
						<TextField id="modulename-field" name='name' disabled label="Module Name" variant="outlined" value={props.module.name} />
						<TextField id="moduledesc-field" name='description' multiline onChange={(e) => handleDescriptionChange(e.target.value)} label="Description" variant="outlined" value={module.description} />
						<TextField id="moduleversion-field" defaultValue={props.module.modelVersion} name='modelVersion' disabled label="Latest Version" variant="outlined" />
						<Button
							component="label"
							variant="outlined"
							startIcon={<UploadFileIcon />}
							sx={{ marginRight: "1rem" }}
						>
							Upload image
							<input type="file" accept="image/jpeg, image/png" hidden onChange={(e) => handleFileUpload(e)} />
						</Button>
						{!imageName ? <></> : imageName}
						<Box sx={displayError}>
							<ErrorComp error={errorMessage} />
						</Box>
						<Box sx={{ display: 'flex', justifyContent: 'space-between', }}>
							<Button variant="contained" onClick={handleClose}>Cancel</Button>
							<LoadingButton loading={loading} id="create-button" onClick={addModule} variant="contained">Submit</LoadingButton>
						</Box>
					</Stack>
				</Box>
			</Modal>
		</>
	)
}

export default EditModule