/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserAgreement
 */
export interface UserAgreement {
    /**
     * 
     * @type {string}
     * @memberof UserAgreement
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UserAgreement
     */
    displayText?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAgreement
     */
    url?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserAgreement
     */
    required?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAgreement
     */
    value?: boolean;
}

/**
 * Check if a given object implements the UserAgreement interface.
 */
export function instanceOfUserAgreement(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function UserAgreementFromJSON(json: any): UserAgreement {
    return UserAgreementFromJSONTyped(json, false);
}

export function UserAgreementFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAgreement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'displayText': !exists(json, 'display_text') ? undefined : json['display_text'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'required': !exists(json, 'required') ? undefined : json['required'],
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function UserAgreementToJSON(value?: UserAgreement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'display_text': value.displayText,
        'url': value.url,
        'required': value.required,
        'value': value.value,
    };
}

