/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ProviderTypeEnum = {
    AwsS3Bucket: 'AWS_S3_BUCKET',
    AzureContainer: 'AZURE_CONTAINER',
    GoogleDrive: 'GOOGLE_DRIVE',
    GoogleCloudStorage: 'GOOGLE_CLOUD_STORAGE'
} as const;
export type ProviderTypeEnum = typeof ProviderTypeEnum[keyof typeof ProviderTypeEnum];


export function ProviderTypeEnumFromJSON(json: any): ProviderTypeEnum {
    return ProviderTypeEnumFromJSONTyped(json, false);
}

export function ProviderTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProviderTypeEnum {
    return json as ProviderTypeEnum;
}

export function ProviderTypeEnumToJSON(value?: ProviderTypeEnum | null): any {
    return value as any;
}

