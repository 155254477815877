import { Configuration } from '../api'

function ApiConfig() {
  return (
    new Configuration({
      basePath: process.env.NODE_ENV == 'development'? "http://localhost:8080":'https://api.aimap.cloud',
    })
  )
}

export default ApiConfig
