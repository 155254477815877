import { Alert } from "@mui/material";
import { useState } from "react";

function ErrorComp(props: any){
const [error] = useState(props.error)

return(
<Alert severity="error">{props.error}</Alert>
);

}export default ErrorComp