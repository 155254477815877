import { Box, Button, IconButton, Modal, Stack, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import ModalStyle from './ModalStyle';
import { useAuth0 } from '@auth0/auth0-react';
import { License, LicenseApi, Module, ModuleApi } from '../api';
import ApiConfig from './ApiConfig';

function DeleteLicense(props: {license:License, user:any, getAllLicenses: Function}) {
	const { getAccessTokenSilently } = useAuth0()
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const [open, setOpen] = useState(false);

	const deleteLicenseById = async (license: License) => {
		try {
			const token = await getAccessTokenSilently();
			const header = { headers: { Authorization: `Bearer ${token}` } }
			await new LicenseApi(ApiConfig()).deleteLicense({ licenseId: license.id as string }, header)
			await props.getAllLicenses()
		} catch (error) {
			console.log(error)
		}
	}

	return (
		<>
			{props.user && props.user["https://aimaps.k8s.oniq.solutions/roles"][0] === "administrator" ?
			<Tooltip title={props.license.approved?"Delete license":"Delete request"}>
				<IconButton onClick={() => { handleOpen(); console.log(props.license) }}>
					<DeleteIcon color='error' />
				</IconButton>
			</Tooltip>
				:
				<></>
			}
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="deleteAccount"
			>
				<Box sx={ModalStyle}>
					<Stack spacing={2}>
						<Typography id="modal-deleteAccount" variant="h6" component="h2">
							Are you sure you want to remove this license?
						</Typography>
						<Box sx={{ display: 'flex', justifyContent: 'space-between', }}>
							<Button variant="contained" onClick={handleClose}>Cancel</Button>
							<Button color="error" id="delete-button" onClick={() => { deleteLicenseById(props.license); handleClose(); }} variant="contained">Remove</Button>
						</Box>
					</Stack>
				</Box>
			</Modal>
		</>
	)
}

export default DeleteLicense