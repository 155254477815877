/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AttributeValRequest } from './AttributeValRequest';
import {
    AttributeValRequestFromJSON,
    AttributeValRequestFromJSONTyped,
    AttributeValRequestToJSON,
} from './AttributeValRequest';
import type { TrackedShapeRequest } from './TrackedShapeRequest';
import {
    TrackedShapeRequestFromJSON,
    TrackedShapeRequestFromJSONTyped,
    TrackedShapeRequestToJSON,
} from './TrackedShapeRequest';

/**
 * 
 * @export
 * @interface SubLabeledTrackRequest
 */
export interface SubLabeledTrackRequest {
    /**
     * 
     * @type {number}
     * @memberof SubLabeledTrackRequest
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof SubLabeledTrackRequest
     */
    frame: number;
    /**
     * 
     * @type {number}
     * @memberof SubLabeledTrackRequest
     */
    labelId: number;
    /**
     * 
     * @type {number}
     * @memberof SubLabeledTrackRequest
     */
    group?: number;
    /**
     * 
     * @type {string}
     * @memberof SubLabeledTrackRequest
     */
    source?: string;
    /**
     * 
     * @type {Array<TrackedShapeRequest>}
     * @memberof SubLabeledTrackRequest
     */
    shapes: Array<TrackedShapeRequest>;
    /**
     * 
     * @type {Array<AttributeValRequest>}
     * @memberof SubLabeledTrackRequest
     */
    attributes?: Array<AttributeValRequest>;
}

/**
 * Check if a given object implements the SubLabeledTrackRequest interface.
 */
export function instanceOfSubLabeledTrackRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "frame" in value;
    isInstance = isInstance && "labelId" in value;
    isInstance = isInstance && "shapes" in value;

    return isInstance;
}

export function SubLabeledTrackRequestFromJSON(json: any): SubLabeledTrackRequest {
    return SubLabeledTrackRequestFromJSONTyped(json, false);
}

export function SubLabeledTrackRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubLabeledTrackRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'frame': json['frame'],
        'labelId': json['label_id'],
        'group': !exists(json, 'group') ? undefined : json['group'],
        'source': !exists(json, 'source') ? undefined : json['source'],
        'shapes': ((json['shapes'] as Array<any>).map(TrackedShapeRequestFromJSON)),
        'attributes': !exists(json, 'attributes') ? undefined : ((json['attributes'] as Array<any>).map(AttributeValRequestFromJSON)),
    };
}

export function SubLabeledTrackRequestToJSON(value?: SubLabeledTrackRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'frame': value.frame,
        'label_id': value.labelId,
        'group': value.group,
        'source': value.source,
        'shapes': ((value.shapes as Array<any>).map(TrackedShapeRequestToJSON)),
        'attributes': value.attributes === undefined ? undefined : ((value.attributes as Array<any>).map(AttributeValRequestToJSON)),
    };
}

