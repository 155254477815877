/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TrainingStatus
 */
export interface TrainingStatus {
    /**
     * 
     * @type {string}
     * @memberof TrainingStatus
     */
    status?: string;
    /**
     * 
     * @type {number}
     * @memberof TrainingStatus
     */
    readonly project?: number;
    /**
     * 
     * @type {string}
     * @memberof TrainingStatus
     */
    readonly message?: string;
    /**
     * 
     * @type {number}
     * @memberof TrainingStatus
     */
    readonly epoch?: number;
    /**
     * 
     * @type {number}
     * @memberof TrainingStatus
     */
    readonly epochs?: number;
    /**
     * 
     * @type {number}
     * @memberof TrainingStatus
     */
    readonly inputSize?: number;
    /**
     * 
     * @type {string}
     * @memberof TrainingStatus
     */
    readonly modelType?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TrainingStatus
     */
    readonly labels?: Array<string>;
}

/**
 * Check if a given object implements the TrainingStatus interface.
 */
export function instanceOfTrainingStatus(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TrainingStatusFromJSON(json: any): TrainingStatus {
    return TrainingStatusFromJSONTyped(json, false);
}

export function TrainingStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrainingStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : json['status'],
        'project': !exists(json, 'project') ? undefined : json['project'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'epoch': !exists(json, 'epoch') ? undefined : json['epoch'],
        'epochs': !exists(json, 'epochs') ? undefined : json['epochs'],
        'inputSize': !exists(json, 'inputSize') ? undefined : json['inputSize'],
        'modelType': !exists(json, 'modelType') ? undefined : json['modelType'],
        'labels': !exists(json, 'labels') ? undefined : json['labels'],
    };
}

export function TrainingStatusToJSON(value?: TrainingStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
    };
}

