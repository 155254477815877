import { Module, Organization, PaginatedPolymorphicProjectList, TrainingApi } from "../api"
import { useAuth0 } from '@auth0/auth0-react';
import ApiConfig from "./ApiConfig";
import { useEffect, useState } from "react";
import { Box, Stack } from "@mui/system";
import ModalStyle from "./ModalStyle";
import { Button, MenuItem, Modal, TextField, Tooltip, Typography } from "@mui/material";
import ErrorComp from "./ErrorComp";
import { LoadingButton } from "@mui/lab";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';


function CreateCVATProject(props: { getAllCVATProjects: any, modules: Module[], orgs: Organization[] , cvatProjects: PaginatedPolymorphicProjectList }) {
	const [module, setModule] = useState<Module>({})
	const { getAccessTokenSilently, user } = useAuth0();
	const [loading, setLoading] = useState(false);
	const [displayError, setDisplayError] = useState({ display: 'none' })
	const [errorMessage, setErrorMessage] = useState('')
	const [open, setOpen] = useState(false);
    const [role, setRole] = useState<string>()
    const [organization, setOrganization] = useState<string>('');
    const handleOrganizationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOrganization(event.target.value);
    }

	const handleOpen = () => { setOpen(true); refresh(); };
	const handleClose = () => { setOpen(false); refresh(); };

	const refresh = () => {
		setModule({})
		setDisplayError({ display: 'none' })
		setLoading(false)
	}

    useEffect(() => {
        if (user && user["https://aimaps.k8s.oniq.solutions/roles"]) {
            setRole(user["https://aimaps.k8s.oniq.solutions/roles"][0])
        }
    }, [user]);
    

	const createTrainingProject = async () => {
		const requestOptions = {
			headers: {
				Authorization: `Bearer ${await getAccessTokenSilently()}`,
				Accept: 'application/json', 'Content-Type': 'application/json'
			},
		}

		const cvatName = module.name + "-" + (module.modelVersion as number + 1)

		if (module.name === undefined) {
			setErrorMessage("Name is missing")
			setDisplayError({ display: 'block' })
		} else if (props.cvatProjects.results?.find(trainingProject => trainingProject.name === cvatName)) {
			setErrorMessage("Only one training per version allowed. This version is already in progress")
			setDisplayError({ display: 'block' })
		} else {
			try {
				setLoading(true)
				await new TrainingApi(ApiConfig()).projectsCreate({
					projectWriteRequest:
					{
                        
						name: cvatName,
                        
						labels: module.labelMap?.map((label: string) => {
							return {
								name: label,
								attributes: [],
								type: "any"
							}
						})
					},
                    org: organization
				}, requestOptions).then(response => {
					props.getAllCVATProjects()
				})
				setLoading(false)
				handleClose();
			} catch (error: any) {
				console.log(error.response)
				setErrorMessage("Something went wrong")
				setDisplayError({ display: 'block' })
				setLoading(false)
			}
		}
	}

	const handleModuleChange = (moduleName: string) => {
		const moduleObj = props.modules.find(module => module.name === moduleName) as Module
		setModule(moduleObj)
	}
	return (
		<div>
            <Tooltip title={(props.modules && props.modules.length===0)?'No modules to train, request a licence from Uggla':'Create a new training session'}>
               <div> <Button className='createOrganization-button' disabled={props.modules && props.modules.length===0} onClick={handleOpen} variant="contained">
                    Create<AddCircleOutlineOutlinedIcon />
                </Button> </div>
            </Tooltip>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="createOrg"
			>
				<Box sx={ModalStyle}>
					<Stack spacing={2}>
						<Typography id="modal-cvatProject" variant="h6" component="h2">
							New Training
						</Typography>
						<TextField id="cvatProjectname-field" name='name' defaultValue={''} onChange={(e) => handleModuleChange(e.target.value)} select label="Module to train" variant="outlined">
							{props.modules.map((option: Module) => (
								<MenuItem key={option.name} value={option.name}>
									{option.name}
								</MenuItem>
							))}
						</TextField>
						<Box sx={{
							display: 'grid',
							gridAutoColumns: '1fr',
							gap: 1,
						}}>
							<Box sx={{ gridRow: '2', gridColumn: 'span 4' }}>
								<TextField id="version-field" value={module.modelVersion as number + 1 || 0} name='version' disabled label="Version" variant="outlined" />
							</Box>
                        </Box>
                        {"administrator"===role?<TextField id="org-field" name='organization' select value={organization} onChange={handleOrganizationChange} label="Organization" variant="outlined" >
                            {props.orgs.map((org) => (
                                <MenuItem key={org.name} value={org.name}>
                                    {org.name}
                                </MenuItem>
                            ))}
                        </TextField>:<></>}
						<Box sx={displayError}>
							<ErrorComp error={errorMessage} />
						</Box>
						<Box sx={{ display: 'flex', justifyContent: 'space-between', }}>
							<Button variant="contained" onClick={handleClose}>Cancel</Button>
							<LoadingButton loading={loading} id="create-button" onClick={() => createTrainingProject()} variant="contained">Save</LoadingButton>
						</Box>
					</Stack>
				</Box>
			</Modal>
		</div>

	)

} export default CreateCVATProject