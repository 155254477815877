import { Button, Modal, TextField, Typography, IconButton, Tooltip } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { useAuth0 } from '@auth0/auth0-react';
import React, { useState } from 'react'
import ApiConfig from './ApiConfig';
import EditIcon from '@mui/icons-material/Edit';
import { Organization, OrganizationApi } from '../api';
import ModalStyle from './ModalStyle';
import ErrorComp from './ErrorComp';
import { LoadingButton } from '@mui/lab';
import { Help } from '@mui/icons-material';

function EditOrganization(props: any) {
	const [open, setOpen] = React.useState(false);
	const { getAccessTokenSilently } = useAuth0();
	const handleOpen = () => { setOpen(true); refresh(); };
	const handleClose = () => { setOpen(false); refresh(); };
	const [organization, setOrganization] = useState<Organization>({ organizationId: props.organization.organizationId, name: props.organization.name, description: props.organization.description, contact: props.organization.contact })
	const [displayError, setDisplayError] = useState({ display: 'none' })
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState('')

	const refresh = () => {
		setOrganization({ organizationId: props.organization.organizationId, name: props.organization.name, description: props.organization.description, contact: props.organization.contact });
		setDisplayError({ display: 'none' });
		setLoading(false)
	}

	const handleOrganizationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		setOrganization({
			...organization,
			[event.target.name]: newValue
		});
	}

	const editOrganization = async (organization: Organization) => {
		const editOrganizationById = async () => {
			try {
				const token = await getAccessTokenSilently();
				const header = {
					headers: {
						Authorization: `Bearer ${token}`,
						Accept: 'application/json', 'Content-Type': 'application/json'
					}
				}

				if (organization.organizationId) {
					const updateOrganizationRequest = { organizationId: organization.organizationId, organization: organization }
					const data = await new OrganizationApi(ApiConfig()).updateOrganization(updateOrganizationRequest, header)
					props.getAllOrganizations()
					return true
				}
			} catch (error: any) {
				setErrorMessage(error.response.data.message)
				setDisplayError({ display: 'block' })
				setLoading(false)
				return false
			}
		}
		await setLoading(true)
		const success = await editOrganizationById();
		if (success) {
			handleClose();
		}

	}

	return (
		<>
		    <Tooltip title="Edit organization">
				<IconButton onClick={handleOpen}>
					<EditIcon />
				</IconButton>
			</Tooltip>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="editOrg"
			>
				<Box sx={ModalStyle}>
					<Stack spacing={2}>
						<Typography id="modal-editOrg" variant="h6" component="h2">
							Edit Organization
						</Typography>
						<Box sx={{
							display: 'grid',
							gridAutoColumns: '1fr',
							gap: 1,
						}}>
							<Box sx={{ gridRow: '1', gridColumn: 'span 5' }}>
								<TextField id="orgname-field" name='name' value={organization.name} onChange={handleOrganizationChange} label="Organization Name" variant="outlined" disabled />
							</Box>
							<Box sx={{ gridRow: '1' }}>
								<Tooltip title="Name change not allowed">
									<IconButton> <Help /> </IconButton>
								</Tooltip>
							</Box>
						</Box>
						<TextField id="orgdesc-field" name='description' value={organization.description} onChange={handleOrganizationChange} label="Description" variant="outlined" />
						<Box sx={displayError}>
							<ErrorComp error={errorMessage} />
						</Box>
						<Box sx={{ display: 'flex', justifyContent: 'space-between', }}>
							<Button variant="contained" onClick={handleClose}>Cancel</Button>
							<LoadingButton loading={loading} id="create-button" onClick={event => editOrganization(organization)} variant="contained">Submit</LoadingButton>
						</Box>
					</Stack>
				</Box>
			</Modal>
		</>
	)

} export default EditOrganization