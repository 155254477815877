/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PatchedLabelRequest } from './PatchedLabelRequest';
import {
    PatchedLabelRequestFromJSON,
    PatchedLabelRequestFromJSONTyped,
    PatchedLabelRequestToJSON,
} from './PatchedLabelRequest';
import type { StorageRequest } from './StorageRequest';
import {
    StorageRequestFromJSON,
    StorageRequestFromJSONTyped,
    StorageRequestToJSON,
} from './StorageRequest';

/**
 * 
 * @export
 * @interface ProjectWriteRequest
 */
export interface ProjectWriteRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectWriteRequest
     */
    name: string;
    /**
     * 
     * @type {Array<PatchedLabelRequest>}
     * @memberof ProjectWriteRequest
     */
    labels?: Array<PatchedLabelRequest>;
    /**
     * 
     * @type {number}
     * @memberof ProjectWriteRequest
     */
    ownerId?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectWriteRequest
     */
    assigneeId?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectWriteRequest
     */
    bugTracker?: string;
    /**
     * 
     * @type {StorageRequest}
     * @memberof ProjectWriteRequest
     */
    targetStorage?: StorageRequest;
    /**
     * 
     * @type {StorageRequest}
     * @memberof ProjectWriteRequest
     */
    sourceStorage?: StorageRequest;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectWriteRequest
     */
    taskSubsets?: Array<string>;
}

/**
 * Check if a given object implements the ProjectWriteRequest interface.
 */
export function instanceOfProjectWriteRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function ProjectWriteRequestFromJSON(json: any): ProjectWriteRequest {
    return ProjectWriteRequestFromJSONTyped(json, false);
}

export function ProjectWriteRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectWriteRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'labels': !exists(json, 'labels') ? undefined : ((json['labels'] as Array<any>).map(PatchedLabelRequestFromJSON)),
        'ownerId': !exists(json, 'owner_id') ? undefined : json['owner_id'],
        'assigneeId': !exists(json, 'assignee_id') ? undefined : json['assignee_id'],
        'bugTracker': !exists(json, 'bug_tracker') ? undefined : json['bug_tracker'],
        'targetStorage': !exists(json, 'target_storage') ? undefined : StorageRequestFromJSON(json['target_storage']),
        'sourceStorage': !exists(json, 'source_storage') ? undefined : StorageRequestFromJSON(json['source_storage']),
        'taskSubsets': !exists(json, 'task_subsets') ? undefined : json['task_subsets'],
    };
}

export function ProjectWriteRequestToJSON(value?: ProjectWriteRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'labels': value.labels === undefined ? undefined : ((value.labels as Array<any>).map(PatchedLabelRequestToJSON)),
        'owner_id': value.ownerId,
        'assignee_id': value.assigneeId,
        'bug_tracker': value.bugTracker,
        'target_storage': StorageRequestToJSON(value.targetStorage),
        'source_storage': StorageRequestToJSON(value.sourceStorage),
        'task_subsets': value.taskSubsets,
    };
}

