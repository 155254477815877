/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const OperationStatus = {
    New: 'new',
    InProgress: 'in progress',
    Completed: 'completed',
    Rejected: 'rejected'
} as const;
export type OperationStatus = typeof OperationStatus[keyof typeof OperationStatus];


export function OperationStatusFromJSON(json: any): OperationStatus {
    return OperationStatusFromJSONTyped(json, false);
}

export function OperationStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): OperationStatus {
    return json as OperationStatus;
}

export function OperationStatusToJSON(value?: OperationStatus | null): any {
    return value as any;
}

