import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, IconButton, InputLabel, MenuItem, Modal, Select, Tooltip, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { TrainingApi, TrainingStatus } from "../api";
import ApiConfig from "./ApiConfig";
import { Help } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import ErrorComp from "./ErrorComp";
import { isNumberObject } from "util/types";

function TrainCVATProject(props: any) {
	const { getAccessTokenSilently } = useAuth0();
	const [open, setOpen] = useState(false);
	const handleOpen = () => { setOpen(true); };
	const handleClose = () => { setOpen(false); };
	const [errorMessage, setErrorMessage] = useState<string>('');
	const [projectId, setProjectId] = useState<number>(0);
	const [downloading, setDownloading] = useState(false)
	const [useUnknown, setUseUnkown] = useState(false)

	const [inputSize, setInputSize]= useState<number>(224);
	const [modelType, setModelType] = useState<string>('OBJECT_DETECTION');
	const { id } = useParams();
	function isNumber(value?: string | number): boolean
		{
		return ((value != null) &&
				(value !== '') &&
				!isNaN(Number(value.toString())));
		}
	const TrainCVATProject = async (projectId: number, useUnknown: boolean, inputSize: number, modelType:string) => {
		try {
			setErrorMessage('')
			setDownloading(true)
			const token = await getAccessTokenSilently();
			const header = { headers: { Authorization: `Bearer ${token}` } }
			await new TrainingApi(ApiConfig()).startTraining({ trainingProjectId: projectId, useUnknown: useUnknown, inputSize:inputSize, modelType:modelType }, header);
		} catch (error: any) {
			error.response.json().then(
				(error: any) => {
					setErrorMessage(error.message)
				}
			)
		}
		setDownloading(false)
		props.setDisabled(true)
		//window.location = "https://tensorboard.k8s.oniq.solutions" as unknown as Location
	}

	return (
		<>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					Use unknown?
				</DialogTitle>
				<DialogContent>

					<DialogContentText id="alert-dialog-description">
						Do you want to use unlabelled images in training? This will train all unlabelled images with the label "unknown"
						<br></br>
						<FormControlLabel control={<Checkbox onChange={(event)=>{
							console.log(event.target.checked);
							setUseUnkown(event.target.checked)
							}} />} label="Use unknown" />
						<InputLabel id="demo-simple-select-label">Select the input size of the model</InputLabel>

					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={inputSize}
						label="Model input size"
						onChange={(event)=>{
							setInputSize(event.target.value as number)
						}}
					>
						<MenuItem value={224}>224</MenuItem>
						<MenuItem value={384}>384</MenuItem>
						<MenuItem value={512}>512</MenuItem>
					</Select>
  				<InputLabel id="demo-simple-select-label">Model type</InputLabel>
				<Select
					labelId="modelType"
					id="modelType"
					value={modelType}
					label="Model type"
					onChange={(event)=>setModelType(event.target.value as string)}
				>
					<MenuItem value="OBJECT_DETECTION">Object detection</MenuItem>
					<MenuItem value="CLASSIFICATION">Image classification</MenuItem>
				</Select>
					</DialogContentText>
				</DialogContent>

				<DialogActions>
					<Button id="create-button" color="warning" onClick={() => { handleClose();}} variant="contained"> Cancel </Button>
					<Button color="info" onClick={() => {TrainCVATProject(props.projectId, useUnknown, inputSize,modelType); handleClose();}}>Train</Button>

				</DialogActions>
			</Dialog>
			{errorMessage !== '' ?
				<>
					<Tooltip title={errorMessage}>
						<IconButton> <Help /> </IconButton>
					</Tooltip>
					<LoadingButton disabled={props.disabled || downloading} className='deleteOrganization-button' onClick={handleOpen} variant="contained">
						Train Project
					</LoadingButton>
				</>
				:
				<LoadingButton disabled={props.disabled || downloading} loading={downloading} className='deleteOrganization-button' onClick={handleOpen} variant="contained">
					Train Project
				</LoadingButton>
			}

		</>
	)
}
export default TrainCVATProject