/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const StorageType = {
    CloudStorage: 'cloud_storage',
    Local: 'local',
    Share: 'share'
} as const;
export type StorageType = typeof StorageType[keyof typeof StorageType];


export function StorageTypeFromJSON(json: any): StorageType {
    return StorageTypeFromJSONTyped(json, false);
}

export function StorageTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): StorageType {
    return json as StorageType;
}

export function StorageTypeToJSON(value?: StorageType | null): any {
    return value as any;
}

