import { Box, Button, Checkbox, Chip, FormControl, InputLabel, ListItemText, MenuItem, Modal, OutlinedInput, Select, Slider, Stack, Typography } from "@mui/material";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { useEffect, useState } from "react";
import ModalStyle from "./ModalStyle";
import ErrorComp from "./ErrorComp";
import { LoadingButton } from "@mui/lab";
import { useAuth0 } from "@auth0/auth0-react";
import { DataApi, GetDataPointsForProjectRequest, Project, ProjectApi, StorageRequest, TrainingApi } from "../api";
import ApiConfig from "./ApiConfig";
import { useNavigate } from 'react-router-dom';

function timeout(delay: number) {
    return new Promise( res => setTimeout(res, delay) );
}

function CreateCVATTask(props: any) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => { setOpen(true) };
    const handleClose = () => { setOpen(false); };
    const { getAccessTokenSilently } = useAuth0();
    const [loading, setLoading] = useState(false);
    const [displayError, setDisplayError] = useState({ display: 'none' })
    const [errorMessage, setErrorMessage] = useState('')
    const [imageQuality, setImageQuality] = useState<number>(70);
    const navigate = useNavigate();
    const [projects, setProjects] = useState<Project[]>([]);
    const [selectedProjects, setSelectedProjects] = useState<Project[]>([]);
    const [projectIds, setProjectIds] = useState<string[]>([]);

    const createTrainingTask = async () => {
        let dataPoints = selectedProjects.map(project => (project.projectId as string))
        console.log(dataPoints)

        console.log(selectedProjects.map(project => (project.heldImages)))

        //await getDataPointsById(dataPoints)

        let noImages = selectedProjects.map(project => (project.heldImages === 0)).includes(true)
        console.log(selectedProjects.length)
        if (selectedProjects.length === 0) {
            setErrorMessage('Please select at least one project')
            setDisplayError({ display: 'block' })
        } else if (noImages === true) {
            setErrorMessage('Please make sure your projects have images')
            setDisplayError({ display: 'block' })
        } else {
            const requestOptions = {
                headers: {
                    Authorization: `Bearer ${await getAccessTokenSilently()}`,
                    Accept: 'application/json', 'Content-Type': 'application/json'
                },
            }

            try {
                setLoading(true);
                selectedProjects.forEach(async project => {
                    console.log(project.cloudStorageId)
                    const storageRequest = {
                        "location": "cloud_storage",
                        "cloudStorageId": project.cloudStorageId
                    }
                    console.log(storageRequest)
                    const res = await new TrainingApi(ApiConfig()).tasksCreate({
                        taskWriteRequest: {
                            projectId: props.projectId,
                            name: project.name as string,
                            targetStorage: storageRequest as StorageRequest,
                            sourceStorage: storageRequest as StorageRequest
                        }
                    }, requestOptions)
                    await new TrainingApi(ApiConfig()).tasksCreateData({
                        id: res.id as number,
						projectId: project.projectId as string,
                        dataRequest: {
                            imageQuality: imageQuality,
                            cloudStorageId: project.cloudStorageId,
                            useCache: true,
                            copyData: true,
                        }
                    }, requestOptions)
                    setLoading(false);
                    await timeout(1000)
                    props.getTaskInfo()
                    handleClose()
                })

                setDisplayError({ display: 'none' })


            } catch (error: any) {
                console.log("ERROR DETECTED")
                setErrorMessage(error.response.data.message)
                setDisplayError({ display: 'block' })
                setLoading(false)
            }
        }

    }

    const getAllProjects = async () => {
        try {
            const token = await getAccessTokenSilently();
            const header = { headers: { Authorization: `Bearer ${token}` } };
            const res = await new ProjectApi(ApiConfig()).getAllProjectsForOrganization(header)
            const data = res;
            setProjects(data);
        } catch (error) {
            console.log(error);
            navigate("/")

        }
    }

    useEffect(() => {
        getAllProjects();
    }, [getAccessTokenSilently]);

    const handleCheckboxChange = (event: { target: { value: any; }; }) => {
        const { value } = event.target;
        setProjectIds(value);
        setSelectedProjects(projects.filter((project) => value.includes(project.projectId as string)))
    };

    function valuetext(value: number) {
        return `${value}%`;
    }

    return (
        <div>
            <Button className='createOrganization-button' onClick={handleOpen} variant="contained">
                Create Task<AddCircleOutlineOutlinedIcon />
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="createOrg"
            >
                <Box sx={ModalStyle}>
                    <Stack spacing={2}>
                        <Typography id="modal-cvatProject" variant="h6" component="h2">
                            New Training Task
                        </Typography>
                        <FormControl>
                            <InputLabel id="checkboxlabel">Collections</InputLabel>
                            <Select

                                labelId="checkboxlabel"
                                input={<OutlinedInput label="Collections" />}
                                multiple
                                value={projectIds}
                                onChange={handleCheckboxChange}
                                renderValue={(selected) =>
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {(selected as string[]).map((value) => (
                                            <Chip key={value} label={projects?.find(e => e.projectId === value)?.name} />
                                        ))}
                                    </Box>
                                }>
                                {projects.map((project) => (
                                    <MenuItem key={project.projectId} value={project.projectId}>
                                        <Checkbox disabled={project.heldImages==0} checked={projectIds.includes(project.projectId as string)} />
                                        <ListItemText >{project.name} ({project.heldImages} images)</ListItemText>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Box sx={displayError}>
                            <ErrorComp error={errorMessage} />
                        </Box>
                        <Box sx={{ display: 'flex', gap: '10px' }}>
                            <Button variant="contained" onClick={handleClose}>Cancel</Button>
                            <LoadingButton loading={loading} id="create-button" onClick={createTrainingTask} variant="contained">Save</LoadingButton>
                        </Box>
                    </Stack>
                </Box>
            </Modal>
        </div >
    )
} export default CreateCVATTask;