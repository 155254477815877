/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Export,
  Project,
  ProjectDetails,
} from '../models';
import {
    ExportFromJSON,
    ExportToJSON,
    ProjectFromJSON,
    ProjectToJSON,
    ProjectDetailsFromJSON,
    ProjectDetailsToJSON,
} from '../models';

export interface AddProjectForOrganizationRequest {
    project: Project;
}

export interface DeleteProjectByIdRequest {
    projectId: string;
}

export interface GetProjectByIdRequest {
    projectId: string;
}

export interface GetProjectExportRequest {
    projectId: string;
    format?: GetProjectExportFormatEnum;
}

export interface ReRunProjectByIdRequest {
    projectId: string;
    moduleId: string;
    create?: boolean;
    name?: string;
}

export interface UpdateProjectByIdRequest {
    projectId: string;
    project: Project;
}

/**
 * 
 */
export class ProjectApi extends runtime.BaseAPI {

    /**
     * Add new Customer Organization Project
     * Add new Customer OrganizationProject
     */
    async addProjectForOrganizationRaw(requestParameters: AddProjectForOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Project>> {
        if (requestParameters.project === null || requestParameters.project === undefined) {
            throw new runtime.RequiredError('project','Required parameter requestParameters.project was null or undefined when calling addProjectForOrganization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/project/organization`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectToJSON(requestParameters.project),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectFromJSON(jsonValue));
    }

    /**
     * Add new Customer Organization Project
     * Add new Customer OrganizationProject
     */
    async addProjectForOrganization(requestParameters: AddProjectForOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Project> {
        const response = await this.addProjectForOrganizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Project for an Organization by Id
     */
    async deleteProjectByIdRaw(requestParameters: DeleteProjectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Project>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling deleteProjectById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/project/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectFromJSON(jsonValue));
    }

    /**
     * Get Project for an Organization by Id
     */
    async deleteProjectById(requestParameters: DeleteProjectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Project> {
        const response = await this.deleteProjectByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all Projects for an Organization
     * Get all Projects for an Organization
     */
    async getAllProjectsForOrganizationRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Project>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/project/organization`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProjectFromJSON));
    }

    /**
     * Get all Projects for an Organization
     * Get all Projects for an Organization
     */
    async getAllProjectsForOrganization(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Project>> {
        const response = await this.getAllProjectsForOrganizationRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get Project for an Organization by Id
     * Get Project for an Organization by Id
     */
    async getProjectByIdRaw(requestParameters: GetProjectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectDetails>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/project/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectDetailsFromJSON(jsonValue));
    }

    /**
     * Get Project for an Organization by Id
     * Get Project for an Organization by Id
     */
    async getProjectById(requestParameters: GetProjectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectDetails> {
        const response = await this.getProjectByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Prepare an export of data
     * Export data
     */
    async getProjectExportRaw(requestParameters: GetProjectExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Export>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectExport.');
        }

        const queryParameters: any = {};

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/project/{projectId}/export`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExportFromJSON(jsonValue));
    }

    /**
     * Prepare an export of data
     * Export data
     */
    async getProjectExport(requestParameters: GetProjectExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Export> {
        const response = await this.getProjectExportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Rerun project with a new Module
     * Rerun project with a new Module
     */
    async reRunProjectByIdRaw(requestParameters: ReRunProjectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Project>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling reRunProjectById.');
        }

        if (requestParameters.moduleId === null || requestParameters.moduleId === undefined) {
            throw new runtime.RequiredError('moduleId','Required parameter requestParameters.moduleId was null or undefined when calling reRunProjectById.');
        }

        const queryParameters: any = {};

        if (requestParameters.moduleId !== undefined) {
            queryParameters['moduleId'] = requestParameters.moduleId;
        }

        if (requestParameters.create !== undefined) {
            queryParameters['create'] = requestParameters.create;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/project/{projectId}/re-run`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectFromJSON(jsonValue));
    }

    /**
     * Rerun project with a new Module
     * Rerun project with a new Module
     */
    async reRunProjectById(requestParameters: ReRunProjectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Project> {
        const response = await this.reRunProjectByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update project for an Organization by Id
     * Update Project for an Organization by Id
     */
    async updateProjectByIdRaw(requestParameters: UpdateProjectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Project>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling updateProjectById.');
        }

        if (requestParameters.project === null || requestParameters.project === undefined) {
            throw new runtime.RequiredError('project','Required parameter requestParameters.project was null or undefined when calling updateProjectById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/project/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectToJSON(requestParameters.project),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectFromJSON(jsonValue));
    }

    /**
     * Update project for an Organization by Id
     * Update Project for an Organization by Id
     */
    async updateProjectById(requestParameters: UpdateProjectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Project> {
        const response = await this.updateProjectByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetProjectExportFormatEnum = {
    Landxml: 'landxml',
    Csv: 'csv'
} as const;
export type GetProjectExportFormatEnum = typeof GetProjectExportFormatEnum[keyof typeof GetProjectExportFormatEnum];
