import { Box, Paper, TableContainer } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { License, LicenseApi, Module, ModuleApi, Organization, OrganizationApi, PaginatedPolymorphicProjectList, Project, ProjectApi, TrainingApi } from "../api";
import ApiConfig from "../Components/ApiConfig";
import CreateProject from "../Components/CreateProject";
import Loading from "../Components/Loading";
import { useNavigate } from 'react-router-dom';
import projectRow from "../Components/ProjectRow";
import { SortableTable, simpleComparator } from '../Components/SortableTable';

function Projects() {
	const { user, isLoading, getAccessTokenSilently } = useAuth0();
	const [projects, setProjects] = useState<Project[]>([]);
	const [modules, setModules] = useState<Module[]>([])
	const [organizations, setOrganizations] = useState<Organization[]>([])
	const navigate = useNavigate();
	//const [disabledButtons, setDisabledButtons] = useState(false)
    const [role, setRole] = useState<string>()
    const [licenses, setLicenses] = useState<License[]>([])
	const [trainingProjects, setTrainingProjects] = useState<PaginatedPolymorphicProjectList>()

	const header = async () => {
		return { headers: { Authorization: `Bearer ${await getAccessTokenSilently()}` } };
	}

	const getAllProjects = async () => {
		try {
			const res = await new ProjectApi(ApiConfig()).getAllProjectsForOrganization(header)
			setProjects(res);
		} catch (error) {
			console.log(error);
			//navigate("/")
		}
	}

	const getAllModules = async () => {
		try {
			const mods = await new ModuleApi(ApiConfig()).getAllModules(header)
			if (user && 'administrator' !== user["https://aimaps.k8s.oniq.solutions/roles"]) {
				const licenses = await new LicenseApi(ApiConfig()).getLicenses(header)
                setLicenses(licenses)
				setModules(mods.filter(m => licenses.filter(l => l.approved).map(l => l.module?.id).includes(m.id)));
			} else {
				setModules(mods);
			}
		} catch (error) {
			console.log(error)
		}
	}

	const getAllOrganizations = async () => {
		try {
			await new OrganizationApi(ApiConfig()).getAllOrganizations(header)
				.then((res) => setOrganizations(res));
		} catch (e) {
			console.error(e)
		}
	}

	const getAllTrainingProjects = async () => {
		try {
			const response = await new TrainingApi(ApiConfig()).projectsList({}, header)
			const response2 = await new TrainingApi(ApiConfig()).projectsList({ pageSize: response.count }, header)
			setTrainingProjects(response2)
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		getAllProjects();
		getAllOrganizations();
		getAllModules();
		getAllTrainingProjects();
        setRole(user && user["https://aimaps.k8s.oniq.solutions/roles"][0])
	}, [user]);

	if (isLoading) {
		return (Loading())
	}
    const uploadDisabled = (project: Project) => {
        if(user && user["https://aimaps.k8s.oniq.solutions/roles"][0] === "administrator") {
            return false
        }
        if (project.module && !licenses.filter(l => l.module?.id === project.module?.id).map(l => l.approved).includes(true)) {
            return true
        }
        return false
    }

	return (
		<>
			{user && (user["https://aimaps.k8s.oniq.solutions/roles"][0] === "user" || user["https://aimaps.k8s.oniq.solutions/roles"][0] === "superuser") ?
				<h1>Projects</h1>
				:
				<h1>Collections</h1>

			}
			<Box sx={{ display: 'flex', justifyContent: 'flex-end', m: 2 }}>
				<CreateProject user={user} setProjects={setProjects} projects={projects} modules={modules} disabledButtons={false} />
			</Box>
			<TableContainer component={Paper}>
				<SortableTable tableProps={{ 'aria-label': "project-table" }}
					columns={[
						{ key: 'p', header: user && user["https://aimaps.k8s.oniq.solutions/roles"][0] === "user" ? <b>Project</b> : <b>Collection</b>, comparator: simpleComparator },
						{ key: "o", header: <b>Organization</b>, comparator: simpleComparator },
						{ key: "d", header: <b>Description</b>, },
						{ key: "m", header: <b>Module</b>, comparator: simpleComparator },
						{ key: "i", header: <>Images</>, comparator: simpleComparator },
						{ key: "up", header: <>User project</> },
						{ key: "btns", header: <></> }
					]}
					rows={projects.map((project) => projectRow({ addProject: (project: Project, add: boolean)=>{setProjects((projects)=>add? [...[project, ...projects]]:projects.map(p=>p.projectId===project.projectId?project:p))}, key: project.projectId, project: project, user: user, getAllProjects: getAllProjects, role: role, trainingProjects: trainingProjects, setProjects: setProjects, modules: modules, organizations: organizations, getAccessTokenSilently: getAccessTokenSilently, navigate: navigate, uploadDisabled : uploadDisabled(project), moduleName: modules.find(m => m.id === project.module?.id)?.name}))}
				/>
			</TableContainer>
		</>
	)
} export default Projects 
