import { Button, Modal, TextField, Typography, IconButton, MenuItem, Tooltip } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { useAuth0 } from '@auth0/auth0-react';
import React, { useState } from 'react'
import ApiConfig from './ApiConfig';
import EditIcon from '@mui/icons-material/Edit';
import { Module, Organization, Project, ProjectApi } from '../api';
import ModalStyle from './ModalStyle';
import ErrorComp from './ErrorComp';
import { LoadingButton } from '@mui/lab';


function EditProject(props: any) {
	const [open, setOpen] = React.useState(false);
	const { getAccessTokenSilently } = useAuth0();
	const handleOpen = () => { setOpen(true); refresh(); };
	const handleClose = () => { setOpen(false); refresh() };
	const [project, setProject] = useState<Project>(props.project)
	const [organization, setOrganization] = useState<Organization>();
	const [displayError, setDisplayError] = useState({ display: 'none' })
	const [errorMessage, setErrorMessage] = useState('')
	const [loading, setLoading] = useState(false);

	const refresh = () => {
		setProject(props.project)
		setOrganization(props.project.organization)
		setDisplayError({ display: 'none' })
		setLoading(false)
	}

	const handleModuleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const module = props.modules.find((o: Module) => o.name === event.target.value)
		if (module) {
			setProject({
				...project,
				module: module
			});
		}
	};

	const editProject = async (project: Project) => {
		setLoading(true)
		try {
			const token = await getAccessTokenSilently();
			const header = {
				headers: {
					Authorization: `Bearer ${token}`,
					Accept: 'application/json', 'Content-Type': 'application/json'
				}
			}
			const updateProjectRequest = { projectId: project.projectId as string, project: project as Project }
			await new ProjectApi(ApiConfig()).updateProjectById(updateProjectRequest, header).then(() => props.getAllProjects())
		} catch (error: any) {
			setErrorMessage(error.response.data.message)
			setDisplayError({ display: 'block' })
			setLoading(false)
		}
		handleClose()
	}

	const handleProjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		setProject({
			...project,
			[event.target.name]: newValue
		});
	}

	const handleOrganizationChange = (orgName: string) => {
		const organization = props.organizations?.find((org: Organization) => org.name === orgName)
		setOrganization(organization)
		setProject({
			...project,
			organization: organization
		})
	}

	return (
		<>
			{props.user && (props.user["https://aimaps.k8s.oniq.solutions/roles"][0] === "user" || props.user["https://aimaps.k8s.oniq.solutions/roles"][0] === "superuser") ?
				<Tooltip title="Edit Project">
					<IconButton onClick={handleOpen} disabled={props.disabledButtons}>
						<EditIcon />
					</IconButton>
				</Tooltip>
				:
				<Tooltip title="Edit Collection">
					<IconButton onClick={handleOpen} disabled={props.disabledButtons}>
						<EditIcon />
					</IconButton>
				</Tooltip>
			}
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="createOrg"
			>
				<Box sx={ModalStyle}>
					<Stack spacing={2}>
						<Typography id="modal-project" variant="h6" component="h2">
							Edit Project
						</Typography>
						<TextField id="projectname-field" name='name' value={project.name} onChange={handleProjectChange} label="Project Name" variant="outlined" />
						<TextField id="projectdesc-field" name='description' value={project.description} onChange={handleProjectChange} label="Description" variant="outlined" />
						<TextField id="module-field" name='module' select value={project.module?.name} onChange={handleModuleChange} label="Module" variant="outlined">
							{props.modules.map((option: Module) => (
								<MenuItem disabled={option.modelVersion == 0} key={option.name} value={option.name}>
									{option.name}
								</MenuItem>
							))}
						</TextField>
						{<TextField id="org-field" name='organization' select value={organization?.name} onChange={(e) => handleOrganizationChange(e.target.value)} label="Organization" variant="outlined" >
							{props.organizations.map((org: Organization) => (
								<MenuItem key={org.name} value={org.name}>
									{org.name}
								</MenuItem>
							))}
						</TextField>}
						<Box sx={displayError}>
							<ErrorComp error={errorMessage} />
						</Box>
						<Box sx={{ display: 'flex', justifyContent: 'space-between', }}>
							<Button variant="contained" onClick={handleClose}>Cancel</Button>
							<LoadingButton loading={loading} id="create-button" onClick={() => editProject(project)} variant="contained">Submit</LoadingButton>
						</Box>
					</Stack>
				</Box>
			</Modal>
		</>

	)

} export default EditProject