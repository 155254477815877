import exp from "constants";
import { Organization, ProjectRead, Project } from "../api";
import { TextField, MenuItem } from "@mui/material";

// Select organization for a cvat project
const SelectOrg = (props: { organizations: Organization[], project: ProjectRead, handleOrganizationChange: (projectId?: number, orgName?: string)=>{} }) => {
    
    const displayName = (name?: String) => {
        return (name==='oniq'? 'NONE': name);
    }

    const valName = (name?: String) => {
        return (name ? name.replaceAll(' ', '_'):'').toLowerCase();
    }
    // Button with dropdown to select organization
    return (
        <TextField id="org-field" name='organization' select value={props.project.assignee?.username} onChange={(e) => props.handleOrganizationChange(props.project.id, e.target?.value)} label="Organization" variant="outlined" >
        {props.organizations.map((org: Organization) => (
            <MenuItem key={org.name} value={valName(org.name)}>
                {displayName(org.name)}
            </MenuItem>
        ))}
    </TextField>
    )

};
export default SelectOrg;
