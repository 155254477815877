/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  License,
} from '../models';
import {
    LicenseFromJSON,
    LicenseToJSON,
} from '../models';

export interface ApproveLicenseRequestRequest {
    license: License;
}

export interface CreateLicensesRequest {
    license: License;
}

export interface DeleteLicenseRequest {
    licenseId: string;
}

export interface GetLicensesForOrgRequest {
    organizationId: string;
}

export interface UpdateLicenseRequest {
    licenseId: string;
    license: License;
}

/**
 * 
 */
export class LicenseApi extends runtime.BaseAPI {

    /**
     * Approve license request
     */
    async approveLicenseRequestRaw(requestParameters: ApproveLicenseRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<License>> {
        if (requestParameters.license === null || requestParameters.license === undefined) {
            throw new runtime.RequiredError('license','Required parameter requestParameters.license was null or undefined when calling approveLicenseRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/license/approve`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LicenseToJSON(requestParameters.license),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LicenseFromJSON(jsonValue));
    }

    /**
     * Approve license request
     */
    async approveLicenseRequest(requestParameters: ApproveLicenseRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<License> {
        const response = await this.approveLicenseRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create license
     */
    async createLicensesRaw(requestParameters: CreateLicensesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<License>> {
        if (requestParameters.license === null || requestParameters.license === undefined) {
            throw new runtime.RequiredError('license','Required parameter requestParameters.license was null or undefined when calling createLicenses.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/license`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LicenseToJSON(requestParameters.license),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LicenseFromJSON(jsonValue));
    }

    /**
     * Create license
     */
    async createLicenses(requestParameters: CreateLicensesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<License> {
        const response = await this.createLicensesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete license
     */
    async deleteLicenseRaw(requestParameters: DeleteLicenseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
            throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling deleteLicense.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/license/{licenseId}`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete license
     */
    async deleteLicense(requestParameters: DeleteLicenseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteLicenseRaw(requestParameters, initOverrides);
    }

    /**
     * Get all licenses
     */
    async getLicensesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<License>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/license`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LicenseFromJSON));
    }

    /**
     * Get all licenses
     */
    async getLicenses(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<License>> {
        const response = await this.getLicensesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get all licenses for organization
     */
    async getLicensesForOrgRaw(requestParameters: GetLicensesForOrgRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<License>>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling getLicensesForOrg.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/license/organisation/{organizationId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LicenseFromJSON));
    }

    /**
     * Get all licenses for organization
     */
    async getLicensesForOrg(requestParameters: GetLicensesForOrgRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<License>> {
        const response = await this.getLicensesForOrgRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update license
     */
    async updateLicenseRaw(requestParameters: UpdateLicenseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<License>> {
        if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
            throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling updateLicense.');
        }

        if (requestParameters.license === null || requestParameters.license === undefined) {
            throw new runtime.RequiredError('license','Required parameter requestParameters.license was null or undefined when calling updateLicense.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/license/{licenseId}`.replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LicenseToJSON(requestParameters.license),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LicenseFromJSON(jsonValue));
    }

    /**
     * Update license
     */
    async updateLicense(requestParameters: UpdateLicenseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<License> {
        const response = await this.updateLicenseRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
