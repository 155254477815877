/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface GetExportByIdRequest {
    exportId: string;
}

/**
 * 
 */
export class ExportApi extends runtime.BaseAPI {

    /**
     * Get export data by exportId
     * Get export data by exportId
     */
    async getExportByIdRaw(requestParameters: GetExportByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.exportId === null || requestParameters.exportId === undefined) {
            throw new runtime.RequiredError('exportId','Required parameter requestParameters.exportId was null or undefined when calling getExportById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/export/{exportId}`.replace(`{${"exportId"}}`, encodeURIComponent(String(requestParameters.exportId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Get export data by exportId
     * Get export data by exportId
     */
    async getExportById(requestParameters: GetExportByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getExportByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
