/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ChunkType = {
    Video: 'video',
    Imageset: 'imageset',
    List: 'list'
} as const;
export type ChunkType = typeof ChunkType[keyof typeof ChunkType];


export function ChunkTypeFromJSON(json: any): ChunkType {
    return ChunkTypeFromJSONTyped(json, false);
}

export function ChunkTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChunkType {
    return json as ChunkType;
}

export function ChunkTypeToJSON(value?: ChunkType | null): any {
    return value as any;
}

