/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const LocationEnum = {
    CloudStorage: 'cloud_storage',
    Local: 'local'
} as const;
export type LocationEnum = typeof LocationEnum[keyof typeof LocationEnum];


export function LocationEnumFromJSON(json: any): LocationEnum {
    return LocationEnumFromJSONTyped(json, false);
}

export function LocationEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocationEnum {
    return json as LocationEnum;
}

export function LocationEnumToJSON(value?: LocationEnum | null): any {
    return value as any;
}

