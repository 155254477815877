/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Box
 */
export interface Box {
    /**
     * 
     * @type {string}
     * @memberof Box
     */
    label?: string;
    /**
     * 
     * @type {number}
     * @memberof Box
     */
    score?: number;
    /**
     * 
     * @type {number}
     * @memberof Box
     */
    x?: number;
    /**
     * 
     * @type {number}
     * @memberof Box
     */
    y?: number;
    /**
     * 
     * @type {number}
     * @memberof Box
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof Box
     */
    height?: number;
}

/**
 * Check if a given object implements the Box interface.
 */
export function instanceOfBox(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BoxFromJSON(json: any): Box {
    return BoxFromJSONTyped(json, false);
}

export function BoxFromJSONTyped(json: any, ignoreDiscriminator: boolean): Box {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': !exists(json, 'label') ? undefined : json['label'],
        'score': !exists(json, 'score') ? undefined : json['score'],
        'x': !exists(json, 'x') ? undefined : json['x'],
        'y': !exists(json, 'y') ? undefined : json['y'],
        'width': !exists(json, 'width') ? undefined : json['width'],
        'height': !exists(json, 'height') ? undefined : json['height'],
    };
}

export function BoxToJSON(value?: Box | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label': value.label,
        'score': value.score,
        'x': value.x,
        'y': value.y,
        'width': value.width,
        'height': value.height,
    };
}

