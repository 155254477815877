/* tslint:disable */
/* eslint-disable */
/**
 * aimaps
 * aimaps API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FrameMeta } from './FrameMeta';
import {
    FrameMetaFromJSON,
    FrameMetaFromJSONTyped,
    FrameMetaToJSON,
} from './FrameMeta';

/**
 * 
 * @export
 * @interface DataMetaRead
 */
export interface DataMetaRead {
    /**
     * 
     * @type {number}
     * @memberof DataMetaRead
     */
    readonly chunkSize?: number;
    /**
     * 
     * @type {number}
     * @memberof DataMetaRead
     */
    readonly size?: number;
    /**
     * 
     * @type {number}
     * @memberof DataMetaRead
     */
    imageQuality: number;
    /**
     * 
     * @type {number}
     * @memberof DataMetaRead
     */
    readonly startFrame?: number;
    /**
     * 
     * @type {number}
     * @memberof DataMetaRead
     */
    readonly stopFrame?: number;
    /**
     * 
     * @type {string}
     * @memberof DataMetaRead
     */
    readonly frameFilter?: string;
    /**
     * 
     * @type {Array<FrameMeta>}
     * @memberof DataMetaRead
     */
    frames: Array<FrameMeta>;
    /**
     * 
     * @type {Array<number>}
     * @memberof DataMetaRead
     */
    deletedFrames: Array<number>;
}

/**
 * Check if a given object implements the DataMetaRead interface.
 */
export function instanceOfDataMetaRead(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "imageQuality" in value;
    isInstance = isInstance && "frames" in value;
    isInstance = isInstance && "deletedFrames" in value;

    return isInstance;
}

export function DataMetaReadFromJSON(json: any): DataMetaRead {
    return DataMetaReadFromJSONTyped(json, false);
}

export function DataMetaReadFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataMetaRead {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'chunkSize': !exists(json, 'chunk_size') ? undefined : json['chunk_size'],
        'size': !exists(json, 'size') ? undefined : json['size'],
        'imageQuality': json['image_quality'],
        'startFrame': !exists(json, 'start_frame') ? undefined : json['start_frame'],
        'stopFrame': !exists(json, 'stop_frame') ? undefined : json['stop_frame'],
        'frameFilter': !exists(json, 'frame_filter') ? undefined : json['frame_filter'],
        'frames': ((json['frames'] as Array<any>).map(FrameMetaFromJSON)),
        'deletedFrames': json['deleted_frames'],
    };
}

export function DataMetaReadToJSON(value?: DataMetaRead | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'image_quality': value.imageQuality,
        'frames': ((value.frames as Array<any>).map(FrameMetaToJSON)),
        'deleted_frames': value.deletedFrames,
    };
}

